import AdSense from 'react-adsense';
import React from 'react';

const AdSenseFrame = ({ slotId }) => {
  if (process.env.NODE_ENV === 'test') {
    return <div></div>;
  }
  return (
    <div className="google-ad py-3">
      <AdSense.Google
        client="ca-pub-1484967379654435"
        slot={slotId}
        style={{
          display: 'block',
          maxWidth: '100%',
          margin: '0px',
        }}
        format="auto"
        responsive="true"
      />
    </div>
  );
};

export default AdSenseFrame;
