import { monthBeginningMoment } from './dateHelpers';
import { calcWiborChange } from './calcWiborChange';
import { calcFee } from './calcFee';

export const calcTranches = ({
  tranches,
  creditValue,
  remainedCapital,
  wiborChanges,
  wibor,
  firstRateDay,
  bankMargin,
  changingFees,
  constantFees,
  interestsTotal,
  changingFeesTotal,
  constantFeesTotal,
  data,
}) => {
  const tranchesSorted = [...tranches].sort((a, b) => a.date - b.date);
  let trancheMonthsNr =
    tranchesSorted.length > 0
      ? -monthBeginningMoment(firstRateDay).diff(
          monthBeginningMoment(tranchesSorted[0].date),
          'months',
        )
      : 0;
  let trancheNr = 0;
  for (trancheNr = 0; trancheNr < tranchesSorted.length; trancheNr++) {
    const tra1 = tranchesSorted[trancheNr];
    const tra2 = tranchesSorted[trancheNr + 1];
    remainedCapital += tra1.value;
    if (remainedCapital > creditValue) remainedCapital = creditValue;
    let monthsDiff = monthBeginningMoment(firstRateDay).diff(
      monthBeginningMoment(tra1.date),
      'months',
    );
    if (tra1 && tra2) {
      monthsDiff = monthBeginningMoment(tra2.date).diff(
        monthBeginningMoment(tra1.date),
        'months',
      );
    }

    let m = -monthsDiff;
    for (m = -monthsDiff; m < 0; m++) {
      const w = calcWiborChange({
        wiborChanges,
        beginningDate: firstRateDay,
        i: trancheMonthsNr + 1,
        wibor,
      });
      wibor = w.wibor;
      const percentage = (bankMargin + wibor) / 100;
      const interests = (remainedCapital * percentage) / 12;
      // calc total instersts
      interestsTotal += interests;

      // fees (changing & constant impact)
      const fees = calcFee({
        changingFees,
        constantFees,
        firstRateDay,
        i: trancheMonthsNr + 1,
        remainedCapital,
      });
      changingFeesTotal += fees.changingFeesInMonth;
      constantFeesTotal += fees.constantFeesInMonth;

      data.push({
        dkNumber: trancheMonthsNr + 1,
        dkCapitalPart: 0,
        dkInterestPart: parseFloat(interests.toFixed(2)),
        dkConstantFees: parseFloat(fees.constantFeesInMonth.toFixed(2)),
        dkChangingFees: parseFloat(fees.changingFeesInMonth.toFixed(2)),
        dkRemainToPay: parseFloat(remainedCapital.toFixed(2)),
        total: parseFloat(
          (
            interests +
            fees.constantFeesInMonth +
            fees.changingFeesInMonth
          ).toFixed(2),
        ),
      });
      trancheMonthsNr += 1;
    }
  }

  return {
    data,
    wibor,
    interestsTotal,
    changingFeesTotal,
    constantFeesTotal,
  };
};
