import React, { useState, useEffect, useRef } from 'react';
import { Col, Button, Row } from 'reactstrap';
import { Form } from 'reactstrap';
import CookieConsent from 'react-cookie-consent';
import FormBasic from '../sub/FormBasic';
import FormExtended from '../sub/FormExtended';
import Results from '../sub/Results';
// import AdModal from '../sub/AdModal';
import AdSenseFrame from '../sub/AdSenseFrame';
import { calcForDecreasingInstallments } from '../../utils/calcForDecreasingInstallments';
import { calcForConstantInstallments } from '../../utils/calcForConstantInstallments';
import setInitState from '../../utils/setInitState';
import { getInitState } from '../../utils/getInitState';
import 'react-datepicker/dist/react-datepicker.css';
import * as sh from '../../utils/stateHelpers';
import { parseAndValidate } from '../../utils/parseAndValidate';
import { CONSTANT } from '../../constants/constants';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const initState = getInitState();
  const { t } = useTranslation();
  const printRef = useRef(null);
  const scrollRef = useRef(null);

  const [creditValue, setCreditValue] = useState(initState.creditValue);
  const [creditDuration, setCreditDuration] = useState(
    initState.creditDuration,
  );
  const [rateType, setRateType] = useState(initState.rateType);
  const [bankMargin, setBankMargin] = useState(initState.bankMargin);
  const [wiborStart, setWiborStart] = useState(initState.wiborStart);
  const [serviceCharge, setServiceCharge] = useState(initState.serviceCharge);
  const [constantFees, setConstantFees] = useState(initState.constantFees);
  const [changingFees, setChangingFees] = useState(initState.changingFees);
  const [firstRateDay, setFirstRateDay] = useState(initState.firstRateDay);
  const [wiborChanges, setWiborChanges] = useState(initState.wiborChanges);
  const [surcharges, setSurcharges] = useState(initState.surcharges);
  const [tranches, setTranches] = useState(initState.tranches);
  const [isStatusAccepted, setIsStatusAccepted] = useState(
    initState.isStatusAccepted,
  );
  const [isForShorten, setIsForShorten] = useState(initState.isForShorten);
  const [allData, setAllData] = useState(initState.allData);
  const [errors, setErrors] = useState(initState.errors);
  const [isOpen, setIsOpen] = useState(false);
  const [isFirstCalc, setIsFirstCalc] = useState(true);
  const allStateVars = {
    creditValue,
    creditDuration,
    rateType,
    bankMargin,
    wiborStart,
    serviceCharge,
    constantFees,
    changingFees,
    firstRateDay,
    wiborChanges,
    surcharges,
    tranches,
    isForShorten,
    isStatusAccepted,
  };

  useEffect(() => {
    setAllData({ data: [] });
  }, [
    creditValue,
    creditDuration,
    rateType,
    bankMargin,
    wiborStart,
    serviceCharge,
    constantFees,
    changingFees,
    firstRateDay,
    wiborChanges,
    surcharges,
    tranches,
    isForShorten,
  ]);

  const calcResults = (e) => {
    e.preventDefault();
    let parsedValidated = {};
    parsedValidated = parseAndValidate(allStateVars);
    const err = parsedValidated.err;
    if (err) {
      setErrors((prev) => ({
        ...prev,
        [err.name]: err.text,
      }));
      if (process.env.NODE_ENV !== 'test') window.scrollTo(0, 0);
      return;
    }
    if (rateType === CONSTANT) {
      setAllData(calcForConstantInstallments(parsedValidated));
    } else {
      setAllData(calcForDecreasingInstallments(parsedValidated));
    }
    isFirstCalc && setIsOpen(true);
    setIsFirstCalc(false);
    setInitState(parsedValidated);
  };

  return (
    <div ref={printRef}>
      <CookieConsent buttonText="OK">
        {t('general.LOCAL_STORAGE_USAGE_INFO')}
      </CookieConsent>
      {/* <AdModal isOpen={isOpen} setIsOpen={setIsOpen} scrollRef={scrollRef} /> */}
      <article className="container">
        <header>
          <h1 className="py-5 text-center">
            {t('home.SITE_TITLE')} <i className="far fa-laugh-wink" />
          </h1>
        </header>
        <AdSenseFrame slotId="2556502078" />
        <Row>
          <Col className="d-inline-flex align-items-center pb-2 ad-turn-off">
            {t('home.TURN_OFF_ADBLOCK')}   
            <i className="far fa-laugh-wink pb-1" />
          </Col>
          <Col className="d-inline-flex justify-content-end align-items-center pb-2">
            <Button
              className="mb-3"
              color="dark"
              onClick={() => {
                localStorage.clear();
                window.location.replace(window.location.origin);
              }}
            >
              {t('home.RESTORE_DEFAULT')}
            </Button>
          </Col>
        </Row>
        <div>
          <Form onSubmit={calcResults}>
            <FormBasic
              creditValue={creditValue}
              onChangeCreditValue={(val) =>
                sh.onChangeCreditValue(val, setCreditValue, setErrors)
              }
              creditDuration={creditDuration}
              onChangeCreditDuration={(val) =>
                sh.onChangeCreditDuration(val, setCreditDuration, setErrors)
              }
              rateType={rateType}
              onChangeRateType={(val) => sh.onChangeRateType(val, setRateType)}
              bankMargin={bankMargin}
              onChangeBankMargin={(val) =>
                sh.onChangeBankMargin(val, setBankMargin, setErrors)
              }
              wiborStart={wiborStart}
              onChangeWiborStart={(val) =>
                sh.onChangeWiborStart(val, setWiborStart, setErrors)
              }
              serviceCharge={serviceCharge}
              onChangeServiceCharge={(val) =>
                sh.onChangeServiceCharge(val, setServiceCharge, setErrors)
              }
              constantFees={constantFees}
              changingFees={changingFees}
              addFee={(feeType, key) =>
                sh.addFee({
                  feeType,
                  key,
                  setConstantFees,
                  setChangingFees,
                  firstRateDay,
                  creditDuration,
                })
              }
              removeFee={(feeType, key) =>
                sh.removeFee({
                  feeType,
                  key,
                  setConstantFees,
                  setChangingFees,
                  setErrors,
                })
              }
              updateFee={(obj) =>
                sh.updateFee({
                  ...obj,
                  setConstantFees,
                  setChangingFees,
                  firstRateDay,
                  creditDuration,
                  tranches,
                  setErrors,
                })
              }
              isStatusAccepted={isStatusAccepted}
              onStatusAccept={() =>
                sh.onStatusAccept(setIsStatusAccepted, setErrors)
              }
              errors={errors}
            />
            <FormExtended
              firstRateDay={firstRateDay}
              onChangeFirstRateDay={(val) =>
                sh.onChangeFirstRateDay(val, setFirstRateDay)
              }
              wiborChanges={wiborChanges}
              addWiborChange={(key) =>
                sh.addWiborChange(key, setWiborChanges, firstRateDay)
              }
              removeWiborChange={(key) =>
                sh.removeWiborChange(key, setWiborChanges, setErrors)
              }
              updateWiborChange={(obj) =>
                sh.updateWiborChange({
                  ...obj,
                  setWiborChanges,
                  firstRateDay,
                  creditDuration,
                  setErrors,
                  tranches,
                })
              }
              surcharges={surcharges}
              addSurcharge={(key) =>
                sh.addSurcharge(key, setSurcharges, firstRateDay)
              }
              removeSurcharge={(key) => sh.removeSurcharge(key, setSurcharges)}
              updateSurcharge={(obj) =>
                sh.updateSurcharge({
                  ...obj,
                  setSurcharges,
                  firstRateDay,
                  creditDuration,
                  setErrors,
                })
              }
              tranches={tranches}
              addTranche={(key) =>
                sh.addTranche(key, setTranches, firstRateDay, setErrors)
              }
              removeTranche={(key) =>
                sh.removeTranche(key, setTranches, setErrors)
              }
              updateTranche={(obj) =>
                sh.updateTranche({
                  ...obj,
                  setTranches,
                  firstRateDay,
                  creditValue,
                  setErrors,
                })
              }
              setIsForShorten={setIsForShorten}
              isForShorten={isForShorten}
              errors={errors}
            />
            <Col className="d-flex justify-content-center align-items-center my-3">
              <Button
                className="px-5"
                color="primary"
                type="submit"
                data-testid="btn-submit"
              >
                {t('home.CALC_SUBMIT')}
              </Button>
            </Col>
          </Form>
          <AdSenseFrame slotId="1009591075" />
        </div>
      </article>
      {Object.keys(errors).length === 0 && allData?.data?.length > 0 && (
        <Results
          rateType={rateType}
          allData={allData}
          printRef={printRef}
          scrollRef={scrollRef}
          isOpen={isOpen}
          surcharges={surcharges}
        />
      )}
      <AdSenseFrame slotId="1120131472" />
    </div>
  );
};

export default Home;
