import moment from 'moment-timezone';
import i18next from 'i18next';
import { monthBeginning, monthEnd } from './dateHelpers';
import { CONSTANT } from '../constants/constants';
import { getDataFromURL, getDataFromLocalStorage } from './getDataFrom';

export const getInitState = () => {
  let initState = {};
  // is URL?
  const stateFromURL = getDataFromURL();
  if (stateFromURL) {
    initState = stateFromURL;
  } else {
    // if NO URL - then use localStorage OR raw initState
    initState = {
      creditValue: 300000,
      creditDuration: 120,
      rateType: CONSTANT,
      bankMargin: 1.7,
      wiborStart: 6.9,
      serviceCharge: 0,
      constantFees: [],
      changingFees: [],
      firstRateDay: monthBeginning(new Date()),
      wiborChanges: [],
      surcharges: [],
      tranches: [],
      isForShorten: false,
      isStatusAccepted: false,
      allData: {},

      errors: {
        errStatusAccepted: i18next.t('errors.STATUTE_REQUIRED'),
      },
    };

    // refresh localStorage data if calc attributes are too old
    const lastCalcDate = new Date(
      parseInt(localStorage.getItem('calculationDate')),
    );
    const isOldCalc = lastCalcDate
      ? moment(lastCalcDate).add(7, 'days').toDate() < new Date()
      : true;
    if (isOldCalc) {
      Object.keys(initState).forEach((key) => localStorage.removeItem(key));
    }

    Object.keys(localStorage).forEach((key) => {
      if (Object.keys(initState).includes(key)) {
        const initValue = localStorage.getItem(key);
        // get all Data type (in localStorage: unix time) and set them as new Date() format in the state
        // get all other data types (string, int, float, bool) and set them as string in the state
        // WARNING: JSON.parse() properly changes "{...}", but
        initState[key] = initValue;
        if (['firstRateDay'].includes(key)) {
          initState[key] = new Date(parseInt(initValue));
        } else if (['isStatusAccepted'].includes(key)) {
          initState[key] = false; // init state should always require a status confirmation
        } else if (['isForShorten'].includes(key)) {
          initState[key] = JSON.parse(initValue);
        } else if (
          ['constantFees', 'changingFees', 'surcharges'].includes(key)
        ) {
          initState[key] = JSON.parse(initValue).map((el) => ({
            ...el,
            startDate: monthBeginning(new Date(parseInt(el.startDate))),
            endDate: monthEnd(new Date(parseInt(el.endDate))),
          }));
        } else if (['tranches'].includes(key)) {
          initState[key] = JSON.parse(initValue).map((tra) => ({
            ...tra,
            date: monthBeginning(new Date(parseInt(tra.date))),
          }));
        } else if (['wiborChanges'].includes(key)) {
          initState[key] = JSON.parse(initValue).map((wib) => ({
            ...wib,
            date: monthBeginning(new Date(parseInt(wib.date))),
          }));
        } else {
          initState[key] = initValue;
        }
        // it's only test-purpose function, no real logic usage
        getDataFromLocalStorage(key, initState[key]);
      }
    });
  }
  return initState;
};
