/* eslint-disable no-loop-func */
import { calcWiborChange } from './calcWiborChange';
import { calcFee } from './calcFee';
import { calcTranches } from './calcTranches';
import { DECREASING } from '../constants/constants';
import { monthBeginningMoment } from './dateHelpers';
import moment from 'moment';
import { calcSurchargesSumForIthMonth } from './calcSurchargesSumForIthMonth';

export const calcForDecreasingInstallments = (inp) => {
  const {
    creditValue,
    creditDuration,
    bankMargin,
    wiborStart,
    serviceCharge = 0,
    constantFees = [],
    changingFees = [],
    firstRateDay,
    wiborChanges = [],
    surcharges = [],
    tranches = [],
    isForShorten,
  } = inp;

  // final set of data with capital parts, interests parts, etc.
  let data = [];

  //calc capital part
  //will be equal for each rate IF there will be no surcharge
  let capitalPart = creditValue / creditDuration;
  let currentCapitalPart = capitalPart;
  let capitalPartForLastMonth = capitalPart;
  let capitalTotal = 0;

  let remainedCapital = 0;

  // only interests and their sum declaration
  let interests = 0;
  let interestsTotal = 0;

  // wibor value (might be changed during the credit duration)
  let wibor = wiborStart;

  // surcharges sum
  let surchargesSum = 0;
  let monthsReduced = 0; // how many months have been reduced due to surcharges (when surcharges for shorten)

  // calc service charge cost
  let serviceChargeCost = (serviceCharge * creditValue) / 100;

  // helpful vars
  let percentage = 0;
  let daysInMonth = 0;
  let daysInYear = 0;
  let constantFeesTotal = 0;
  let changingFeesTotal = 0;

  // calc TRANCHES costs (before credit is started to be payed)
  const resTra = calcTranches({
    tranches,
    creditValue,
    remainedCapital,
    wiborChanges,
    wibor,
    firstRateDay,
    bankMargin,
    changingFees,
    constantFees,
    interestsTotal,
    changingFeesTotal,
    constantFeesTotal,
    data,
  });
  data = resTra.data;
  wibor = resTra.wibor;
  interestsTotal = resTra.interestsTotal;
  changingFeesTotal = resTra.changingFeesTotal;
  constantFeesTotal = resTra.constantFeesTotal;

  // CALC INSTALLMENTS, WHEN CREDIT HAS STARTED TO BE PAID
  remainedCapital = creditValue;
  let i = 1;
  for (i = 1; i <= creditDuration - Math.floor(monthsReduced); i++) {
    // for the last month use capitalPartForLastMonth:
    // when were no surcharges for shorten it will have standard currentCapitalPart
    // but when surcharge for shorten occured then it may have reduced value
    if (
      i === creditDuration - Math.floor(monthsReduced) &&
      isForShorten &&
      surcharges.length > 0
    ) {
      currentCapitalPart = capitalPartForLastMonth;
    }
    // calc for proper Wibor --------------- WIBOR
    // just check if wibor has changed during the credit period
    // NOTE: change date should be a date, when wibor change had a first affect on the credit value(!)
    const w = calcWiborChange({
      type: DECREASING,
      wiborChanges,
      beginningDate: firstRateDay,
      i,
      wibor,
      percentage,
      bankMargin,
      remainedCapital,
    });
    wibor = w.wibor;
    percentage = w.percentage;
    // --------------- INTERESTS ---------------
    // calc loan percentage (% for every month)
    percentage = (bankMargin + wibor) / 100;
    // calc how many days in month
    daysInMonth = monthBeginningMoment(
      moment(firstRateDay).add(i - 1, 'months'),
    ).daysInMonth();
    // calc how many days in year
    daysInYear = monthBeginningMoment(
      moment(firstRateDay).add(i - 1, 'months'),
    ).isLeapYear()
      ? 366
      : 365;

    interests = remainedCapital * percentage * (daysInMonth / daysInYear);
    // calc total instersts
    interestsTotal += interests;
    // add capital part to total sum of payed capital parts
    capitalTotal += currentCapitalPart;

    // ------------ CHANGING & CONSTANT FEES -------------
    const fees = calcFee({
      changingFees,
      constantFees,
      firstRateDay,
      i,
      remainedCapital,
    });
    changingFeesTotal += fees.changingFeesInMonth;
    constantFeesTotal += fees.constantFeesInMonth;

    // -------------- SURCHARGES ----------------------
    // we calculate capital for NEXT(!) iteration
    // surcharges for rate reduction affects on capital part
    // i.e. before surcharge will be 120 months, capital part: 1666.67zł and after surcharge will be also 120 months and (for 1000zł surcharge in 1 month) capital part: 1658,26zł
    // surcharges for shorten do NOT affect on capital part, but obviously they also make the rate a bit smaller, because the remained capital will be smaller, so it will reduce the interest part too
    // i.e. before surcharge will be 120 months, capital part: 1666.67zł and after surcharge will be also 120 months and (for 1000zł surcharge in 1 month) capital part: 1666.67zł, but interest part will be smaller & it will shorten the credit only a part of month (still 120months anyway yet)
    const capitalPartBeforeMonthWithSurcharge = currentCapitalPart;

    let surchargeValuesSumForIthMonth = calcSurchargesSumForIthMonth({
      surcharges,
      ithMonth: i,
      firstRateDay,
    });
    if (surchargeValuesSumForIthMonth) {
      remainedCapital = creditValue - capitalTotal - surchargesSum; // remainedCapital BEFORE surcharge
      // case when surcharge is higher than remained capital
      if (surchargeValuesSumForIthMonth > remainedCapital) {
        surchargeValuesSumForIthMonth = remainedCapital;
      }
      surchargesSum += surchargeValuesSumForIthMonth;
      remainedCapital = creditValue - capitalTotal - surchargesSum;
      // ---- SURCHARGES FOR SHORTEN ----
      if (isForShorten && surcharges.length > 0) {
        // how many iterations shorter?
        monthsReduced =
          monthsReduced + surchargeValuesSumForIthMonth / currentCapitalPart;
        capitalPartForLastMonth =
          currentCapitalPart -
          (monthsReduced - Math.floor(monthsReduced)) * currentCapitalPart;
      } else {
        // ---- SURCHARGES FOR DECREASING ----
        currentCapitalPart = remainedCapital / (creditDuration - i);
      }
    }

    // --------- REMAINED CAPITAL ---------
    // it should contain currently payed capitalPart and currently payed surcharge (except last)
    remainedCapital = creditValue - capitalTotal - surchargesSum;

    data.push({
      dkNumber: i,
      dkCapitalPart: parseFloat(capitalPartBeforeMonthWithSurcharge.toFixed(2)),
      dkInterestPart: parseFloat(interests.toFixed(2)),
      dkConstantFees: parseFloat(fees.constantFeesInMonth.toFixed(2)),
      dkChangingFees: parseFloat(fees.changingFeesInMonth.toFixed(2)),
      dkRemainToPay: parseFloat(remainedCapital.toFixed(2)),
      total: parseFloat(
        (
          (i === 1 ? serviceChargeCost : 0) +
          capitalPartBeforeMonthWithSurcharge +
          interests +
          fees.constantFeesInMonth +
          fees.changingFeesInMonth
        ).toFixed(2),
      ),
    });

    if (Math.round(remainedCapital) <= 0) break; // lets to pass last installment where remainedCapital = 0
  }

  const creditCost =
    creditValue +
    interestsTotal +
    constantFeesTotal +
    changingFeesTotal +
    serviceChargeCost;

  return {
    data,
    firstRateDay,
    creditValue,
    serviceChargeCost,
    interestsTotal,
    constantFeesTotal,
    changingFeesTotal,
    creditCost,

    capitalTotalSumControl: capitalTotal,
    surchargesSumControl: surchargesSum,
  };
};
