import React, { useState } from 'react';
import { FormGroup, Form, Button, Input, Row, Col } from 'reactstrap';
import { addTweet } from '../../utils/forumUtils';
import { useTranslation } from 'react-i18next';

const ForumForm = ({ setTweets, setError, setIsFetching, lang }) => {
  const [newTweetContent, setNewTweetContent] = useState('');
  const [newTweetAuthor, setNewTweetAuthor] = useState('');
  const { t } = useTranslation();

  return (
    <Form
      name="creditForumForm"
      method="post"
      data-netlify="true"
      data-netlify-recaptcha="true"
      onSubmit={(e) =>
        addTweet({
          e,
          newTweetAuthor,
          newTweetContent,
          setTweets,
          setNewTweetAuthor,
          setNewTweetContent,
          setError,
          setIsFetching,
          lang,
        })
      }
    >
      <Input type="hidden" name="form-name" value="creditForumForm" />
      <Input
        type="hidden"
        name="subject"
        id="subject"
        value="Nowa wiadomość na forum kredytowym"
      />
      <FormGroup>
        <Input
          required
          type="textarea"
          value={newTweetContent}
          placeholder={t('forum.ADD_YOUR_COMMENT')}
          id="content"
          name="content"
          onChange={(e) => setNewTweetContent(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Row>
          <Col sm={8} className="mb-3">
            <Input
              required
              value={newTweetAuthor}
              type="text"
              id="author"
              name="author"
              placeholder={t('forum.AUTHOR')}
              onChange={(e) => {
                if (e.target.value === 'Tosia-admin') {
                  window.alert(t('forum.ALERT_TEXT'));
                  setNewTweetAuthor(t('forum.ROGUE'));
                }
                if (e.target.value === 'kirkol') {
                  setNewTweetAuthor('Tosia-admin');
                } else {
                  setNewTweetAuthor(e.target.value);
                }
              }}
            />
          </Col>
          <Col sm={4}>
            <Button color="secondary">{t('forum.ADD_YOUR_COMMENT')}</Button>
          </Col>
        </Row>
      </FormGroup>
      <div data-netlify-recaptcha="true"></div>
    </Form>
  );
};

export default ForumForm;
