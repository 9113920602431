import React from 'react';
import { Helmet } from 'react-helmet';
import AdSenseFrame from '../sub/AdSenseFrame';
import { useTranslation } from 'react-i18next';

const Tips = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <Helmet>
        <title>{t('tips.PAGE_TITLE')}</title>
      </Helmet>
      <article>
        <header>
          <h1 className="py-5 text-center">{t('tips.PAGE_TITLE')}</h1>
          <h3 className="py-5 text-center">{t('tips.SUB_TITLE')}</h3>
        </header>
        <AdSenseFrame slotId="3450781348" />
        <div className="pb-4">
          <h3 className="pb-3">1. {t('tips.PARAG1')}:</h3>
          <ul>
            <li className="pb-2">{t('tips.PARAG2')}</li>
            <li className="pb-2 text-secondary">{t('tips.PARAG3')}</li>
            <li className="pb-2 text-secondary">{t('tips.PARAG4')}</li>
            <li className="pb-2 ">
              {t('tips.PARAG5')}{' '}
              <span className="text-secondary">{t('tips.PARAG6')}</span>{' '}
              {t('tips.PARAG7')}
            </li>
            <li className="pb-2">{t('tips.PARAG8')}</li>
            <li className="pb-2">
              <span className="text-secondary"> {t('tips.PARAG9')} </span>{' '}
              {t('tips.PARAG10')}
            </li>
            <li className="pb-2">
              <span className="text-secondary"> {t('tips.PARAG11')}: </span>
              {t('tips.PARAG12')}
            </li>
            <li className="pb-2">
              <span className="text-secondary"> {t('tips.PARAG13')} </span>{' '}
              {t('tips.PARAG14')}
            </li>
            <li className="pb-2">
              {t('tips.PARAG15')}{' '}
              <span className="text-secondary"> {t('tips.PARAG16')}</span>{' '}
              {t('tips.PARAG17')}
            </li>
            <li className="pb-2">
              {t('tips.PARAG18')}{' '}
              <span className="text-secondary">{t('tips.PARAG19')} </span>
              {t('tips.PARAG20')}
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <h3 className="pb-3">
            2. {t('tips.PARAG21')} <strike>{t('tips.PARAG22')}</strike>{' '}
            {t('tips.PARAG23')}?
          </h3>
          <h5>{t('tips.PARAG24')}?</h5>
          <h5 className="text-secondary">{t('tips.PARAG25')}</h5>
          <p>{t('tips.PARAG26')}</p>
          <p>{t('tips.PARAG27')}</p>
        </div>
        <div className="pb-4">
          <h3 className="pb-3">3. {t('tips.PARAG28')}?</h3>
          <h5>{t('tips.PARAG29')}</h5>
          <p className="text-secondary">{t('tips.PARAG30')}!</p>
          <p>
            {t('tips.PARAG31')}{' '}
            <span className="text-secondary">{t('tips.PARAG32')}!</span>{' '}
            {t('tips.PARAG33')}
          </p>
          <p>{t('tips.PARAG34')}</p>
          <p>{t('tips.PARAG35')}</p>
        </div>
        <div className="pb-4">
          <h3 className="pb-3">4. {t('tips.PARAG36')}?</h3>
          <h5>{t('tips.PARAG37')}!</h5>
          <p className="text-secondary">{t('tips.PARAG38')}?</p>
          <p>{t('tips.PARAG39')}</p>
        </div>
        <div className="pb-4">
          <h3 className="pb-3">5. {t('tips.PARAG40')}</h3>
          <h5>{t('tips.PARAG41')}...</h5>
          <p className="text-secondary">{t('tips.PARAG42')}</p>
          <p>{t('tips.PARAG43')}</p>
          <p className="text-secondary">{t('tips.PARAG44')}</p>
        </div>
        <div className="pb-4">
          <h3 className="pb-3">6. {t('tips.PARAG45')}</h3>
          <h5>{t('tips.PARAG46')}?</h5>
          <p>{t('tips.PARAG47')}</p>
          <p>{t('tips.PARAG48')}</p>
        </div>
        <div className="pb-4">
          <h3 className="pb-3">7. {t('tips.PARAG49')}</h3>
          <p>{t('tips.PARAG50')}</p>
        </div>
      </article>
    </div>
  );
};

export default Tips;
