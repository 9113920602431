import i18next from 'i18next';
// checks if certain field contains proper data format (float, integet, <0, etc.)
const validateFieldsOnFly = ({
  prev,
  value,
  errName,
  fieldName,
  setErrors,
}) => {
  // NON-NUMBER FIELDS
  if (fieldName === 'statusAccepted') {
    if (!value) {
      setErrors((prev) => ({
        ...prev,
        errStatusAccepted: i18next.t('errors.STATUTE_REQUIRED'),
      }));
    } else {
      setErrors((prev) => {
        const newState = prev;
        delete newState['errStatusAccepted'];
        return newState;
      });
    }
    return value;
  }

  // DATE FIELDS
  if (
    [
      'firstRateDay',
      'wiborChangeDate',
      'constantFeeStartDate',
      'constantFeeEndDate',
      'changingFeeStartDate',
      'changingFeeEndDate',
      'surchargeStartDate',
      'surchargeEndDate',
    ].includes(fieldName)
  ) {
    // firstRateDay has no validation rules, just Date
    let savedValue = value;
    return savedValue;
  }
  // NUMBER FIELDS
  // only numbers: 10, -10, 10.10, -10.10,
  let savedValue = value
    .replace(',', '.')
    .replace(/[^\d.-]+/g, '')
    .replace('..', '.');
  savedValue = savedValue
    .split('')
    .map((char, idx) => {
      if (idx !== 0 && char === '-') return '';
      return char;
    })
    .join('');
  if (savedValue.startsWith('.') || savedValue.startsWith('-.'))
    savedValue = savedValue.replace('.', '');
  // only integers: 10
  if (fieldName === 'creditDuration') {
    savedValue = savedValue.replace(/[^\d]+/g, '');
  }
  // only positive numbers: 10, 10.10
  if (
    [
      'serviceCharge',
      'creditValue',
      'bankMargin',
      'constantFeeValue',
      'changingFeeValue',
      'surchargeValue',
      'trancheValue',
    ].includes(fieldName)
  ) {
    savedValue = savedValue.replace('-', '');
  }

  let errOccurred = false;
  // differ error texts only
  if (
    [
      'creditValue',
      'creditDuration',
      'constantFeeValue',
      'changingFeeValue',
      'surchargeValue',
      'trancheValue',
    ].includes(fieldName) &&
    (parseFloat(savedValue) === 0 || isNaN(savedValue) || savedValue === '')
  ) {
    errOccurred = true;
    setErrors((prev) => ({
      ...prev,
      [errName]: i18next.t('errors.POSITIVE_NUMBERS_REQUIRED'),
    }));
  }
  if (isNaN(savedValue) || savedValue === '') {
    errOccurred = true;
    setErrors((prev) => ({
      ...prev,
      [errName]: i18next.t('errors.NUMBERS_REQUIRED'),
    }));
  }

  if (!errOccurred) {
    // remove err for that field from the stare
    setErrors((prev) => {
      const newState = prev;
      delete newState[errName];
      return newState;
    });
  }

  if (savedValue.includes('.')) {
    const isChangingFeeValue =
      fieldName === 'changingFeeValue'
        ? /\.\d{1,5}$/.test(savedValue)
        : /\.\d{1,2}$/.test(savedValue);
    if (!(savedValue.endsWith('.') || isChangingFeeValue)) {
      return prev;
    }
  }

  return savedValue;
};

export default validateFieldsOnFly;
