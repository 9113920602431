export const calcMonthsReduced = (
  installment,
  input,
  surcharge, // it's a surcharge AND rest of previous surcharge if any left from previous surcharges
) => {
  let percentage = 0;
  let interests = 0;
  let currentCapitalPart = 0;
  let remainedCapital = input.creditValue;
  let remainedSurchargeTemp = surcharge;
  let remainedSurchargeMinusCapitalPart = 0;
  let reduction = 0;
  const rates = [];
  let capitalTotal = 0;
  // simple further credit calculation, if there were no surcharges (and no other changes)
  // used for calculation how many months will be reduced after surcharge (and + rest of previous surcharges, if any)
  let i = 1;
  for (i = 1; i <= input.creditDuration; i++) {
    //calc loan percentage (% for every month)
    percentage = (input.bankMargin + input.wiborStart) / 100;
    //calc interests part
    interests = (remainedCapital * percentage) / 12;
    //calc capital part
    currentCapitalPart = installment - interests;
    // add capital part to total sum of payed capital parts
    capitalTotal += currentCapitalPart;
    remainedCapital = input.creditValue - capitalTotal;
    rates.push({
      dkNumber: `alternative-installment-${i}`,
      dkCapitalPart: parseFloat(currentCapitalPart.toFixed(2)),
      dkInterestPart: parseFloat(interests.toFixed(2)),
      dkRemainToPay: parseFloat(remainedCapital.toFixed(2)),
    });
    if (Math.round(remainedCapital) <= 0) break; // lets to pass last installment where remainedCapital = 0
  }
  let j = 0;
  for (j = 0; j < rates.length; j++) {
    // for last rate we use ceil since it may be difference a bit (up to 0.03 PLN)
    remainedSurchargeMinusCapitalPart =
      j === rates.length - 1
        ? Math.ceil(remainedSurchargeTemp - rates[j].dkCapitalPart)
        : remainedSurchargeTemp - rates[j].dkCapitalPart;
    if (remainedSurchargeMinusCapitalPart >= 0) {
      remainedSurchargeTemp = remainedSurchargeMinusCapitalPart;
      reduction += 1;
    }
  }
  return { reduction, remainedSurcharge: remainedSurchargeTemp };
};
