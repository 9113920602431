import React from 'react';
import Navi from './sub/Navi';
import Home from './pages/Home';
import Footer from './sub/Footer';
import About from './pages/About';
import Status from './pages/Status';
import Examples from './pages/Examples';
import Forum from './pages/Forum';
import Tips from './pages/Tips';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LangObjProvider } from '../providers/LangObjProvider';
import availableLangObjs from '../locals/availableLangObjs';
import { useTranslation } from 'react-i18next';

const App = () => {
  const { t } = useTranslation();
  const langSubPaths = availableLangObjs.map((lgObj) => lgObj.subPath);
  return (
    <Router>
      <LangObjProvider>
        <Helmet>
          <title>{t('general.SITE_TITLE')}</title>
        </Helmet>
        <Navi />
        <section className="section-area">
          <Routes>
            {langSubPaths.map((subPath, idx) => (
              <Route path={subPath} key={subPath + idx}>
                <Route path="" element={<Home />} />
                <Route path="o_mnie" element={<About />} />
                <Route path="regulamin" element={<Status />} />
                <Route path="o_kredycie" element={<Examples />} />
                <Route path="forum" element={<Forum />} />
                <Route path="porady" element={<Tips />} />
              </Route>
            ))}
          </Routes>
        </section>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        />
        <ins
          className="adsbygoogle"
          style={{ display: 'block', textAlign: 'center' }}
          data-ad-layout="in-article"
          data-ad-format="fluid"
          data-ad-client="ca-pub-1484967379654435"
          data-ad-slot="8957205265"
        />
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        <Footer />
      </LangObjProvider>
    </Router>
  );
};

export default App;
