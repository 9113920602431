import React from 'react';
import { Helmet } from 'react-helmet';
import AdSenseFrame from '../sub/AdSenseFrame';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <Helmet>
        <title>{t('about.PAGE_TITLE')}</title>
      </Helmet>
      <article>
        <header>
          <h1 className="py-5 text-center">{t('about.ABOUT_ME')}</h1>
        </header>
        <div className="pb-4">
          <h3 className="pb-4">{t('about.HELLO')}</h3>
          <p>{t('about.PARAG1')} </p>
          <p>
            {t('about.PARAG2')} <i className="far fa-grin-beam" />
          </p>
          <hr />
          <p>{t('about.PARAG3')} </p>
          <p>
            <b className="text-secondary"> 85 1160 2202 0000 0002 7793 8709 </b>
          </p>
          <hr />
          <p>{t('about.PARAG4')}</p>
          <p>{t('about.PARAG5')}</p>
          <p>{t('about.PARAG6')}</p>
          <p>{t('about.PARAG7')}</p>
          <hr />
          <p>{t('about.PARAG8')}</p>
          <ol>
            <li>{t('about.PARAG9')}</li>
            <li>{t('about.PARAG10')}</li>
            <li>{t('about.PARAG11')}</li>
            <li>{t('about.PARAG12')}</li>
            <li>{t('about.PARAG13')}</li>
            <li>{t('about.PARAG14')}</li>
            <li>{t('about.PARAG15')}</li>
            <li>{t('about.PARAG16')}</li>
            <li>{t('about.PARAG17')}</li>
            <li>{t('about.PARAG18')}</li>
            <li>{t('about.PARAG19')}</li>
            <li>{t('about.PARAG20')}</li>
            <li>{t('about.PARAG21')}</li>
          </ol>
        </div>
        <AdSenseFrame slotId="7226374826" />
      </article>
    </div>
  );
};

export default About;
