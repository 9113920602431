import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Tweet = ({
  content,
  id,
  author,
  time,
  likes,
  addLike,
  tweets,
  setTweets,
  setIsFetching,
  setError,
  lang,
}) => {
  const { t } = useTranslation();
  const [liked, setLiked] = useState(false);

  const checkLike = (id) => {
    if (!liked)
      addLike({ id, tweets, setTweets, setIsFetching, setError, lang });
    setLiked(true);
  };

  return (
    <div>
      <Row className="bg-dark py-2">
        <Col className="tweet-left">
          <i className="fas fa-user pr-2" />
          {` ${author}`}
        </Col>
        <Col className="tweet-right">
          <i
            title={t('forum.LIKE_IT')}
            className={`fas fa-heart ${liked && 'liked'}`}
            onClick={() => checkLike(id)}
          />
          {` ${likes}   ${moment(time).format('YYYY-MM-DD HH:mm')}`}
        </Col>
      </Row>
      <Row className="tweet-content pt-2 pb-3 px-2 mb-3">{content}</Row>
    </div>
  );
};

export default Tweet;
