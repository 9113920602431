import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useLangObjContext } from '../../providers/LangObjProvider';
import availableLangObjs from '../../locals/availableLangObjs';
import { useTranslation } from 'react-i18next';

const Navi = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { langObj, setLangObj } = useLangObjContext();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const navToPageLangVersion = (lgObj) => {
    const path = window.location.pathname.split('/').slice(-1)[0];
    setLangObj(lgObj);
    i18n.changeLanguage(langObj.lang);
    navigate(`${lgObj.subPath}/${path}`);
  };

  return (
    <Navbar color="dark" dark expand="lg" className="p-0">
      <NavbarBrand tag={Link} to={`${langObj.subPath}/`} className="logo">
        <div className="logo-icon logo-red"></div>
        <div className="logo-icon logo-yellow"></div>
        <div className="logo-icon logo-grey"></div>
      </NavbarBrand>
      <NavbarToggler onClick={() => setIsOpen((prev) => !prev)} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ms-auto navigation" navbar>
          <NavItem>
            <NavLink tag={Link} to={`${langObj.subPath}/o_mnie`}>
              {t('nav.ABOUT_ME')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={`${langObj.subPath}/o_kredycie`}>
              {t('nav.ABOUT_CREDIT')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={`${langObj.subPath}/porady`}>
              {t('nav.TIPS')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={`${langObj.subPath}/regulamin`}>
              {t('nav.STATUTE')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to={`${langObj.subPath}/forum`}>
              {t('nav.FORUM')}
            </NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              <img
                alt={langObj.lang}
                src={`/images/countries/${langObj.lang}.png`}
              />{' '}
              {langObj.lang.toUpperCase()}
            </DropdownToggle>
            <DropdownMenu dark>
              {availableLangObjs
                .filter((lgObj) => lgObj.lang !== langObj.lang)
                .map((lgObj) => (
                  <DropdownItem
                    disabled={lgObj.lang === 'ua'}
                    key={lgObj.lang}
                    onClick={() => navToPageLangVersion(lgObj)}
                  >
                    <img
                      alt={langObj.lang}
                      src={`/images/countries/${lgObj.lang}.png`}
                    />{' '}
                    {lgObj.lang.toUpperCase()}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Navi;
