import encode from './encode';
import i18next from 'i18next';

export const callAPI = async ({
  source,
  data,
  method,
  setIsFetching,
  setError,
  lang,
}) => {
  setIsFetching(true);
  let resJson = null;
  try {
    const options =
      method === 'post'
        ? {
            method: method,
            body: JSON.stringify(data),
          }
        : {};
    const res = await fetch('/.netlify/functions/' + source, options);
    resJson = await res.json();
    resJson = resJson.data;
  } catch (err) {
    i18next.changeLanguage(lang);
    console.log(`Error occurred in ${source}`, err);
    setError(i18next.t('errors.SOMETHING_WRONG_HAPPENED'));
  }
  setIsFetching(false);
  return resJson;
};

export const getTweets = async ({
  setTweets,
  setCount,
  setIsFetching,
  setError,
  offset = 0,
  lang,
}) => {
  const res = await callAPI({
    source: `tweetRead?offset=${offset}`,
    data: {},
    method: 'get',
    setIsFetching,
    setError,
    lang,
  });
  if (res) {
    const { tweets, count } = res;
    setTweets(tweets);
    setCount(count);
  }
};

export const addTweet = async ({
  e,
  newTweetAuthor,
  newTweetContent,
  setTweets,
  setNewTweetAuthor,
  setNewTweetContent,
  setError,
  setIsFetching,
  lang,
}) => {
  e.preventDefault();

  const newTweet = {
    author: newTweetAuthor,
    content: newTweetContent,
  };
  try {
    setIsFetching(true);
    await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'creditForumForm',
        author: newTweetAuthor,
        content: newTweetContent,
      }),
    });
    const tweet = await callAPI({
      source: 'tweetCreate',
      data: newTweet,
      method: 'post',
      setIsFetching,
      setError,
      lang,
    });
    if (tweet) {
      setTweets((prev) => [tweet, ...prev]);
      setNewTweetAuthor('');
      setNewTweetContent('');
      setError(null);
    }
  } catch (err) {
    setError(err);
  }
};

export const addLike = async ({
  id,
  tweets,
  setTweets,
  setIsFetching,
  setError,
  lang,
}) => {
  const tweet = tweets.find((tweet) => tweet._id === id);
  if (tweet) {
    const data = { ...tweet, likes: tweet.likes + 1 };
    const resTweet = await callAPI({
      source: 'tweetUpdate',
      data,
      method: 'post',
      setIsFetching,
      setError,
      lang,
    });
    if (resTweet) {
      setTweets((prev) => prev.map((tw) => (tw._id === id ? resTweet : tw)));
    }
  }
};
