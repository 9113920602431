import React, { useState, useEffect } from 'react';
import {
  Form,
  FormGroup,
  Input,
  Col,
  Label,
  Collapse,
  Button,
  CardBody,
  Card,
} from 'reactstrap';
import moment from 'moment';
import Area from './Area';
import AdSenseFrame from './AdSenseFrame';
import validate from '../../utils/validateFieldsOnFly';
import ReactToPrint from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { calcSurchargesSumForIthMonth } from '../../utils/calcSurchargesSumForIthMonth';

const Dts = ({ allData, printRef, surcharges }) => {
  const [collapse, setCollapse] = useState(false);
  const [dataState, setDataState] = useState([]);
  const [DTSvalue, setDTSvalue] = useState(500);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const {
    data,
    firstRateDay,
    creditValue,
    serviceChargeCost,
    interestsTotal,
    constantFeesTotal,
    changingFeesTotal,
    creditCost,
  } = allData;

  useEffect(() => {
    if (!errors.errDTSvalue) {
      setDataState(() =>
        allData.data.map((d) => ({
          dkNumber: d.dkNumber,
          percent: (100 * d.total) / DTSvalue,
        })),
      );
    }
  }, [allData.data, DTSvalue, errors.errDTSvalue]);

  const onCalcDTSvalue = (value) => {
    setDTSvalue((prev) =>
      validate({
        prev,
        value,
        errName: 'errDTSvalue',
        fieldName: 'DTSvalue',
        setErrors,
      }),
    );
  };
  const exportCSV = () => {
    const mainData = [
      [t('general.CREDIT_AMOUNT'), creditValue.toFixed(2)],
      [t('general.INTEREST_TOTAL'), interestsTotal.toFixed(2)],
      [t('general.SERVICE_CHARGE'), serviceChargeCost.toFixed(2)],
      [t('general.CONSTANT_FEES_TOTAL'), constantFeesTotal.toFixed(2)],
      [t('general.CHANGING_FEES_TOTAL'), changingFeesTotal.toFixed(2)],
      [t('general.CREDIT_COST_TOTAL'), creditCost.toFixed(2)],
      [t('general.TOTAL'), (creditCost - creditValue).toFixed(2)],
    ];

    const csvMainDataContent = `data:text/csv;charset=utf-8,${mainData
      .map((d) => d[0])
      .join(';')}\n${mainData
      .map((d) => d[1].toString().replace('.', ','))
      .join(';')}\n\n`;

    const calcDataHeaderMap = [
      [t('general.RATE_NUMBER'), 'dkNumber'],
      [t('general.DATE'), 'dkDate'],
      [t('general.REMAIN_TO_PAY'), 'dkRemainToPay'],
      [t('general.CAPITAL_PART'), 'dkCapitalPart'],
      [t('general.INTEREST'), 'dkInterestPart'],
      [t('general.CONSTANT_FEES'), 'dkConstantFees'],
      [t('general.CHANGING_FEES'), 'dkChangingFees'],
      [t('general.SUM'), 'total'],
    ];
    const calcDataHeaderRow = calcDataHeaderMap.map((cd) => cd[0]).join(';');
    let csvCalcDataContent = '';
    const dataWithDate = data.map((dt) => {
      let surchargeValuesSumForIthMonth = calcSurchargesSumForIthMonth({
        surcharges,
        ithMonth: dt.dkNumber,
        firstRateDay,
      })
        .toString()
        .replace('.', ',');
      return {
        ...dt,
        dkDate: moment(firstRateDay)
          .add(dt.dkNumber - 1, 'months')
          .format('YYYY-MM'),
        total: surchargeValuesSumForIthMonth
          ? `${dt.total} + ${surchargeValuesSumForIthMonth}`
          : `${dt.total}`,
      };
    });

    dataWithDate.forEach((d) => {
      calcDataHeaderMap.forEach((headTit) => {
        csvCalcDataContent += `${d[headTit[1]].toString().replace('.', ',')};`;
      });
      csvCalcDataContent += '\n';
    });

    const CSVfile =
      csvMainDataContent + calcDataHeaderRow + '\n' + csvCalcDataContent;

    const encodedUri = encodeURI(CSVfile);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'credit calculation.csv');
    document.body.appendChild(link);
    link.click();
    window.open(encodedUri);
    document.body.removeChild(link);
  };

  return (
    <div>
      <Button
        className="me-3 mb-3"
        color="primary"
        onClick={() => {
          setCollapse((prev) => !prev);
        }}
      >
        {t('dts.CHECK_YOUR_DTS')}
      </Button>
      <Button className="me-3 mb-3" onClick={exportCSV}>
        {t('general.EXPORT_TO_CSV')}
      </Button>
      <ReactToPrint
        trigger={() => (
          <Button color="dark" className="mb-3">
            {t('general.PRINT')}
          </Button>
        )}
        content={() => printRef.current}
      />
      <Collapse isOpen={collapse}>
        <Card className="dti-card">
          <CardBody>
            <p>
              <strong>{t('dts.DTS_ABBR')}</strong>. {t('dts.PARAG1')}
            </p>
            <p>{t('dts.PARAG2')}</p>
            <p>
              {t('dts.PARAG3')} (<i className="far fa-laugh-wink" />)
              {t('dts.PARAG4')} <i className="far fa-dizzy"></i>?{' '}
              {t('dts.PARAG5')}
            </p>
            <ul>
              {t('dts.DTS_VALUE')}
              <li>
                {
                  '<= 30% - super, kredyt nie powinien być problemem dla Twojego budżetu'
                }
              </li>
              <li>{t('dts.RANGE1')}</li>
              <li>{t('dts.RANGE2')}</li>
              <li>&gt; {t('dts.RANGE3')}</li>
            </ul>
            <Form>
              <FormGroup className="form-group" row>
                <Label for="savingsValue" sm={7}>
                  {t('dts.MONTHLY_SAVINGS_VALUE')}*:
                </Label>
                <Col sm={5}>
                  <Input
                    name="savingsValue"
                    type="text"
                    value={DTSvalue}
                    onChange={(e) => onCalcDTSvalue(e.target.value)}
                  />
                  {errors.errSavings && (
                    <p className="m-0 error">{errors.errSavings}</p>
                  )}
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        {dataState && (
          <Area
            data={dataState}
            width={720}
            height={400}
            margin={{ left: 70, right: 70, bottom: 70, top: 70 }}
            className="pb-3"
          />
        )}
      </Collapse>
      <AdSenseFrame slotId="3744642425" />
    </div>
  );
};

export default Dts;
