import { monthBeginningMoment } from './dateHelpers';
import moment from 'moment';

export const calcSurchargesSumForIthMonth = ({
  surcharges,
  ithMonth,
  firstRateDay,
}) => {
  const filteredSurchargesForIthMonth = surcharges.filter(
    (sur) =>
      monthBeginningMoment(
        moment(firstRateDay).add(ithMonth - 1, 'months'),
      ).isSameOrAfter(monthBeginningMoment(sur.startDate)) &&
      monthBeginningMoment(
        moment(firstRateDay).add(ithMonth - 1, 'months'),
      ).isSameOrBefore(monthBeginningMoment(sur.endDate)),
  );
  const surchargeValuesSumForIthMonth = filteredSurchargesForIthMonth.reduce(
    (sum, sur) => sum + parseFloat(sur.value),
    0,
  );
  return surchargeValuesSumForIthMonth;
};
