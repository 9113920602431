import React, { Fragment, useEffect } from 'react';
import pl from 'date-fns/locale/pl';
import en from 'date-fns/locale/en-US';
import ua from 'date-fns/locale/uk';

import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Col, Row, Button, Label, Tooltip, FormGroup, Input } from 'reactstrap';
import { monthEnd, monthBeginning } from '../../utils/dateHelpers';
import { CONSTANT_FEE } from '../../constants/constants';
import { useTranslation } from 'react-i18next';
import { useLangObjContext } from '../../providers/LangObjProvider';

export const FeeFormGroup = ({
  errors,
  feeType,
  forName,
  labelText,
  isTooltipOpen,
  setIsTooltipOpen,
  tooltipText,
  fees,
  valueTxt,
  valueUnit,
  addFee,
  removeFee,
  updateFee,
  testid,
}) => {
  const { t } = useTranslation();
  const { langObj } = useLangObjContext();
  useEffect(() => {
    //used to set date-pickers started from Monday (not Sunday)
    registerLocale('pl', pl);
    registerLocale('en', en);
    registerLocale('ua', ua);
    setDefaultLocale(langObj.lang);
  }, [langObj.lang]);
  const err =
    errors[feeType === CONSTANT_FEE ? `errConstantFee` : `errChangingFee`];
  return (
    <FormGroup id={`${feeType}s`} className="form-group" row>
      <Label for={`${forName}s`} sm={4}>
        {labelText}
      </Label>
      <Tooltip
        placement="bottom"
        hideArrow={true}
        isOpen={isTooltipOpen}
        target={`${feeType}s`}
        toggle={(prev) => {
          setIsTooltipOpen(!prev);
        }}
        className="bg-dark"
      >
        {tooltipText}
      </Tooltip>
      <Col sm={8}>
        <Button
          data-testid={testid + '-add-btn'}
          className="btn-secondary mb-3"
          onClick={() => addFee(feeType, `${forName}-${new Date().getTime()}`)}
        >
          {t('general.ADD_FEE')}
        </Button>
        {fees.map((fee, i) => (
          <Fragment key={`${feeType}Row-${fee.key}`}>
            {i === 0 && (
              <Row key={`${feeType}Row-labels-${fee.key}`}>
                <Col sm={4} className="date-picker">
                  <Label for={`${forName}s-start-date`}>
                    {t('general.FROM')}
                  </Label>
                </Col>
                <Col sm={4} className="date-picker">
                  <Label for={`${forName}s-end-date`}>{t('general.TO')}</Label>
                </Col>
                <Col sm={4} className="date-picker">
                  <Label for={`${forName}s-value`}>{valueTxt}</Label>
                </Col>
              </Row>
            )}
            <Row key={`${feeType}Row-${fee.key}`} className="pb-3">
              <Col sm={4} className="date-picker">
                <DatePicker
                  name={`${forName}s-start-date`}
                  className="form-control"
                  placeholderText={`${forName}s-start-date-field`}
                  dateFormat="yyyy-MM"
                  showMonthYearPicker
                  selectsStart
                  selected={fee.startDate}
                  onChange={(e) => {
                    updateFee({
                      type: 'startDate',
                      feeType,
                      key: fee.key,
                      startDate: e && monthBeginning(new Date(e)),
                      value: `${fee.value}`,
                    });
                  }}
                />
              </Col>
              <Col sm={4} className="date-picker">
                <DatePicker
                  name={`${forName}s-end-date`}
                  placeholderText={`${forName}s-end-date-field`}
                  className="form-control"
                  dateFormat="yyyy-MM"
                  showMonthYearPicker
                  selectsEnd
                  selected={monthEnd(new Date(fee.endDate))}
                  onChange={(e) =>
                    updateFee({
                      type: 'endDate',
                      feeType,
                      key: fee.key,
                      endDate: e && monthEnd(new Date(e)),
                      value: `${fee.value}`,
                    })
                  }
                />
              </Col>
              <Col sm={3}>
                <Input
                  name={`${feeType}-${fee.key}`}
                  data-testid={testid + '-value-field'}
                  className={
                    'mb-2 ' +
                    (errors[
                      feeType === CONSTANT_FEE
                        ? 'errConstantFee'
                        : 'errChangingFee'
                    ] && 'error')
                  }
                  placeholder={valueUnit}
                  value={fee.value}
                  type="text"
                  onChange={(e) =>
                    updateFee({
                      type: 'value',
                      feeType,
                      key: fee.key,
                      value: `${e.target.value}`,
                    })
                  }
                />
              </Col>
              <Col
                className={
                  'd-flex justify-content-center align-items-center pb-2'
                }
                sm={1}
              >
                <button
                  onClick={() => removeFee(feeType, fee.key)}
                  data-testid={testid + '-remove-btn'}
                  className="btn btn-default"
                >
                  <div className="btn-close" />
                </button>
              </Col>
            </Row>
          </Fragment>
        ))}
        {err && (
          <p className="m-0 error" data-testid={testid + '-error'}>
            {err}
          </p>
        )}
      </Col>
    </FormGroup>
  );
};
