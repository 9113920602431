import moment from 'moment';

// first of month (last hour of the day - no timezones issues :)
export const monthBeginning = (value) => {
  return moment(value).startOf('month').startOf('day').toDate();
};
export const monthBeginningMoment = (value) =>
  moment(value).startOf('month').startOf('day');

// last of month
export const monthEnd = (value) => {
  return moment(value).endOf('month').endOf('day').toDate();
};
export const monthEndMoment = (value) => {
  return moment(value).endOf('month').endOf('day');
};

// last possible date of charge
export const creditLastDay = (firstRateDay, creditDuration) =>
  moment(firstRateDay)
    .add(creditDuration - 1, 'months')
    .endOf('month')
    .endOf('day')
    .toDate();
