export default function setInitState(state) {
  // set localStorage
  delete state.allData;
  Object.keys(state).forEach((key) => {
    // for Date type keys
    if (['firstRateDay'].includes(key)) {
      localStorage.setItem(key, state[key].getTime());
    } else if (['changingFees', 'constantFees', 'surcharges'].includes(key)) {
      localStorage.setItem(
        key,
        JSON.stringify(
          state[key].map((obj) => ({
            ...obj,
            startDate: obj.startDate.getTime(),
            endDate: obj.endDate.getTime(),
          })),
        ),
      );
    } else if (['tranches', 'wiborChanges'].includes(key)) {
      localStorage.setItem(
        key,
        JSON.stringify(
          state[key].map((obj) => ({
            ...obj,
            date: obj.date.getTime(),
          })),
        ),
      );
    } else {
      localStorage.setItem(key, state[key]);
    }
  });
  localStorage.setItem('calculationDate', new Date().getTime());

  // set URL params
  const stateStr = JSON.stringify({
    ...state,
    firstRateDay: state.firstRateDay.getTime(),
    changingFees: state.changingFees.map((fee) => ({
      ...fee,
      startDate: fee.startDate.getTime(),
      endDate: fee.endDate.getTime(),
    })),
    constantFees: state.constantFees.map((fee) => ({
      ...fee,
      startDate: fee.startDate.getTime(),
      endDate: fee.endDate.getTime(),
    })),
    surcharges: state.surcharges.map((sur) => ({
      ...sur,
      startDate: sur.startDate.getTime(),
      endDate: sur.endDate.getTime(),
    })),
    tranches: state.tranches.map((tra) => ({
      ...tra,
      date: tra.date.getTime(),
    })),
    wiborChanges: state.wiborChanges.map((wib) => ({
      ...wib,
      date: wib.date.getTime(),
    })),
  });
  const serialisedStateStr = encodeURIComponent(stateStr);
  // replace chars forbidden for URL
  const shortenSerStateStr = serialisedStateStr
    .replaceAll('%22', '_') // "
    .replaceAll('%3A', '~') // :
    .replaceAll('%2C', 'X') // ,
    .replaceAll('%7D', '*'); // }
  const urlParams = new URLSearchParams({ calc: shortenSerStateStr });
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${urlParams}`,
  );
}
