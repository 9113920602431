import React, { useState, useEffect } from 'react';
import Tweet from '../sub/Tweet';
import { Helmet } from 'react-helmet';
import ReactPaginate from 'react-paginate';
import { Spinner } from 'reactstrap';
import ForumForm from '../sub/ForumForm';
import { getTweets, addLike } from '../../utils/forumUtils';
import { useTranslation } from 'react-i18next';
import { useLangObjContext } from '../../providers/LangObjProvider';

const Forum = () => {
  const [tweets, setTweets] = useState([]);
  const [count, setCount] = useState(0);
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const MAX_ITEMS = 10;
  const tweetsCount = Math.ceil(count / MAX_ITEMS);
  const { langObj } = useLangObjContext();
  const { t } = useTranslation();

  useEffect(() => {
    getTweets({
      setTweets,
      setCount,
      setIsFetching,
      setError,
      lang: langObj.lang,
    });
  }, [langObj.lang]);

  const handlePageClick = (pageNumber) => {
    const offset = pageNumber * 10;
    getTweets({
      setTweets,
      setCount,
      offset,
      setIsFetching,
      setError,
      lang: langObj.lang,
    });
    window.scrollTo(0, 0);
  };

  return (
    <div className="container">
      <Helmet>
        <title>{t('forum.PAGE_TITLE')}</title>
      </Helmet>
      <article className="text-center">
        <header>
          <h1 className="py-5">{t('forum.FORUM')}</h1>
          <h3 className="pb-3">{t('forum.ADD_YOUR_COMMENT')}</h3>
        </header>
        {isFetching && (
          <div className="vh-100">
            <Spinner />
          </div>
        )}
        {error ? (
          <div className="text-center vh-100 error">{error}</div>
        ) : (
          <div className="forum">
            {tweets.map((tweet) => (
              <div key={tweet.time} className="mx-3">
                <Tweet
                  id={tweet._id}
                  author={tweet.author}
                  time={tweet.time}
                  content={tweet.content}
                  likes={tweet.likes}
                  lang={langObj.lang}
                  addLike={addLike}
                  tweets={tweets}
                  setTweets={setTweets}
                  setIsFetching={setIsFetching}
                  setError={setError}
                />
              </div>
            ))}
            <ForumForm
              setTweets={setTweets}
              setError={setError}
              setIsFetching={setIsFetching}
              lang={langObj.lang}
            />
            <div className="col-md-6 offset-md-3 text-center my-4">
              <ReactPaginate
                previousLabel={
                  <span className="fa fa-angle-left pagination-arrow" />
                }
                nextLabel={
                  <span className="fa fa-angle-right pagination-arrow" />
                }
                breakLabel={<span>...</span>}
                breakClassName={'break'}
                pageCount={tweetsCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={(e) => handlePageClick(e.selected)}
                containerClassName={'pagination'}
                activeClassName={'active'}
                initialPage={0}
                disableInitialCallback={true}
              />
            </div>
          </div>
        )}
      </article>
    </div>
  );
};

export default Forum;
