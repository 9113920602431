export const locals = {
  pl: {
    translation: {
      general: {
        BASIC_DATA: 'Dane podstawowe',
        ADDITIONAL_DATA: 'Dane rozszerzone',
        SITE_TITLE:
          'Kalkulator kredytu Tosi - nadpłaty, zmiany wiboru, transze, RRSO, opłaty stałe i zmienne',
        LOCAL_STORAGE_USAGE_INFO:
          'Strona używa local storage, żebyś nie musiał za każdym razem wpisywać tych samych danych ;)',
        MARGIN: 'Marża',
        BANK_MARGIN: 'Marża banku',
        WIBOR: 'Wibor',
        WIBOR_START: 'Wibor początkowy',
        SERVICE_CHARGE: 'Prowizja',
        SURCHARGE: 'nadpłata',
        SURCHARGES: 'Nadpłaty',
        ADD_SURCHARGE: 'Dodaj nadpłatę',
        FOR_SHORTEN: 'na skrócenie',
        NEW_VALUE: 'nowa wartość',
        ADD_CHANGE: 'Dodaj zmianę',
        ADD_FEE: 'Dodaj opłatę',
        CONSTANT_FEES: 'Opłaty stałe',
        CHANGING_FEES: 'Opłaty zmienne',
        TRANCHES: 'Transze',
        ADD_TRANCHE: 'Dodaj transzę',
        TRANCHE_VALUE: 'Wartość transzy',
        PERCENTAGE: 'Oprocentowanie',
        PERCENT: 'Procent',
        AMOUNT: 'Kwota',
        CREDIT_COST: 'Koszt kredytu',
        CREDIT_AMOUNT: 'Kwota kredytu',
        CREDIT_PERIOD: 'Okres kredytowania',
        INTEREST: 'Odsetki',
        INTEREST_TOTAL: 'Odsetki do spłaty',
        CAPITAL_PART: 'Część kapitałowa raty',
        REMAIN_TO_PAY: 'Pozostało do spłaty',
        STATUTE: 'Regulamin',
        ACCEPT_STATUTE: 'Akceptuję regulamin',
        CONSTANT_FEES_TOTAL: 'Opłaty stałe łącznie',
        CHANGING_FEES_TOTAL: 'Opłaty zmienne łącznie',
        DTS: 'DTS',
        CREDIT_TIME: 'Czas kredytu',
        YEARS: 'lat',
        RATE_TYPE: 'Rodzaj rat',
        CONSTANT_INSTALLMENTS: 'Raty równe',
        DECREASING_INSTALLMENTS: 'Raty malejące',
        ZL_PER_MONTH: 'zł/mies.',
        ZL: 'zł',
        CURRENCY: 'PLN',
        MONTH: 'mies.',
        RATE_WITHOUT_FEES: 'Rata (bez opłat)',
        RATE_WITH_FEES: 'Rata (z opłatami)',
        FIRST_RATE: 'pierwsza',
        AFTER_CHANGE: 'po zmianie',
        CREDIT_COST_FULL_WITHOUT_FEES:
          'Całkowity koszt kredytu (bez opłat dodatkowych)',
        CREDIT_COST_FULL_WITH_FEES: 'Całkowity koszt kredytu (z opłatami)',
        CREDIT_COST_TOTAL: 'Całkowity koszt kredytu',
        SORRY_FOR_ADVERT: 'Sorki za reklamę',
        TOTAL: 'Razem',
        DATE: 'Data',
        SUM: 'Suma',
        RATE_NUMBER: 'Lp.',
        EXPORT_TO_CSV: 'Export do CSV',
        PRINT: 'Drukuj',
        FROM: 'Od',
        TO: 'Do',
      },
      nav: {
        ABOUT_ME: 'O MNIE',
        ABOUT_CREDIT: 'O KREDYCIE',
        TIPS: 'PORADY',
        STATUTE: 'REGULAMIN',
        FORUM: 'FORUM',
      },
      form: {
        PERCENT_OF_REMAINING_CAPITAL: '% pozostałego kapitału',
        CONSTANT_FEE_TOOLTIP: 'Opłaty stałe co miesiąc (np. 20zł/mies.)',
        CHANGING_FEE_TOOLTIP:
          'Opłaty zmienne, zależne od pozostałej kwoty kredytu (wartość w %)',
        MONTH_OF_FIRST_CAPITAL_PART_PAYMENT:
          'Miesiąc pobrania pierwszej raty kapitałowej',
        WIBOR_CHANGE: 'Zmiana Wiboru',
        FOR_SHORTEN_TOOLTIP:
          'Zaznacz, jeśli nadpłaty mają być liczone na skrócenie raty. Odznaczone, jeśli nadpłaty mają służyć zmniejszeniu raty',
      },
      dts: {
        CHECK_YOUR_DTS: 'Sprawdź swój DTS',
        DTS_ABBR: 'DTS - Debt to Savings',
        PARAG1:
          'To stosunek Twojego zadłużenia do oszczędności w ciągu miesiąca. To współczynnik zbliżony do współczynnika DTI (Debt to Income) jednocześnie będąc bardziej od niego "prawdziwym". Za jego pomocą możesz określić, czy rata kredytu będzie bezpieczna dla Twojego budżetu.',
        PARAG2: 'Dlaczego DTS jest lepszy od DTI i po co go sprawdzać?',
        PARAG3:
          'Załóżmy, że zastanawiasz się nad kredytem z ratą 900zł na 30 lat, zarabiasz 10.000zł netto',
        PARAG4:
          ', ale miesięcznie odkładasz 100zł. DTI będzie optymistyczny, natomiast DTS wskaże wysokie ryzyko. Skoro dotychczas nie odkładałeś, dlaczego miałbyś teraz to zmienić? Podobnie, gdy wartość Twoich miesięcznych oszczędności będzie równa wyliczanej racie - co w sytuacji, gdy pojawi się "nagły wydatek"? Weźmiesz kolejny kredyt',
        PARAG5:
          'Jeśli teraz Twoje oszczędności są niewielkie lub nie umiesz oszczędzać wcale, kredyt prawie na pewno będzie dla Ciebie niemałym kłopotem...przez 30 lat.',
        DTS_VALUE: 'Wartość DTS',
        MONTHLY_SAVINGS_VALUE: 'Wartość Twoich miesięcznych oszczędności',
        RANGE1: '30-50% - OK, Twój budżet będzie raczej bezpieczny',
        RANGE2: '50-75% - może być, ale mogłoby być lepiej ;)',
        RANGE3: '75% - wzięcie kredytu to duże ryzyko dla Twojego budżetu',
      },
      home: {
        SITE_TITLE: 'KALKULATOR KREDYTU TOSI',
        TURN_OFF_ADBLOCK: 'Wyłącz AdBlocka - proszę',
        RESTORE_DEFAULT: 'Przywróć domyślne',
        CALC_SUBMIT: 'Przelicz',
      },
      about: {
        PAGE_TITLE: 'Kalkulator kredytu - o mnie',
        ABOUT_ME: 'O mnie',
        HELLO: 'Cześć!',
        PARAG1:
          'Napisałam ten kalkulator dla ludzi takich jak ja, którzy kredyt wziąć musieli (lub muszą), ale chcą zapłacić za niego jak najmniej i spłacić go jak najszybciej.',
        PARAG2:
          'Życzę Ci braku kredytu! Żebyś spłacił go jak najszybciej, jak najmniejszym kosztem. A swoje pieniądze poświęcił na własne cele, nie na utrzymywanie instytucji, których sponsorować nie chcesz',
        PARAG3:
          'Jeśli mój kalkulator Ci się spodobał chciałbyś mnie wesprzeć w kolejnych podobnych inicjatywach będzie mi miło, jeśli "kupisz mi kawę" i przelejesz jej wartość na mój nr konta:',
        PARAG4: 'Chciałabym także bardzo podziękować:',
        PARAG5:
          'Mojemu mężowi, Kubie - za wiele bardzo dobrych pomysłów, wsparcie ogólne i bardzo merytoryczne w rozkminianiu wszelakich wzorów i obliczeń (w naprawdę bardzo różnych miejsach i sytuacjach xD)',
        PARAG6: 'Danielowi A. - za ideę wizualizacji współczynnika DTS :D',
        PARAG7:
          'Grzesiowi W. i Grzesiowi GW. - za zmotywowanie mnie do napisania testów dla wszystkich nowych funkcjolaności (tak strasznie mi się nie chciało, ale wiem, wiem...TDD over all! Dzięki!)',
        PARAG8: 'Ostatnie zmiany (05.12.2023):',
        PARAG9:
          'Wprowadzono możliwość dodania zmiany wiboru oraz nadpłaty (wcześniej możliwe było jedno lub(!) drugie)',
        PARAG10: 'Dodano "Pozostało do spłaty" w tabeli wyników',
        PARAG11:
          'Dodano "Suma+nadpłata" w tabeli wyników (jeśli dodano nadpłaty)',
        PARAG12: 'Dodano możliwość eksportu danych do CSV oraz ich wydruku',
        PARAG13: 'Dodano podliczenie RRSO',
        PARAG14: 'Dodano możliwość rozbicia kredytu na transze',
        PARAG15: 'Dodano tłumaczenie EN (planowane jest także UA jeszcze)',
        PARAG16: 'Dodano możliwość wysyłania zadanych parametrów za pomocą URL',
        PARAG17: 'Poprawiono nieco UI ;)',
        PARAG18: 'Podbito packeages',
        PARAG19:
          'Odświeżono przykłady i domyślne wartości (choć trudno nadążyć...)',
        PARAG20:
          'Dodano zakładkę "Porady" - to tylko moje przemyślenia! Doradcą finansowym nie jestem! ;)',
        PARAG21: 'Oczywiście wszystkie funkcjonalności są pokryte testami ;)',
      },
      examples: {
        PAGE_TITLE:
          'O kredycie, przykłady dla różnych parametrów kredytu i z czego składa się kredyt',
        SUB_TITLE1: 'O kredycie',
        SUB_TITLE2: 'Czyli co warto wiedzieć',
        EXP_MARGIN:
          'Część oprocentowania kredytu. Marża to procent po jakim bank(!) pożycza pieniądze od innego banku (tak, bank nie ma "własnych" pieniędzy - po prostu pożycza je od innego banku...). Im jest wyższa, tym więcej musisz zapłacić. Nie zmienia się w trakcie trwania kredytu',
        EXP_WIBOR:
          'Także część oprocentowania kredytu. Wibor jest jednak zmienny w czasie! Zmienia się co kilka miesięcy i może spowodować że Twoja rata wzrośnie lub zmaleje',
        EXP_SERVICE_CHARGE:
          ' Jednorazowa opłata jaką bank pobiera za udzielenie kredytu. Jest to koszt płatny zwykle na początku spłaty kredytu (ale może być też wliczony do kwoty wziętego kredytu)',
        EXP_CONSTANT_FEES:
          'Przykładowo: ubezpieczenie nieruchomości, wynosi tyle samo przez cały czas trwania kredytu. Ta opłata NIE JEST(!) doliczana do wysokości raty (a to nadal miesięczny koszt, który musisz zapłacić)',
        EXP_CHANGING_FEES:
          'Przykładowo: ubezpieczenie na życie. Jego wartość to określony procent kapitału pozostałego do spłaty. Co miesiąc kwota tej opłaty będzie coraz niższa (jeśli spłacasz część kapitałową swojego kredytu). Ta opłata NIE JEST(!) doliczana do wysokości raty (a to nadal miesięczny koszt, który musisz zapłacić)',
        EXP_CREDIT_COST:
          ' To całkowita kwota jaką zapłacisz bankowi i ubezpieczycielom. UWAGA(!): dobrze planując spłatę swojego kredytu (np. nadpłacając kredyt, itp.) możesz naprawdę znacząco obniżyć tę kwotę!',
        EXP_DTS:
          'Debt To Savings. To współczynnik określający bezpieczeństwo Twojego budżetu, gdy zdecydujesz się na wzięcie kredytu. Oblicza się go dzieląc wartość miesięcznego zadłużenia do miesięcznych oszczędności',
        PARAG1: 'Z czego składa się kredyt',
        PARAG2: 'To marża plus wibor',
        PARAG3: 'Jak zaplanować/policzyć kredyt używając tego kalkulatora?',
        PARAG4: 'Przykład "z życia"',
        PARAG5:
          'Chcemy kupić mieszkanie. Koszt mieszkania 600tys. złotych. Wkładu własnego mamy 150tys. Zatem kredytować będziemy 450zł',
        PARAG6: 'Bank X przedstawił nam ofertę o warunkach',
        PARAG7: '(Wibor 3M na dzień 25.05.2023)',
        PARAG8: 'Ale także kilka wymaganych ubezpieczeń...',
        PARAG9: 'Na mieszkanie:',
        PARAG10: 'bieżącego zadłużenia/mies.',
        PARAG11: 'Na życie (min. 1 kredytobiorcy)',
        PARAG12: 'Ubezpieczenie o jakiejś głupiej nazwie:',
        PARAG13: 'jednorazowo',
        PARAG14:
          'Oraz od ryzyka wyskoczenia pryszcza na nosie! Ubezpieczenia nazywają się naprawdę różnie, ale zawsze są tym samym :)',
        PARAG15: 'bieżącego zadłużenia/mies. - na 1 rok',
        PARAG16: 'Oto jak policzyć koszt naszego kredytu',
        PARAG17: 'Przykład z życia',
        PARAG18: 'nadpłata po 2 latach',
        PARAG19:
          'ALE po 2 latach spłacania dostaliśmy spadek po "wujku z Ameryki". Postanawiamy zrobić nadpłatę w wysokości 50 tys. na skrócenie kredytu',
        PARAG20: 'wzrost Wiboru po 5 latach',
        PARAG21:
          'ALE po 5 latach spłacania bank centralny ogłosił wzrost stóp procentowych. Nasz Wibor skacze do 12%.',
        PARAG22: 'Przykłady porównawcze',
        PARAG23: 'Ważne',
        PARAG24:
          ' Poniższe przykłady mają na celu porównanie różnych parametrów kredytu. Nie chcę pokazać "co się bardziej opłaca", ponieważ kredyty tak naprawdę bardzo różnią się od siebie w szczegółach. Dopiero mając gotową ofertę z banku można sprawdzić rzeczywisty koszt kredytu. Ale warto też sobie trochę pogdybać...',
        PARAG25:
          'Założyłam przykładowe opłaty stałe (20zł/mies.) i zmienne (0,03% wartości bieżącego zadłużenia), ponieważ są one często pomijane w przykładowych kalkulacjach, a w rzeczywistości osiągają one nawet 10-20% kosztów kredytu(!), o czym warto pamiętać. W przykładach poniżej prowizja wynosi 0%',
        PARAG26: 'Porównanie dla parametrów',
        PARAG27: '100 tys. na start, 100 tys. po roku, 200 po dwóch latach',
        EXAMPLE_TITLE_1: 'Raty równe i raty malejące',
        EXAMPLE_TITLE_2:
          'Gdy kredyt jest o 20tys. mniejszy (30lat, raty równe)',
        EXAMPLE_TITLE_3:
          'Gdy kredyt jest o 5 lat krótszy (400 000tys, raty równe)',
        EXAMPLE_TITLE_4: 'Gdy po roku zmieni się Wibor (większy o 1% (!))',
        EXAMPLE_TITLE_5:
          'Czy warto nadpłacać - raty równe, zmniejszenie raty (nadpłata 30 tys. po 3 latach)',
        EXAMPLE_TITLE_6:
          'Czy warto nadpłacać - raty równe, skrócenie okresu kredytowania (nadpłata 30 tys. po 3 latach)',
        EXAMPLE_TITLE_7:
          'Czy warto nadpłacać szybciej - raty równe, skrócenie okresu kredytowania (nadpłata 30 tys. po 3 latach i po 6 latach)',
        EXAMPLE_TITLE_8: 'Transze czy jednorazowo? Raty równe',
        EXAMPLE_TITLE_9:
          'Czy warto nadpłacać szybciej - raty malejące, zmniejszenie raty (nadpłata 30 tys. po 3 latach)',
        EXAMPLE_TITLE_10:
          'Czy warto nadpłacać - raty malejące, skrócenie okresu kredytowania (nadpłata 30 tys. po 3 latach)',
        EXAMPLE_TITLE_11:
          'Czy warto nadpłacać szybciej - raty malejące, skrócenie okresu kredytowania (nadpłata 30 tys. po 3 latach i po 6 latach)',
        EXAMPLE_TITLE_12: 'Transze czy jednorazowo? Raty malejące',
        IMG_TITLE_1: 'wykres dla rat równych',
        IMG_TITLE_2: 'wykres dla rat malejących',
        IMG_TITLE_3: 'wykres dla 400 tysiecy',
        IMG_TITLE_4: 'wykres dla 380 tysiecy',
        IMG_TITLE_5: 'wykres dla 30lat',
        IMG_TITLE_6: 'wykres dla 25lat',
        IMG_TITLE_7: 'wykres dla wiboru 6,9%',
        IMG_TITLE_8: 'wykres dla wiboru 7,9%',
        IMG_TITLE_9: 'wykres bez nadpłaty dla rat równych',
        IMG_TITLE_10:
          'wykres z nadpłatą 30 tysiecy po 3 latach na zmniejszenie raty dla rat rownych',
        IMG_TITLE_11:
          'wykres z nadpłatą 30 tysiecy po 3 latach na skrocenie okresu kredytowania dla rat rownych',
        IMG_TITLE_12:
          'wykres z nadpłatą 30 tysiecy po 6 latach na skrocenie okresu kredytowania',
        IMG_TITLE_13: 'wykres z trzema transzami dla rat równych',
        IMG_TITLE_14: 'wykres bez nadpłaty dla rat malejących',
        IMG_TITLE_15:
          'wykres z nadpłatą 30 tysiecy po 3 latach na zmniejszenie raty dla rat malejacych',
        IMG_TITLE_16:
          'wykres z nadpłatą 30 tysiecy po 3 latach na skrócenie okresu kredytowania dla rat malejacych',
        IMG_TITLE_21:
          'wykres z nadpłatą 30 tysiecy po 6 latach na skrócenie okresu kredytowania dla rat malejacych',
        AFTER_A_YEAR: 'po roku',
        AFTER_3_YEARS: 'po 3 latach',
        AFTER_6_YEARS: 'po 6 latach',
        AFTER_I_TRANCHE: 'po I transzy',
        AFTER_II_TRANCHE: 'po II transzy',
        AFTER_III_TRANCHE: 'po III transzy',
        AFTER_CHANGE: 'po zmianie',
        SURCHARGE_AFTER_1: 'nadpłata 30tys. po 3 latach',
        SURCHARGE_AFTER_2: 'nadpłata 30tys. po 6 latach',
        WITHOUT_TRANCHES: 'bez transz',
      },
      forum: {
        PAGE_TITLE: 'Kalkulator kredytu - forum',
        FORUM: 'Forum',
        ADD_YOUR_COMMENT: 'Dodaj swój komentarz',
        AUTHOR: 'Autor',
        ALERT_TEXT: 'Nie podszywaj się pode mnie ;) Dzięki',
        ROGUE: 'Oszust',
        LIKE_IT: 'Lubię to',
      },
      status: {
        PAGE_TITLE: 'Regulamin korzystania z kalkulatora',
        PARAG1:
          'Pamiętaj, że wyniki mojego kalkulatora to informacje poglądowe. Harmonogram Twoich rat może różnić się od tych zaprezentowanych w wynikach - choć mam nadzieję, że nieznacznie.',
        PARAG2: 'Na forum proszę o wpisy kulturalne. Inne będę usuwać.',
        PARAG3:
          'PS. Jeśli się gdzieś pomyliłam, to napisz mi, proszę, na forum. Postaram poprawić się jak najszybciej.',
      },
      tips: {
        PAGE_TITLE: 'Porady',
        SUB_TITLE: 'Kilka subiektywnych(!) porad i nie tylko',
        PARAG1: 'Kilka ogólnych i szybkich porad',
        PARAG2:
          'Porównaj różne warianty kredytu samemu! "Pobaw się" kalkulatorem, pogdybaj, wypróbuj różne scenariusze. To nie boli :)',
        PARAG3: 'NIGDY nie bierz kredytu "pod korek"!',
        PARAG4:
          'Banki bardzo często definiują "ratę" jako sumę części kapitałowej oraz odsetkowej, ALE pomijają (a raczej "liczą osobno") comiesięczne opłaty związane z ubezpieczeniami! Nie zapominaj o nich',
        PARAG5:
          'Szacując na jak wysoką ratę miesięcznie będziesz mógł sobie pozwolić',
        PARAG6:
          'NIE(!) bierz pod uwagę miesięcznych dochodów. Zamiast nich sprawdź ile co miesiąc oszczędzasz.',
        PARAG7:
          'Co z tego, że zarabiasz 20tys. na rękę (:D), jeśli opłaty, leasingi, inne kredyty, koszty utrzymania co miesiąc wynoszą Cię 19tys.?',
        PARAG8:
          'Weź kredyt na najmniejszą możliwą kwotę, z jak najkrótszym czasem kredytowania (wiem, łatwo powiedzieć)',
        PARAG9: 'Jak najszybciej staraj się nadpłacać swój kredyt',
        PARAG10:
          '- im szybciej i "agresywniej", tym lepiej. UWAGA: zależnie od umowy, bank może naliczać dodatkowe opłaty związane z nadpłatą. "Porządniejsze" banki nie utrudniają tej procedury. Niemniej jednak warto sprawdzić jaki rodzaj nadpłat jest dla nas najbardziej opłacalny (np. częstsze i małe lub rzadsze i duże) - nadpłacanie z reguły się opłaca ;)',
        PARAG11: 'Pro tip dla nadpłacających',
        PARAG12:
          'Niektóre z banków po dokonaniu nadpłaty na skrócenie kredytu z ratą malejącą pozwalają podnieść kwotę raty do jej pierwotnej wysokości. Spowoduje to, że nasza rata urośnie do wartości z początku kredytu, ALE jednocześnie podniesie to wartość spłacanej części kapiałowej - a tym samym może pozwolić nam jeszcze bardziej skrócić nasz kredyt',
        PARAG13: 'Uważaj na transze.',
        PARAG14:
          'Kredyty rozbite na transze z reguły wymagają płacenia samej części odsetkowej do momentu uruchomienia całości kredytu. Oznacza to, że dopóki bank nie wypłaci Ci wszystkich transz, nie będziesz płacił części kapitałowej. W takim przypadku, Twój kredyt nie maleje(!), za to bankowi płacisz co miesiąc. Dla banku to żyła złota, dla Ciebie niekoniecznie ;)',
        PARAG15: 'Bierz pod uwagę, że Wibor, a co za tym idzie -',
        PARAG16: 'wysokość Twojej raty - może się zmienić.',
        PARAG17:
          'Załóż, że w trakcie trwania Twojego kredytu (np. 10-30lat) sytuacja na rynkach finansowych na pewno się zmieni i lepiej być przygotowanym na gorsze ;)',
        PARAG18: 'Z reguły',
        PARAG19: 'kredyty z ratą malejącą są bardziej opłacalne (tańsze)',
        PARAG20:
          '(niższy koszt kredytu), niż te z ratą równą. ALE musisz pamiętać, że kredyt z ratą malejącą "trudniej dostać", ponieważ jego początkowe raty będą wyższe w porównaniu do kredytu z ratą równą',
        PARAG21:
          'Czy mam jakikolwiek wpływ na koszt kredytu? Zwłaszcza, jeśli podpisałem już',
        PARAG22: 'cyrograf',
        PARAG23: 'umowę',
        PARAG24: 'Przecież i tak będzie drogo, co za różnica',
        PARAG25:
          'To właśnie Ty(!) masz największy wpływ na koszt kredytu! To Ty decydujesz ile swoich pieniędzy chcesz oddać bankowi!',
        PARAG26:
          'Zapewne nie każdy się ze mną zgodzi, bo przecież kredyty różnią się marżą, ubezpieczeniami, wibor się zmienia, itd. itd. To z pewnością fakt. ALE. Jeśli np. dokonując nadpłat możesz 2-3 krotnie zmniejszyć koszt swojego kredytu, to nijak będzie się to miało do porównania dobrej i gorszej ofety kredytowej z tego samego okresu ;)',
        PARAG27:
          'Na pewno warto zestawić i porównać ze sobą oferty banków, ale pamiętajmy, że najwięcej możemy zaoszczędzić sami, jeśli "tylko" będziemy podejmować dobre decyzje. Najlepiej przed uruchomieniem kredytu, ale także w jego trakcie.',
        PARAG28: 'Czy doradca kredytowy mi doradzi',
        PARAG29:
          'To taka miła Pani! Wszyscy znajomi i ciocia Grażynka ją chwalą',
        PARAG30: 'Najważniejsza zasada: doradca kredytowy to sprzedawca',
        PARAG31:
          'Czy widziałeś kiedyś, żeby sprzedawca (zwłaszcza taki, u którego zostawisz setki tysięcy złotych) był dla Ciebie niemiły? :D Doradca kredytowy, to nic innego jak sprzedawca telewizorów w sklepie z AGD. Sprzedaje telewizory różnych producentów (banków), podpowie Ci, który telewizor ma wyraźniejsze kolory lub lepszą jakość dźwięku (oprocentowanie, dodatkowe opłaty). Powinien (różnie bywa) doradzić Ci, czy lepszy dla Ciebie będzie LG, Samsung, czy Sony, czy dla Twojego pokoju lepsze będzie 55 czy 60 cali. Ale jak każdy sprzedawca - pomoże dokonać Ci wyboru w tym co ma na stanie.',
        PARAG32: 'Na pewno nie(!) odradzi Ci kupna (wzięcia kredytu)',
        PARAG33:
          'Jego zadaniem jest, żebyś kupił od niego produkt w miłej i sympatycznej atmosferze, tak, żebyś mógł się nabytkiem pochwalić przed sąsiadem. Ale - to wcale nie musi być jego zła wola - może nie poinformować Cię, że model danej marki jest przestarzały (że warto nadpłacać), ma regularnie psującego się pilota (Wibor może się wzrosnąć), a także, że po roku będziesz szukał gwarancji po całym domu, bo producent przyoszczędził na głośnikach. (dałeś sobie wcisnąć zbędne ubezpieczenia)',
        PARAG34:
          'Wedle reguły: im droższy zakup, tym bardziej sam sprawdź na co warto zwrócić uwagę',
        PARAG35:
          'Zwłaszcza, że z kredytem może okazać się nawet łatwiej niż z kupnem telewizora ;)',
        PARAG36: 'Czy płacę za doradcę kredytowego',
        PARAG37: 'Ach, ta Pani jest taka miła',
        PARAG38:
          'A czy płaciłeś kiedyś za obsługę Pana, który sprzedawał Ci telewizor',
        PARAG39:
          'PS. Nie płacisz za to bezpośrednio, ale sprzedawcy telewizorów nie pracują za darmo ;)',
        PARAG40: 'Słyszałem/am, że najpierw i tak spłaca się odsetki',
        PARAG41: 'Czyli co z tego, że nadpłacę, będę kobminować',
        PARAG42:
          'NIE, NIE I JESZCZE RAZ NIE! Każda rata składa się z części kapitałowej ORAZ odsetkowej (+ koszty ubezpieczeń)',
        PARAG43:
          'Jeśli dzisiaj uruchomisz kredyt, a jutro go spłacisz (tego Ci życzę!), to bankowi zapłacisz kilka złotych (odsetki naliczone przez jeden dzień) - oczywiście, jeśli Twój kredyt nie miał prowizji, ale to też znikomy koszt w porównaniu do "typowych" kosztów kredytu',
        PARAG44:
          'Im szybciej spłacisz kredyt, tym z reguły będzie on tańszy - bank mniej na Tobie zarobi',
        PARAG45: 'Jak stworzyć przedział zmiany Wiboru za pomocą kalkulatora',
        PARAG46:
          'Czyli co, jeśli Wibor zmieni się tylko na kilka miesięcy i wróci do poprzedniej wartości',
        PARAG47:
          'Np. Podczas uruchomienia kredytu Wibor wyniósł 6,90%. Jednak po kilku miesiącach wzrósł do 8,0%, żeby po jakimś czasie wrócić do poprzedniej wartości 6,90%.',
        PARAG48:
          'W kalkulatorze ustaw dwie zmiany Wiboru - najpierw na 8,0%, następnie na 6,90%',
        PARAG49: 'Jak porównać dwa kredyty za pomocą tej strony',
        PARAG50: 'Bez filozofii: otwórz sobie dwa okna przeglądarki :D',
      },
      results: {
        YOUR_RESULTS: 'Twoje wyniki',
        SUMMARY: 'Podsumowanie',
      },
      errors: {
        SOMETHING_WRONG_HAPPENED: 'Coś się zepsuło :(',
        STATUTE_REQUIRED: 'Wymagana akceptacja regulaminu',
        NUMBERS_REQUIRED: 'Wymagane wartości numeryczne',
        POSITIVE_NUMBERS_REQUIRED: 'Wymagane dodatnie wartości numeryczne',
        WRONG_RATE_TYPE_VALUE: 'Pole rodzaju raty ma niepoprawną wartość',
        TRANCHE_WRONG_SUM:
          'Suma transz musi być mniejsza lub równa niż kwota kredytu',
        TRANCHE_FIRST_DATE:
          'Data pierwszej transzy musi być ustawiony przed datą rozpoczęcia spłaty kredytu',
        WIBOR_CHANGE_MIN_DATE:
          'Minimalna data zmiany wiboru musi być większa lub równa dacie rozpoczęcia kredytu lub dacie najwcześniejszej transzy',
        WIBOR_CHANGE_MAX_DATE:
          'Maksymalna data zmiany wiboru musi mniejsza lub równa dacie ostatniej raty',
        CONSTANT_FEE_MIN_START_DATE:
          'Minimalna data początkowa opłaty stałej musi być większa lub równa dacie rozpoczęcia kredytu lub dacie najwcześniejszej transzy',
        CONSTANT_FEE_MAX_START_DATE:
          'Maksymalna data początkowa opłaty stałej musi mniejsza lub równa dacie ostatniej raty',
        CHANGING_FEE_MIN_START_DATE:
          'Minimalna data początkowa opłaty zmiennej musi być większa lub równa dacie rozpoczęcia kredytu lub dacie najwcześniejszej transzy',
        CHANGING_FEE_MAX_START_DATE:
          'Maksymalna data początkowa opłaty zmiennej musi mniejsza lub równa dacie ostatniej raty',
        SURCHARGE_MIN_START_DATE:
          'Minimalna data początkowa nadpłaty musi być większa lub równa dacie rozpoczęcia kredytu',
        SURCHARGE_MAX_START_DATE:
          'Maksymalna data początkowa nadpłaty musi mniejsza lub równa dacie ostatniej raty',
        CONSTANT_FEE_MIN_END_DATE:
          'Minimalna data końcowa opłaty stałej musi być większa lub równa dacie rozpoczęcia kredytu lub dacie najwcześniejszej transzy',
        CONSTANT_FEE_MAX_END_DATE:
          'Maksymalna data końcowa opłaty stałej musi mniejsza lub równa dacie ostatniej raty',
        CHANGING_FEE_MIN_END_DATE:
          'Minimalna data końcowa opłaty zmiennej musi być większa lub równa dacie rozpoczęcia kredytu lub dacie najwcześniejszej transzy',
        CHANGING_FEE_MAX_END_DATE:
          'Maksymalna data końcowa opłaty zmiennej musi mniejsza lub równa dacie ostatniej raty',
        SURCHARGE_MIN_END_DATE:
          'Minimalna data nadpłaty musi być większa lub równa dacie rozpoczęcia kredytu',
        SURCHARGE_MAX_END_DATE:
          'Maksymalna data nadpłaty musi być mniejsza lub równa dacie ostatniej raty',
        START_DATE_NOT_OLDER:
          'Data końcowa nie moze być mniejsza niż data początkowa',
      },
    },
  },
  en: {
    translation: {
      general: {
        BASIC_DATA: 'Basic data',
        ADDITIONAL_DATA: 'Addtional data',
        SITE_TITLE:
          "Tosia's Credit Calculator - surcharges, Wibor changes, tranches, RRSO, constant and changing fees",
        LOCAL_STORAGE_USAGE_INFO:
          "The site is using a local storage to save your time - you don't need to retype the data, when you revisit it ;)",
        MARGIN: 'Margin',
        BANK_MARGIN: 'Bank margin',
        WIBOR: 'Wibor',
        WIBOR_START: 'Wibor at the beginning',
        SERVICE_CHARGE: 'Service charge',
        SURCHARGE: 'surcharge',
        SURCHARGES: 'Surcharges',
        ADD_SURCHARGE: 'Add surcharge',
        FOR_SHORTEN: 'for shorten',
        NEW_VALUE: 'new value',
        ADD_CHANGE: 'Add change',
        ADD_FEE: 'Add fee',
        CONSTANT_FEES: 'Constant fees',
        CHANGING_FEES: 'Changing fees',
        TRANCHES: 'Tranches',
        ADD_TRANCHE: 'Add tranche',
        TRANCHE_VALUE: 'Tranche value',
        PERCENTAGE: 'Percentage',
        PERCENT: 'Percent',
        AMOUNT: 'Amount',
        CREDIT_COST: 'Credit cost',
        CREDIT_AMOUNT: 'Credit amount',
        CREDIT_PERIOD: 'Credit period',
        INTEREST: 'Interest',
        INTEREST_TOTAL: 'Interest total',
        CAPITAL_PART: 'Capital part of installment',
        REMAIN_TO_PAY: 'Left to pay',
        STATUTE: 'Statute',
        ACCEPT_STATUTE: 'I accept the statute',
        CONSTANT_FEES_TOTAL: 'Constant fees total',
        CHANGING_FEES_TOTAL: 'Changing fees total',
        DTS: 'DTS',
        CREDIT_TIME: 'Credit duration',
        YEARS: 'years',
        RATE_TYPE: 'Rate type',
        CONSTANT_INSTALLMENTS: 'Constant installments',
        DECREASING_INSTALLMENTS: 'Decreasing installments',
        ZL_PER_MONTH: 'PLN/month',
        ZL: 'PLN',
        CURRENCY: 'PLN',
        MONTH: 'mos.',
        RATE_WITHOUT_FEES: 'Installment (without fees)',
        RATE_WITH_FEES: 'Installment (with fees)',
        FIRST_RATE: 'first',
        AFTER_CHANGE: 'after change',
        CREDIT_COST_FULL_WITHOUT_FEES: 'Total credit cost (without fees)',
        CREDIT_COST_FULL_WITH_FEES: 'Total credit cost (with fees)',
        CREDIT_COST_TOTAL: 'Total credit cost (without fees)',
        SORRY_FOR_ADVERT: 'Sorry for an advertisement',
        TOTAL: 'Total',
        DATE: 'Date',
        SUM: 'Sum',
        RATE_NUMBER: 'No.',
        EXPORT_TO_CSV: 'Export to CSV',
        PRINT: 'Print',
        FROM: 'From',
        TO: 'To',
      },
      nav: {
        ABOUT_ME: 'ABOUT',
        ABOUT_CREDIT: 'ABOUT CREDIT',
        TIPS: 'TIPS',
        STATUTE: 'STATUTE',
        FORUM: 'FORUM',
      },
      form: {
        PERCENT_OF_REMAINING_CAPITAL: '% remaining capital',
        CONSTANT_FEE_TOOLTIP: 'Constant fees monthly (i.e. 20 PLN/month)',
        CHANGING_FEE_TOOLTIP:
          'Changing fees (dependent on remaining capital, i.e. 0.003 %)',
        MONTH_OF_FIRST_CAPITAL_PART_PAYMENT:
          'Month of first capital part payment',
        WIBOR_CHANGE: 'Wibor change',
        FOR_SHORTEN_TOOLTIP:
          'Check, if you want to calculate your surcharges for credit shorten. Uncheck, if you want to calculate you surcharges for decreasing your installments',
      },
      dts: {
        CHECK_YOUR_DTS: 'Check your DTS',
        DTS_ABBR: 'DTS - Debt to Savings',
        PARAG1:
          'It\'s an attitute of the value of your total debts to your savings in month period. This ratio is similar to DTI (Debt to Income) ratio, but DTS is more "real". You can use it in order to check, whether the monthly installment of the credit will be safe for your home bugdet',
        PARAG2: 'Why DTS is better than DTI and why is it worth to check it?',
        PARAG3:
          'Assume, you consider to take a credit for 30 years, which installment will be 900zł/month, your salary is 10.000zł net',
        PARAG4:
          ", but monthly you save only 100zł. DTI will show you optimistic scenario, but DTS will warn you due to a high rish. If so far you haven't been saving money, why would you change it now? Even, if the value of your monthly savings would be equal to estimated installment - what will happen, when you face any 'unexpected payment'? Will you take a next credit",
        PARAG5:
          "If now your current pace of saving is not enough or you can't save money at all, then the credit amost certainly will be a big trouble for you...for next 30 years",
        DTS_VALUE: 'DTS value',
        MONTHLY_SAVINGS_VALUE: 'Value of your monthly savings',
        RANGE1: "30-50% - it's OK, your budget will be rather safe",
        RANGE2: '50-75% - it might be, but it could be better ;)',
        RANGE3:
          '75% - taking the credit will be a huge risk for you and your budget',
      },
      home: {
        SITE_TITLE: "TOSIA'S CREDIT CALCULATOR",
        TURN_OFF_ADBLOCK: 'Turn off your AdBlock - please',
        RESTORE_DEFAULT: 'Restore defaults',
        CALC_SUBMIT: 'Calculate',
      },
      about: {
        PAGE_TITLE: 'Credit calculator - about me',
        ABOUT_ME: 'About me',
        HELLO: 'Hello!',
        PARAG1:
          'I created this calculator for people like me, who had to take a credit (or they have to), but they also want to pay for it as least as possible and pay it back as quick as possible',
        PARAG2:
          "I wish you no credit! May you repay it in the soonest term at the lowest possible cost. May you could spend your money for your own aims, not for institutions, which you don't want sponsor to",
        PARAG3:
          'If you like my calculator and you\'d like to support me with my next initiatives, it will be glad, if you "buy me a coffee" and transfer its value to my account:',
        PARAG4: "Additionally, I'd like to thank:",
        PARAG5:
          'My husband, Kuba - for many great ideas, general and substantive support in understanding various formulas and calculations (in many random places and situations xD)',
        PARAG6: 'Daniel A. - for the idea of visualisation of DTS factor :D',
        PARAG7:
          'Grzesiu W. i Grzesiu GW. - for motivating me to covering all new features by tests (I felt so strong laziness, but I know, I know...DTS over all! Thank you!)',
        PARAG8: 'Last changes (05.12.2023):',
        PARAG9:
          'Added option to mix wibor changes with surcharges (previously it was allowed to add only wibor changes OR surcharges)',
        PARAG10: 'Added "Left to pay" in results table',
        PARAG11:
          'Added "Sum+surcharge" in results table (if surcharges were added)',
        PARAG12: 'Added an option of CSV export and printing the results',
        PARAG13: 'Added a calculation of RRSO',
        PARAG14: 'Added an option of using tranches',
        PARAG15: 'Added EN translation (UA is planned as well)',
        PARAG16: 'Added an option of sharing URL params',
        PARAG17: 'UI has been improved ;)',
        PARAG18: 'Updated packeages',
        PARAG19:
          "Updated examples and default values (but it's not so easy to keep it up to date)",
        PARAG20:
          'Added a "Tips" tab - but it is only my insights! I\'m not a financial advisor ;)',
        PARAG21: 'Obvioulsy all new features are covered by tests ;)',
      },
      examples: {
        PAGE_TITLE:
          'About credit, some examples of credit variants and parameters and what the credit consists of',
        SUB_TITLE1: 'About credit',
        SUB_TITLE2: 'So, what is worth to know',
        EXP_MARGIN:
          "It's a part of credit percentage. Margin is a percent at which the bank borrows the money from another bank (yep, bank doesn't have \"it's own \" money - it just borrows them from another bank...). The higher it is, then you need to pay back more. It is not changeable during credit duration",
        EXP_WIBOR:
          "It's a part of credit percentage as well as margin. However Wibor is time-varying. It changes each few months and may cause, that your installment will increase or decrease",
        EXP_SERVICE_CHARGE:
          ' One time payment, which bank charges for giving you the credit. It is paid mostly once on the credit beginnig (but it might be also included in credit amount)',
        EXP_CONSTANT_FEES:
          'For instance: a flat insurance, where you pay each month the same amount of money for the entire credit duration. That cost is NOT(!) included in installment value (but it is still a mothly payment you will be charged)',
        EXP_CHANGING_FEES:
          "For instance: a life insurance. It's value is dependent on a certain percent of the still left capital to be paid. Each month it's value will be a bit lower (if you pay a capital part of your credit). That cost is NOT(!) included in installment value (but it is still a mothly payment you will be charged)",
        EXP_CREDIT_COST:
          " It is a total amount of money, which you'll pay the bank and insurers. NOTE(!): if you plan your repayment well (i.e. overpaying the credit, etc.) you may significantly reduce that cost!",
        EXP_DTS:
          "Debt To Savings. It's a coefficient, which defines a safety of you budget, when you decide to take a loan. It is calculated by dividing the value of the monthly debt by the monthly savings",
        PARAG1: 'What does the credit consist of?',
        PARAG2: "It's a margin plus wibor",
        PARAG3: 'How to plan/calculate the credit using this calculator?',
        PARAG4: 'An example "from life"',
        PARAG5:
          'We want to buy a flat. It costs 600k PLN. Our "own contribution" is 150k PLN. Thus, our credit amount will equal 450k PLN.',
        PARAG6: 'Bank X gave us the offer with such conditions:',
        PARAG7: '(Wibor 3M for a day 25.05.2023)',
        PARAG8: 'But also a few required insurances...',
        PARAG9: 'Flat insurance:',
        PARAG10: 'current debt/month',
        PARAG11: 'Life insurance (min. 1 borrower)',
        PARAG12: 'Insurance with a random, stupid name:',
        PARAG13: 'one time',
        PARAG14:
          'And from the risk of a pimple on the nose! Insurance names are really different, but they are always the same :)',
        PARAG15: 'current debt/month - for a 1 year',
        PARAG16: 'How to calculate the cost of our credit',
        PARAG17: 'An example "from life"',
        PARAG18: 'surcharge after 2 years',
        PARAG19:
          'BUT after 2 years of paying off, we got an inheritance from "uncle from USA". We decide to make a surcharge of 50k PLN for a credit shorten',
        PARAG20: 'increase Wibor after 5 years',
        PARAG21:
          'BUT after 5 years of paying off cental bank has announced an increase in interest rates. Our Wibor rises to 12%',
        PARAG22: 'Comparison examples',
        PARAG23: 'Important',
        PARAG24:
          'Beheath examples illustrate a comparison of various parameters of the credit. I don\'t want to show "what is more profitable", because credits are very different in details. Only with a particular offer of bank we may check the real credit cost. But it is very worth to try out some probable variants earlier...',
        PARAG25:
          'I assumed an example set of fees: constant fees (20PLN/mo.) and changing fees (0,03% current debt value), because they are commonly skipped in most of example calculations, but in real they may reach even 10-20% of credit cost(!), what is worth knowing. In beneath examples service charge of bank equals 0%',
        PARAG26: 'Comparison for parameters',
        PARAG27:
          '100 tys. at the beginning, 100 tys. after a year, 200 after two years',
        EXAMPLE_TITLE_1: 'Constant installments and decreasing installments',
        EXAMPLE_TITLE_2:
          'When the credit is 20k lower (30 years, constant installments)',
        EXAMPLE_TITLE_3:
          'When the credit is 5 years shorter (400 000k, constant installments)',
        EXAMPLE_TITLE_4:
          'When after 1 year Wibor will change (1% increase (!))',
        EXAMPLE_TITLE_5:
          'Is it worth to make surcharges - constant installments, surcharge for an installment reduction (surcharge 30k after 3 years)',
        EXAMPLE_TITLE_6:
          'Is it worth to make surcharges - constant installments, surcharge for credit shorten (surcharge 30k after 3 years)',
        EXAMPLE_TITLE_7:
          'Is it worth to make surcharges - constant installments, surcharge for credit shorten (surcharges 30k after 3 years and after 6 years)',
        EXAMPLE_TITLE_8:
          'Credit with tranches or paid out once? Constant installments',
        EXAMPLE_TITLE_9:
          'Is it worth to make surcharges - decreasing installments, surcharge for an installment reduction (surcharge 30k after 3 years)',
        EXAMPLE_TITLE_10:
          'Is it worth to make surcharges - decreasing installments, surcharge for credit shorten (surcharge 30k after 3 years)',
        EXAMPLE_TITLE_11:
          'Is it worth to make surcharges - decreasing installments, surcharge for credit shorten (surcharges 30k after 3 years and after 6 years)',
        EXAMPLE_TITLE_12:
          'Credit with tranches or paid out once? Decreasing installments',
        IMG_TITLE_1: 'Credit chart for constant installments',
        IMG_TITLE_2: 'Credit chart for decreasing installments',
        IMG_TITLE_3: 'Credit chart for 400k',
        IMG_TITLE_4: 'Credit chart for 380k',
        IMG_TITLE_5: 'Credit chart for 30 years',
        IMG_TITLE_6: 'Credit chart for 25 years',
        IMG_TITLE_7: 'Credit chart for 6,9% Wibor',
        IMG_TITLE_8: 'Credit chart for 7,9% Wibor',
        IMG_TITLE_9: 'Credit chart for 1 surcharge - constant installments',
        IMG_TITLE_10:
          'Credit chart for 1 surcharge 30k after 3 years for installment reduction - constant installments',
        IMG_TITLE_11:
          'Credit chart for 1 surcharge 30k after 3 years for credit shorten - constant installments',
        IMG_TITLE_12:
          'Credit chart for 1 surcharge 30k after 6 years for credit shorten - constant installments',
        IMG_TITLE_13: 'Credit chart for three tranches - constant installments',
        IMG_TITLE_14: 'wCredit chart for decreasing installments',
        IMG_TITLE_15:
          'Credit chart for 1 surcharge 30k after 3 years for installment reduction - decreasing installments',
        IMG_TITLE_16:
          'Credit chart for 1 surcharge 30k after 3 years for credit shorten - decreasing installments',
        IMG_TITLE_21:
          'Credit chart for 1 surcharge 30k after 6 years for credit shorten - decreasing installments',
        AFTER_A_YEAR: 'after a year',
        AFTER_3_YEARS: 'after 3 years',
        AFTER_6_YEARS: 'after 6 years',
        AFTER_I_TRANCHE: 'after I tranche',
        AFTER_II_TRANCHE: 'after II tranche',
        AFTER_III_TRANCHE: 'after III tranche',
        AFTER_CHANGE: 'after change',
        SURCHARGE_AFTER_1: 'surcharge 30k. after 3 years',
        SURCHARGE_AFTER_2: 'surcharge 30k. after 6 years',
        WITHOUT_TRANCHES: 'no tranches',
      },
      forum: {
        PAGE_TITLE: 'Credit calculator - forum',
        FORUM: 'Forum',
        ADD_YOUR_COMMENT: 'Add comment',
        AUTHOR: 'Author',
        ALERT_TEXT: "Don't impersonate me ;) Thx",
        ROGUE: 'Rogue',
        LIKE_IT: 'Like it',
      },
      status: {
        PAGE_TITLE: 'Terms of use of the calculator',
        PARAG1:
          'Remember, that results of my calculator are illustrative informations. The schedule of your installments may differ from those presented in the results - although slightly, I hope.',
        PARAG2:
          'On the forum, please post cultural comments. Others I will delete.',
        PARAG3:
          "PS. If I'm wrong somewhere, please let me know and leave the comment on the forum. I will try to improve as soon as possible.",
      },
      tips: {
        PAGE_TITLE: 'Tips',
        SUB_TITLE: 'A few subjective(!) tips and more',
        PARAG1: 'Some general and quick hints',
        PARAG2:
          'Compare different credit variant by your own! "Play" with calculator, try various scenarios. It doesn\'t hurt :)',
        PARAG3: 'DO NEVER take maximum allowed credit amount!',
        PARAG4:
          "Banks very often define 'the installment' as a sum of capital and insterest part, but they skip (or rather \"count separately\") monthly fees related to insurances! Don't forget about them",
        PARAG5:
          'When you estimate how high installment you will be able to afford',
        PARAG6:
          "DON'T take into account your monthly salary. Except it, check how much money per month you save.",
        PARAG7:
          'So what if you earn 20k net (:D), if fees, leasings, other loans, life cost per month take 19k?',
        PARAG8:
          "Take the creadit for the possibly minimum amount with the shortest duration (I know, it's easy to say)",
        PARAG9: 'As soon as possible overpay your credit',
        PARAG10:
          "- more quickly and \"more aggressively\" is better. NORE: depends on the contract, bank may charge additional fees related with surcharge. More 'reliable' banks don't make that procedure difficult. Nevertheless, it's worth to check, which kind of surcharges is the most profitable for us (i.e. more often and smaller or not so often, but higher) - generally any overpaying is profitable ;)",
        PARAG11: 'Pro tip for overpayers',
        PARAG12:
          'Some of banks let you to increase value of the installments to first of your installment value - when you make a surcharge for credit shorten (in case of decreasing installments). It will cause, that our monthly installment will rise (to the first installment value), BUT it will also increase the value of capital part of each installment, what will let us to shorten the creadit even more',
        PARAG13: 'Watch out for tranches.',
        PARAG14:
          "Credits given in tranches generally require paying only the interest part of installments till the total amount of the credit is taken. It mean's, you will not pay the capital part of your installments until bank transfers you all tranches. In that case, your credit doesn't decrease(!), but you pay the bank each month. From bank perspective it is a it's a gold mine, but for sure not for you",
        PARAG15: 'Take into account that Wibor, and thus -',
        PARAG16: 'value of your installment - may change.',
        PARAG17:
          "Assume, that during the time you will be paying back your loan (i.e. 10-30 years) situation of the finance market will change for sure and you should be prepared for 'worse time' ;)",
        PARAG18: 'Generally',
        PARAG19:
          'credits with decreasing installments are more profitable (cheaper)',
        PARAG20:
          "(lower credit cost), than credits with constant installments. BUT you need to remember, that credits with decreasing installments are more difficult 'to be taken', because its first installments will be higher in comparison to credits with constant installments",
        PARAG21:
          "Do I have any impact on the credit cost? Especially, if I've already signed up",
        PARAG22: "'pledge/pact'",
        PARAG23: 'contract',
        PARAG24: "It will be expensive anyway, so what's the difference",
        PARAG25:
          'You (!) have the greatest influence on the cost of the credit! You decide how much of your money you want to give to the bank!',
        PARAG26:
          "For sure not all of you will agree with me, because credits parameters are different (other margins, insurances, wibor is changing all the time, etc. etc.). That's the fact, for sure. BUT i.e. if you overpay your credit, you may decrease its cost even 2-3 times, so that difference is incomparable with better and worse bank offer from the similar point of time ;)",
        PARAG27:
          'For sure it is worth to compare all bank offers, but remember, that the highest savings we may do ourselves, if we will "only" make good decisions. It would be better before credit launch, but when we already pay it back as well.',
        PARAG28: 'Will credit counselor help me',
        PARAG29:
          "She's so nice Lady! All my friends, even my aunt Elisabeth praise her",
        PARAG30: 'The most important rule: credit counselor is a salesman',
        PARAG31:
          'Have you ever seen a salesman (especially one, who you will entrust hundred of thousands PLN), who is rude? :D Credit counselor is works like a TV-salesman in home appliance store. He sells TVs of various producers (banks), he will tell you, which TV has clearer colours or better sound quality (percentage, fees). He should (but he might not) advice you, which one will be better for you: LG, Samsung or Sony and which size will be more suitable for your room. But as a salesman - he may help you to make a decission between articles, which are in the store',
        PARAG32:
          'For sure, he will not discourage you the purchase (taking the credit)',
        PARAG33:
          "His aim is to encourage you for purchase in let you to buy it in nice and pleasureful atmosphere in a way, that you want to boast with your neighbour. But - it doesn't need to be his bad will - he may not inform you, that this particular TV is outdated (and it's worth to overpay the credit), that its remote often breaks (Wibor may grow up) and after 1 year you will looking for a warranty all over the house, because the producer has saved money on speakers. (you agreed for redundant insurances)",
        PARAG34:
          'Main rule: Wedle reguły: the more expensive the purchase is, then more worth is to check what parameters you should compare',
        PARAG35:
          'Especially, if taking the credit might be easier, than buying a new TV ;)',
        PARAG36: 'Do I pay for credit counselor',
        PARAG37: 'Ah, that Lady is so kind',
        PARAG38: 'Have you ever paid for a service of TV-salesman',
        PARAG39:
          "PS. You don't pay Nie płacisz za to straightway, but TV-salesman don't work for free ;)",
        PARAG40: "I've heard, that interests are paid firstly",
        PARAG41: "So what if I overpay, I'll mess around",
        PARAG42:
          'NO, NO AND ONCE AGAIN NO! Each installment consists capital AND interest part (+ fees cost)',
        PARAG43:
          "If you start your credit today, but tomorrow you will pay it back (I wish it to you!), then you will need to pay the bank a few PLN (interests accrued for one day) - obviously, if your credit didn't contain a service charge, but it will be pretty small cost anyway in comparison to 'typical' credit costs",
        PARAG44:
          'The sooner you repay the loan, the cheaper it will usually be - the bank will earn less from you',
        PARAG45:
          'How to create a range of time for changed Wibor with this calculator',
        PARAG46:
          'So what if Wibor changes only for a few months and returns to its previous value',
        PARAG47:
          'I.e. When you launched your credit the Wibor was 6,90%. Though after a few months it increased to 8,0%, but after some time it turned back to its previous value 6,90%',
        PARAG48:
          'In the Wibor changes fields set two Wibor changes - first 8,0%, then 6,90%',
        PARAG49: 'How to compare two credits using that site',
        PARAG50: 'No rocket science: just open two tabs of your browser :D',
      },
      results: {
        YOUR_RESULTS: 'Your results',
        SUMMARY: 'Summary',
      },
      errors: {
        SOMETHING_WRONG_HAPPENED: 'Something went wrong :(',
        STATUTE_REQUIRED: 'Acceptance of the statute is required',
        NUMBERS_REQUIRED: 'Numeric values are required',
        POSITIVE_NUMBERS_REQUIRED: 'Positive numeric values required',
        WRONG_RATE_TYPE_VALUE: 'Rate type field has uncorrect value',
        TRANCHE_WRONG_SUM:
          'The sum of the tranches must be less than or equal the credit amount',
        TRANCHE_FIRST_DATE:
          'The first tranche date must be set before the credit repayment start date',
        WIBOR_CHANGE_MIN_DATE:
          'The minimum Wibor change date must be greater than or equal to the credit start date or earliest tranche date',
        WIBOR_CHANGE_MAX_DATE:
          'The maximum Wibor change date must be less than or equal to the last credit payment date',
        CONSTANT_FEE_MIN_START_DATE:
          'The minimum constant fee start date must be greater than or equal to the credit start date or earliest tranche date',
        CONSTANT_FEE_MAX_START_DATE:
          'The maximum constant fee start date must be less than or equal to the last credit payment date',
        CHANGING_FEE_MIN_START_DATE:
          'The minimum changing fee start date must be greater than or equal to the credit start date or earliest tranche date',
        CHANGING_FEE_MAX_START_DATE:
          'The maximum changing fee start date must be less than or equal to the last credit payment date',
        SURCHARGE_MIN_START_DATE:
          'The minimum surcharge start date must be greater than or equal to the credit start date',
        SURCHARGE_MAX_START_DATE:
          'The maximum surcharge start date must be less than or equal to the last credit payment date',
        CONSTANT_FEE_MIN_END_DATE:
          'The minimum constant fee end date must be greater than or equal to the credit start date or earliest tranche date',
        CONSTANT_FEE_MAX_END_DATE:
          'The maximum constant fee end date must be less than or equal to the last credit payment date',
        CHANGING_FEE_MIN_END_DATE:
          'The minimum changing fee end date must be greater than or equal to the credit start date or earliest tranche date',
        CHANGING_FEE_MAX_END_DATE:
          'The maximum changing fee end date must be less than or equal to the last credit payment date',
        SURCHARGE_MIN_END_DATE:
          'The minimum surcharge end date must be greater than or equal to the credit start date',
        SURCHARGE_MAX_END_DATE:
          'The maximum surcharge date must be less than or equal to the last credit payment date',
        START_DATE_NOT_OLDER: 'The end date cannot be less than the start date',
      },
    },
  },
  ua: {
    translation: {
      general: {
        BASIC_DATA: 'Dane podstawowe',
        ADDITIONAL_DATA: 'Dane rozszerzone',
        SITE_TITLE:
          'Kalkulator kredytu Tosi - nadpłaty, zmiany wiboru, transze, RRSO, opłaty stałe i zmienne',
        LOCAL_STORAGE_USAGE_INFO:
          'Strona używa local storage, żebyś nie musiał za każdym razem wpisywać tych samych danych ;)',
        MARGIN: 'Marża',
        BANK_MARGIN: 'Marża banku',
        WIBOR: 'Wibor',
        WIBOR_START: 'Wibor początkowy',
        SERVICE_CHARGE: 'Prowizja',
        SURCHARGE: 'nadpłata',
        SURCHARGES: 'Nadpłaty',
        ADD_SURCHARGE: 'Dodaj nadpłatę',
        FOR_SHORTEN: 'na skrócenie',
        NEW_VALUE: 'nowa wartość',
        ADD_CHANGE: 'Dodaj zmianę',
        ADD_FEE: 'Dodaj opłatę',
        CONSTANT_FEES: 'Opłaty stałe',
        CHANGING_FEES: 'Opłaty zmienne',
        TRANCHES: 'Transze',
        ADD_TRANCHE: 'Dodaj transzę',
        TRANCHE_VALUE: 'Wartość transzy',
        PERCENTAGE: 'Oprocentowanie',
        PERCENT: 'Procent',
        AMOUNT: 'Kwota',
        CREDIT_COST: 'Koszt kredytu',
        CREDIT_AMOUNT: 'Kwota kredytu',
        CREDIT_PERIOD: 'Okres kredytowania',
        INTEREST: 'Odsetki',
        INTEREST_TOTAL: 'Odsetki do spłaty',
        CAPITAL_PART: 'Część kapitałowa raty',
        REMAIN_TO_PAY: 'Pozostało do spłaty',
        STATUTE: 'Regulamin',
        ACCEPT_STATUTE: 'Akceptuję regulamin',
        CONSTANT_FEES_TOTAL: 'Opłaty stałe łącznie',
        CHANGING_FEES_TOTAL: 'Opłaty zmienne łącznie',
        DTS: 'DTS',
        CREDIT_TIME: 'Czas kredytu',
        YEARS: 'lat',
        RATE_TYPE: 'Rodzaj rat',
        CONSTANT_INSTALLMENTS: 'Raty równe',
        DECREASING_INSTALLMENTS: 'Raty malejące',
        ZL_PER_MONTH: 'zł/mies.',
        ZL: 'zł',
        CURRENCY: 'PLN',
        MONTH: 'mies.',
        RATE_WITHOUT_FEES: 'Rata (bez opłat)',
        RATE_WITH_FEES: 'Rata (z opłatami)',
        FIRST_RATE: 'pierwsza',
        AFTER_CHANGE: 'po zmianie',
        CREDIT_COST_FULL_WITHOUT_FEES:
          'Całkowity koszt kredytu (bez opłat dodatkowych)',
        CREDIT_COST_FULL_WITH_FEES: 'Całkowity koszt kredytu (z opłatami)',
        CREDIT_COST_TOTAL: 'Całkowity koszt kredytu',
        SORRY_FOR_ADVERT: 'Sorki za reklamę',
        TOTAL: 'Razem',
        DATE: 'Data',
        SUM: 'Suma',
        RATE_NUMBER: 'Lp.',
        EXPORT_TO_CSV: 'Export do CSV',
        PRINT: 'Drukuj',
        FROM: 'Od',
        TO: 'Do',
      },
      nav: {
        ABOUT_ME: 'O MNIE',
        ABOUT_CREDIT: 'O KREDYCIE',
        TIPS: 'PORADY',
        STATUTE: 'REGULAMIN',
        FORUM: 'FORUM',
      },
      form: {
        PERCENT_OF_REMAINING_CAPITAL: '% pozostałego kapitału',
        CONSTANT_FEE_TOOLTIP: 'Opłaty stałe co miesiąc (np. 20zł/mies.)',
        CHANGING_FEE_TOOLTIP:
          'Opłaty zmienne, zależne od pozostałej kwoty kredytu (wartość w %)',
        MONTH_OF_FIRST_CAPITAL_PART_PAYMENT:
          'Miesiąc pobrania pierwszej raty kapitałowej',
        WIBOR_CHANGE: 'Zmiana Wiboru',
        FOR_SHORTEN_TOOLTIP:
          'Zaznacz, jeśli nadpłaty mają być liczone na skrócenie raty. Odznaczone, jeśli nadpłaty mają służyć zmniejszeniu raty',
      },
      dts: {
        CHECK_YOUR_DTS: 'Sprawdź swój DTS',
        DTS_ABBR: 'DTS - Debt to Savings',
        PARAG1:
          'To stosunek Twojego zadłużenia do oszczędności w ciągu miesiąca. To współczynnik zbliżony do współczynnika DTI (Debt to Income) jednocześnie będąc bardziej od niego "prawdziwym". Za jego pomocą możesz określić, czy rata kredytu będzie bezpieczna dla Twojego budżetu.',
        PARAG2: 'Dlaczego DTS jest lepszy od DTI i po co go sprawdzać?',
        PARAG3:
          'Załóżmy, że zastanawiasz się nad kredytem z ratą 900zł na 30 lat, zarabiasz 10.000zł netto',
        PARAG4:
          ', ale miesięcznie odkładasz 100zł. DTI będzie optymistyczny, natomiast DTS wskaże wysokie ryzyko. Skoro dotychczas nie odkładałeś, dlaczego miałbyś teraz to zmienić? Podobnie, gdy wartość Twoich miesięcznych oszczędności będzie równa wyliczanej racie - co w sytuacji, gdy pojawi się "nagły wydatek"? Weźmiesz kolejny kredyt',
        PARAG5:
          'Jeśli teraz Twoje oszczędności są niewielkie lub nie umiesz oszczędzać wcale, kredyt prawie na pewno będzie dla Ciebie niemałym kłopotem...przez 30 lat.',
        DTS_VALUE: 'Wartość DTS',
        MONTHLY_SAVINGS_VALUE: 'Wartość Twoich miesięcznych oszczędności',
        RANGE1: '30-50% - OK, Twój budżet będzie raczej bezpieczny',
        RANGE2: '50-75% - może być, ale mogłoby być lepiej ;)',
        RANGE3: '75% - wzięcie kredytu to duże ryzyko dla Twojego budżetu',
      },
      home: {
        SITE_TITLE: 'KALKULATOR KREDYTU TOSI',
        TURN_OFF_ADBLOCK: 'Wyłącz AdBlocka - proszę',
        RESTORE_DEFAULT: 'Przywróć domyślne',
        CALC_SUBMIT: 'Przelicz',
      },
      about: {
        PAGE_TITLE: 'Kalkulator kredytu - o mnie',
        ABOUT_ME: 'O mnie',
        HELLO: 'Cześć!',
        PARAG1:
          'Napisałam ten kalkulator dla ludzi takich jak ja, którzy kredyt wziąć musieli (lub muszą), ale chcą zapłacić za niego jak najmniej i spłacić go jak najszybciej.',
        PARAG2:
          'Życzę Ci braku kredytu! Żebyś spłacił go jak najszybciej, jak najmniejszym kosztem. A swoje pieniądze poświęcił na własne cele, nie na utrzymywanie instytucji, których sponsorować nie chcesz',
        PARAG3:
          'Jeśli mój kalkulator Ci się spodobał chciałbyś mnie wesprzeć w kolejnych podobnych inicjatywach będzie mi miło, jeśli "kupisz mi kawę" i przelejesz jej wartość na mój nr konta:',
        PARAG4: 'Chciałabym także bardzo podziękować:',
        PARAG5:
          'Mojemu mężowi, Kubie - za wiele bardzo dobrych pomysłów, wsparcie ogólne i bardzo merytoryczne w rozkminianiu wszelakich wzorów i obliczeń (w naprawdę bardzo różnych miejsach i sytuacjach xD)',
        PARAG6: 'Danielowi A. - za ideę wizualizacji współczynnika DTS :D',
        PARAG7:
          'Grzesiowi W. i Grzesiowi GW. - za zmotywowanie mnie do napisania testów dla wszystkich nowych funkcjolaności (tak strasznie mi się nie chciało, ale wiem, wiem...TDD over all! Dzięki!)',
        PARAG8: 'Ostatnie zmiany (05.12.2023):',
        PARAG9:
          'Wprowadzono możliwość dodania zmiany wiboru oraz nadpłaty (wcześniej możliwe było jedno lub(!) drugie)',
        PARAG10: 'Dodano "Pozostało do spłaty" w tabeli wyników',
        PARAG11:
          'Dodano "Suma+nadpłata" w tabeli wyników (jeśli dodano nadpłaty)',
        PARAG12: 'Dodano możliwość eksportu danych do CSV oraz ich wydruku',
        PARAG13: 'Dodano podliczenie RRSO',
        PARAG14: 'Dodano możliwość rozbicia kredytu na transze',
        PARAG15: 'Dodano tłumaczenie EN (planowane jest także UA jeszcze)',
        PARAG16: 'Dodano możliwość wysyłania zadanych parametrów za pomocą URL',
        PARAG17: 'Poprawiono nieco UI ;)',
        PARAG18: 'Podbito packeages',
        PARAG19:
          'Odświeżono przykłady i domyślne wartości (choć trudno nadążyć...)',
        PARAG20:
          'Dodano zakładkę "Porady" - to tylko moje przemyślenia! Doradcą finansowym nie jestem! ;)',
        PARAG21: 'Oczywiście wszystkie funkcjonalności są pokryte testami ;)',
      },
      examples: {
        PAGE_TITLE:
          'O kredycie, przykłady dla różnych parametrów kredytu i z czego składa się kredyt',
        SUB_TITLE1: 'O kredycie',
        SUB_TITLE2: 'Czyli co warto wiedzieć',
        EXP_MARGIN:
          'Część oprocentowania kredytu. Marża to procent po jakim bank(!) pożycza pieniądze od innego banku (tak, bank nie ma "własnych" pieniędzy - po prostu pożycza je od innego banku...). Im jest wyższa, tym więcej musisz zapłacić. Nie zmienia się w trakcie trwania kredytu',
        EXP_WIBOR:
          'Także część oprocentowania kredytu. Wibor jest jednak zmienny w czasie! Zmienia się co kilka miesięcy i może spowodować że Twoja rata wzrośnie lub zmaleje',
        EXP_SERVICE_CHARGE:
          ' Jednorazowa opłata jaką bank pobiera za udzielenie kredytu. Jest to koszt płatny zwykle na początku spłaty kredytu (ale może być też wliczony do kwoty wziętego kredytu)',
        EXP_CONSTANT_FEES:
          'Przykładowo: ubezpieczenie nieruchomości, wynosi tyle samo przez cały czas trwania kredytu. Ta opłata NIE JEST(!) doliczana do wysokości raty (a to nadal miesięczny koszt, który musisz zapłacić)',
        EXP_CHANGING_FEES:
          'Przykładowo: ubezpieczenie na życie. Jego wartość to określony procent kapitału pozostałego do spłaty. Co miesiąc kwota tej opłaty będzie coraz niższa (jeśli spłacasz część kapitałową swojego kredytu). Ta opłata NIE JEST(!) doliczana do wysokości raty (a to nadal miesięczny koszt, który musisz zapłacić)',
        EXP_CREDIT_COST:
          ' To całkowita kwota jaką zapłacisz bankowi i ubezpieczycielom. UWAGA(!): dobrze planując spłatę swojego kredytu (np. nadpłacając kredyt, itp.) możesz naprawdę znacząco obniżyć tę kwotę!',
        EXP_DTS:
          'Debt To Savings. To współczynnik określający bezpieczeństwo Twojego budżetu, gdy zdecydujesz się na wzięcie kredytu. Oblicza się go dzieląc wartość miesięcznego zadłużenia do miesięcznych oszczędności',
        PARAG1: 'Z czego składa się kredyt',
        PARAG2: 'To marża plus wibor',
        PARAG3: 'Jak zaplanować/policzyć kredyt używając tego kalkulatora?',
        PARAG4: 'Przykład "z życia"',
        PARAG5:
          'Chcemy kupić mieszkanie. Koszt mieszkania 600tys. złotych. Wkładu własnego mamy 150tys. Zatem kredytować będziemy 450zł',
        PARAG6: 'Bank X przedstawił nam ofertę o warunkach',
        PARAG7: '(Wibor 3M na dzień 25.05.2023)',
        PARAG8: 'Ale także kilka wymaganych ubezpieczeń...',
        PARAG9: 'Na mieszkanie:',
        PARAG10: 'bieżącego zadłużenia/mies.',
        PARAG11: 'Na życie (min. 1 kredytobiorcy)',
        PARAG12: 'Ubezpieczenie o jakiejś głupiej nazwie:',
        PARAG13: 'jednorazowo',
        PARAG14:
          'Oraz od ryzyka wyskoczenia pryszcza na nosie! Ubezpieczenia nazywają się naprawdę różnie, ale zawsze są tym samym :)',
        PARAG15: 'bieżącego zadłużenia/mies. - na 1 rok',
        PARAG16: 'Oto jak policzyć koszt naszego kredytu',
        PARAG17: 'Przykład z życia',
        PARAG18: 'nadpłata po 2 latach',
        PARAG19:
          'ALE po 2 latach spłacania dostaliśmy spadek po "wujku z Ameryki". Postanawiamy zrobić nadpłatę w wysokości 50 tys. na skrócenie kredytu',
        PARAG20: 'wzrost Wiboru po 5 latach',
        PARAG21:
          'ALE po 5 latach spłacania bank centralny ogłosił wzrost stóp procentowych. Nasz Wibor skacze do 12%.',
        PARAG22: 'Przykłady porównawcze',
        PARAG23: 'Ważne',
        PARAG24:
          ' Poniższe przykłady mają na celu porównanie różnych parametrów kredytu. Nie chcę pokazać "co się bardziej opłaca", ponieważ kredyty tak naprawdę bardzo różnią się od siebie w szczegółach. Dopiero mając gotową ofertę z banku można sprawdzić rzeczywisty koszt kredytu. Ale warto też sobie trochę pogdybać...',
        PARAG25:
          'Założyłam przykładowe opłaty stałe (20zł/mies.) i zmienne (0,03% wartości bieżącego zadłużenia), ponieważ są one często pomijane w przykładowych kalkulacjach, a w rzeczywistości osiągają one nawet 10-20% kosztów kredytu(!), o czym warto pamiętać. W przykładach poniżej prowizja wynosi 0%',
        PARAG26: 'Porównanie dla parametrów',
        PARAG27: '100 tys. na start, 100 tys. po roku, 200 po dwóch latach',
        EXAMPLE_TITLE_1: 'Raty równe i raty malejące',
        EXAMPLE_TITLE_2:
          'Gdy kredyt jest o 20tys. mniejszy (30lat, raty równe)',
        EXAMPLE_TITLE_3:
          'Gdy kredyt jest o 5 lat krótszy (400 000tys, raty równe)',
        EXAMPLE_TITLE_4: 'Gdy po roku zmieni się Wibor (większy o 1% (!))',
        EXAMPLE_TITLE_5:
          'Czy warto nadpłacać - raty równe, zmniejszenie raty (nadpłata 30 tys. po 3 latach)',
        EXAMPLE_TITLE_6:
          'Czy warto nadpłacać - raty równe, skrócenie okresu kredytowania (nadpłata 30 tys. po 3 latach)',
        EXAMPLE_TITLE_7:
          'Czy warto nadpłacać szybciej - raty równe, skrócenie okresu kredytowania (nadpłata 30 tys. po 3 latach i po 6 latach)',
        EXAMPLE_TITLE_8: 'Transze czy jednorazowo? Raty równe',
        EXAMPLE_TITLE_9:
          'Czy warto nadpłacać szybciej - raty malejące, zmniejszenie raty (nadpłata 30 tys. po 3 latach)',
        EXAMPLE_TITLE_10:
          'Czy warto nadpłacać - raty malejące, skrócenie okresu kredytowania (nadpłata 30 tys. po 3 latach)',
        EXAMPLE_TITLE_11:
          'Czy warto nadpłacać szybciej - raty malejące, skrócenie okresu kredytowania (nadpłata 30 tys. po 3 latach i po 6 latach)',
        EXAMPLE_TITLE_12: 'Transze czy jednorazowo? Raty malejące',
        IMG_TITLE_1: 'wykres dla rat równych',
        IMG_TITLE_2: 'wykres dla rat malejących',
        IMG_TITLE_3: 'wykres dla 400 tysiecy',
        IMG_TITLE_4: 'wykres dla 380 tysiecy',
        IMG_TITLE_5: 'wykres dla 30lat',
        IMG_TITLE_6: 'wykres dla 25lat',
        IMG_TITLE_7: 'wykres dla wiboru 6,9%',
        IMG_TITLE_8: 'wykres dla wiboru 7,9%',
        IMG_TITLE_9: 'wykres bez nadpłaty dla rat równych',
        IMG_TITLE_10:
          'wykres z nadpłatą 30 tysiecy po 3 latach na zmniejszenie raty dla rat rownych',
        IMG_TITLE_11:
          'wykres z nadpłatą 30 tysiecy po 3 latach na skrocenie okresu kredytowania dla rat rownych',
        IMG_TITLE_12:
          'wykres z nadpłatą 30 tysiecy po 6 latach na skrocenie okresu kredytowania',
        IMG_TITLE_13: 'wykres z trzema transzami dla rat równych',
        IMG_TITLE_14: 'wykres bez nadpłaty dla rat malejących',
        IMG_TITLE_15:
          'wykres z nadpłatą 30 tysiecy po 3 latach na zmniejszenie raty dla rat malejacych',
        IMG_TITLE_16:
          'wykres z nadpłatą 30 tysiecy po 3 latach na skrócenie okresu kredytowania dla rat malejacych',
        IMG_TITLE_21:
          'wykres z nadpłatą 30 tysiecy po 6 latach na skrócenie okresu kredytowania dla rat malejacych',
        AFTER_A_YEAR: 'po roku',
        AFTER_3_YEARS: 'po 3 latach',
        AFTER_6_YEARS: 'po 6 latach',
        AFTER_I_TRANCHE: 'po I transzy',
        AFTER_II_TRANCHE: 'po II transzy',
        AFTER_III_TRANCHE: 'po III transzy',
        AFTER_CHANGE: 'po zmianie',
        SURCHARGE_AFTER_1: 'nadpłata 30tys. po 3 latach',
        SURCHARGE_AFTER_2: 'nadpłata 30tys. po 6 latach',
        WITHOUT_TRANCHES: 'bez transz',
      },
      forum: {
        PAGE_TITLE: 'Kalkulator kredytu - forum',
        FORUM: 'Forum',
        ADD_YOUR_COMMENT: 'Dodaj swój komentarz',
        AUTHOR: 'Autor',
        ALERT_TEXT: 'Nie podszywaj się pode mnie ;) Dzięki',
        ROGUE: 'Oszust',
        LIKE_IT: 'Lubię to',
      },
      status: {
        PAGE_TITLE: 'Regulamin korzystania z kalkulatora',
        PARAG1:
          'Pamiętaj, że wyniki mojego kalkulatora to informacje poglądowe. Harmonogram Twoich rat może różnić się od tych zaprezentowanych w wynikach - choć mam nadzieję, że nieznacznie.',
        PARAG2: 'Na forum proszę o wpisy kulturalne. Inne będę usuwać.',
        PARAG3:
          'PS. Jeśli się gdzieś pomyliłam, to napisz mi, proszę, na forum. Postaram poprawić się jak najszybciej.',
      },
      tips: {
        PAGE_TITLE: 'Porady',
        SUB_TITLE: 'Kilka subiektywnych(!) porad i nie tylko',
        PARAG1: 'Kilka ogólnych i szybkich porad',
        PARAG2:
          'Porównaj różne warianty kredytu samemu! "Pobaw się" kalkulatorem, pogdybaj, wypróbuj różne scenariusze. To nie boli :)',
        PARAG3: 'NIGDY nie bierz kredytu "pod korek"!',
        PARAG4:
          'Banki bardzo często definiują "ratę" jako sumę części kapitałowej oraz odsetkowej, ALE pomijają (a raczej "liczą osobno") comiesięczne opłaty związane z ubezpieczeniami! Nie zapominaj o nich',
        PARAG5:
          'Szacując na jak wysoką ratę miesięcznie będziesz mógł sobie pozwolić',
        PARAG6:
          'NIE(!) bierz pod uwagę miesięcznych dochodów. Zamiast nich sprawdź ile co miesiąc oszczędzasz.',
        PARAG7:
          'Co z tego, że zarabiasz 20tys. na rękę (:D), jeśli opłaty, leasingi, inne kredyty, koszty utrzymania co miesiąc wynoszą Cię 19tys.?',
        PARAG8:
          'Weź kredyt na najmniejszą możliwą kwotę, z jak najkrótszym czasem kredytowania (wiem, łatwo powiedzieć)',
        PARAG9: 'Jak najszybciej staraj się nadpłacać swój kredyt',
        PARAG10:
          '- im szybciej i "agresywniej", tym lepiej. UWAGA: zależnie od umowy, bank może naliczać dodatkowe opłaty związane z nadpłatą. "Porządniejsze" banki nie utrudniają tej procedury. Niemniej jednak warto sprawdzić jaki rodzaj nadpłat jest dla nas najbardziej opłacalny (np. częstsze i małe lub rzadsze i duże) - nadpłacanie z reguły się opłaca ;)',
        PARAG11: 'Pro tip dla nadpłacających',
        PARAG12:
          'Niektóre z banków po dokonaniu nadpłaty na skrócenie kredytu z ratą malejącą pozwalają podnieść kwotę raty do jej pierwotnej wysokości. Spowoduje to, że nasza rata urośnie do wartości z początku kredytu, ALE jednocześnie podniesie to wartość spłacanej części kapiałowej - a tym samym może pozwolić nam jeszcze bardziej skrócić nasz kredyt',
        PARAG13: 'Uważaj na transze.',
        PARAG14:
          'Kredyty rozbite na transze z reguły wymagają płacenia samej części odsetkowej do momentu uruchomienia całości kredytu. Oznacza to, że dopóki bank nie wypłaci Ci wszystkich transz, nie będziesz płacił części kapitałowej. W takim przypadku, Twój kredyt nie maleje(!), za to bankowi płacisz co miesiąc. Dla banku to żyła złota, dla Ciebie niekoniecznie ;)',
        PARAG15: 'Bierz pod uwagę, że Wibor, a co za tym idzie -',
        PARAG16: 'wysokość Twojej raty - może się zmienić.',
        PARAG17:
          'Załóż, że w trakcie trwania Twojego kredytu (np. 10-30lat) sytuacja na rynkach finansowych na pewno się zmieni i lepiej być przygotowanym na gorsze ;)',
        PARAG18: 'Z reguły',
        PARAG19: 'kredyty z ratą malejącą są bardziej opłacalne (tańsze)',
        PARAG20:
          '(niższy koszt kredytu), niż te z ratą równą. ALE musisz pamiętać, że kredyt z ratą malejącą "trudniej dostać", ponieważ jego początkowe raty będą wyższe w porównaniu do kredytu z ratą równą',
        PARAG21:
          'Czy mam jakikolwiek wpływ na koszt kredytu? Zwłaszcza, jeśli podpisałem już',
        PARAG22: 'cyrograf',
        PARAG23: 'umowę',
        PARAG24: 'Przecież i tak będzie drogo, co za różnica',
        PARAG25:
          'To właśnie Ty(!) masz największy wpływ na koszt kredytu! To Ty decydujesz ile swoich pieniędzy chcesz oddać bankowi!',
        PARAG26:
          'Zapewne nie każdy się ze mną zgodzi, bo przecież kredyty różnią się marżą, ubezpieczeniami, wibor się zmienia, itd. itd. To z pewnością fakt. ALE. Jeśli np. dokonując nadpłat możesz 2-3 krotnie zmniejszyć koszt swojego kredytu, to nijak będzie się to miało do porównania dobrej i gorszej ofety kredytowej z tego samego okresu ;)',
        PARAG27:
          'Na pewno warto zestawić i porównać ze sobą oferty banków, ale pamiętajmy, że najwięcej możemy zaoszczędzić sami, jeśli "tylko" będziemy podejmować dobre decyzje. Najlepiej przed uruchomieniem kredytu, ale także w jego trakcie.',
        PARAG28: 'Czy doradca kredytowy mi doradzi',
        PARAG29:
          'To taka miła Pani! Wszyscy znajomi i ciocia Grażynka ją chwalą',
        PARAG30: 'Najważniejsza zasada: doradca kredytowy to sprzedawca',
        PARAG31:
          'Czy widziałeś kiedyś, żeby sprzedawca (zwłaszcza taki, u którego zostawisz setki tysięcy złotych) był dla Ciebie niemiły? :D Doradca kredytowy, to nic innego jak sprzedawca telewizorów w sklepie z AGD. Sprzedaje telewizory różnych producentów (banków), podpowie Ci, który telewizor ma wyraźniejsze kolory lub lepszą jakość dźwięku (oprocentowanie, dodatkowe opłaty). Powinien (różnie bywa) doradzić Ci, czy lepszy dla Ciebie będzie LG, Samsung, czy Sony, czy dla Twojego pokoju lepsze będzie 55 czy 60 cali. Ale jak każdy sprzedawca - pomoże dokonać Ci wyboru w tym co ma na stanie.',
        PARAG32: 'Na pewno nie(!) odradzi Ci kupna (wzięcia kredytu)',
        PARAG33:
          'Jego zadaniem jest, żebyś kupił od niego produkt w miłej i sympatycznej atmosferze, tak, żebyś mógł się nabytkiem pochwalić przed sąsiadem. Ale - to wcale nie musi być jego zła wola - może nie poinformować Cię, że model danej marki jest przestarzały (że warto nadpłacać), ma regularnie psującego się pilota (Wibor może się wzrosnąć), a także, że po roku będziesz szukał gwarancji po całym domu, bo producent przyoszczędził na głośnikach. (dałeś sobie wcisnąć zbędne ubezpieczenia)',
        PARAG34:
          'Wedle reguły: im droższy zakup, tym bardziej sam sprawdź na co warto zwrócić uwagę',
        PARAG35:
          'Zwłaszcza, że z kredytem może okazać się nawet łatwiej niż z kupnem telewizora ;)',
        PARAG36: 'Czy płacę za doradcę kredytowego',
        PARAG37: 'Ach, ta Pani jest taka miła',
        PARAG38:
          'A czy płaciłeś kiedyś za obsługę Pana, który sprzedawał Ci telewizor',
        PARAG39:
          'PS. Nie płacisz za to bezpośrednio, ale sprzedawcy telewizorów nie pracują za darmo ;)',
        PARAG40: 'Słyszałem/am, że najpierw i tak spłaca się odsetki',
        PARAG41: 'Czyli co z tego, że nadpłacę, będę kobminować',
        PARAG42:
          'NIE, NIE I JESZCZE RAZ NIE! Każda rata składa się z części kapitałowej ORAZ odsetkowej (+ koszty ubezpieczeń)',
        PARAG43:
          'Jeśli dzisiaj uruchomisz kredyt, a jutro go spłacisz (tego Ci życzę!), to bankowi zapłacisz kilka złotych (odsetki naliczone przez jeden dzień) - oczywiście, jeśli Twój kredyt nie miał prowizji, ale to też znikomy koszt w porównaniu do "typowych" kosztów kredytu',
        PARAG44:
          'Im szybciej spłacisz kredyt, tym z reguły będzie on tańszy - bank mniej na Tobie zarobi',
        PARAG45: 'Jak stworzyć przedział zmiany Wiboru za pomocą kalkulatora',
        PARAG46:
          'Czyli co, jeśli Wibor zmieni się tylko na kilka miesięcy i wróci do poprzedniej wartości',
        PARAG47:
          'Np. Podczas uruchomienia kredytu Wibor wyniósł 6,90%. Jednak po kilku miesiącach wzrósł do 8,0%, żeby po jakimś czasie wrócić do poprzedniej wartości 6,90%.',
        PARAG48:
          'W kalkulatorze ustaw dwie zmiany Wiboru - najpierw na 8,0%, następnie na 6,90%',
        PARAG49: 'Jak porównać dwa kredyty za pomocą tej strony',
        PARAG50: 'Bez filozofii: otwórz sobie dwa okna przeglądarki :D',
      },
      results: {
        YOUR_RESULTS: 'Twoje wyniki',
        SUMMARY: 'Podsumowanie',
      },
      errors: {
        SOMETHING_WRONG_HAPPENED: 'Coś się zepsuło :(',
        STATUTE_REQUIRED: 'Wymagana akceptacja regulaminu',
        NUMBERS_REQUIRED: 'Wymagane wartości numeryczne',
        POSITIVE_NUMBERS_REQUIRED: 'Wymagane dodatnie wartości numeryczne',
        WRONG_RATE_TYPE_VALUE: 'Pole rodzaju raty ma niepoprawną wartość',
        TRANCHE_WRONG_SUM:
          'Suma transz musi być mniejsza lub równa niż kwota kredytu',
        TRANCHE_FIRST_DATE:
          'Data pierwszej transzy musi być ustawiony przed datą rozpoczęcia spłaty kredytu',
        WIBOR_CHANGE_MIN_DATE:
          'Minimalna data zmiany wiboru musi być większa lub równa dacie rozpoczęcia kredytu lub dacie najwcześniejszej transzy',
        WIBOR_CHANGE_MAX_DATE:
          'Maksymalna data zmiany wiboru musi mniejsza lub równa dacie ostatniej raty',
        CONSTANT_FEE_MIN_START_DATE:
          'Minimalna data początkowa opłaty stałej musi być większa lub równa dacie rozpoczęcia kredytu lub dacie najwcześniejszej transzy',
        CONSTANT_FEE_MAX_START_DATE:
          'Maksymalna data początkowa opłaty stałej musi mniejsza lub równa dacie ostatniej raty',
        CHANGING_FEE_MIN_START_DATE:
          'Minimalna data początkowa opłaty zmiennej musi być większa lub równa dacie rozpoczęcia kredytu lub dacie najwcześniejszej transzy',
        CHANGING_FEE_MAX_START_DATE:
          'Maksymalna data początkowa opłaty zmiennej musi mniejsza lub równa dacie ostatniej raty',
        SURCHARGE_MIN_START_DATE:
          'Minimalna data początkowa nadpłaty musi być większa lub równa dacie rozpoczęcia kredytu',
        SURCHARGE_MAX_START_DATE:
          'Maksymalna data początkowa nadpłaty musi mniejsza lub równa dacie ostatniej raty',
        CONSTANT_FEE_MIN_END_DATE:
          'Minimalna data końcowa opłaty stałej musi być większa lub równa dacie rozpoczęcia kredytu lub dacie najwcześniejszej transzy',
        CONSTANT_FEE_MAX_END_DATE:
          'Maksymalna data końcowa opłaty stałej musi mniejsza lub równa dacie ostatniej raty',
        CHANGING_FEE_MIN_END_DATE:
          'Minimalna data końcowa opłaty zmiennej musi być większa lub równa dacie rozpoczęcia kredytu lub dacie najwcześniejszej transzy',
        CHANGING_FEE_MAX_END_DATE:
          'Maksymalna data końcowa opłaty zmiennej musi mniejsza lub równa dacie ostatniej raty',
        SURCHARGE_MIN_END_DATE:
          'Minimalna data nadpłaty musi być większa lub równa dacie rozpoczęcia kredytu',
        SURCHARGE_MAX_END_DATE:
          'Maksymalna data nadpłaty musi być mniejsza lub równa dacie ostatniej raty',
        START_DATE_NOT_OLDER:
          'Data końcowa nie moze być mniejsza niż data początkowa',
      },
    },
  },
};
