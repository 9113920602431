import { CONSTANT_FEE, CHANGING_FEE } from '../constants/constants';
import { monthBeginningMoment } from './dateHelpers';
import moment from 'moment';

export const calcFee = ({
  changingFees,
  constantFees,
  firstRateDay,
  i,
  remainedCapital,
}) => {
  const feesArrs = [
    { type: CHANGING_FEE, arr: changingFees },
    { type: CONSTANT_FEE, arr: constantFees },
  ];
  let changingFeesInMonth = 0;
  let constantFeesInMonth = 0;
  feesArrs.forEach((feeObj) => {
    feeObj.arr.forEach((fee) => {
      if (
        monthBeginningMoment(
          moment(firstRateDay).add(i - 1, 'months'),
        ).isSameOrAfter(monthBeginningMoment(fee.startDate)) &&
        monthBeginningMoment(
          moment(firstRateDay).add(i - 1, 'months'),
        ).isSameOrBefore(monthBeginningMoment(fee.endDate))
      ) {
        if (feeObj.type === CHANGING_FEE) {
          changingFeesInMonth += (remainedCapital * fee.value) / 100;
        } else {
          constantFeesInMonth += fee.value;
        }
      }
    });
  });
  return { changingFeesInMonth, constantFeesInMonth };
};
