import React from 'react';
import { Helmet } from 'react-helmet';
import AdSenseFrame from '../sub/AdSenseFrame';
import { useTranslation } from 'react-i18next';

const Status = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <Helmet>
        <title>{t('status.PAGE_TITLE')}</title>
      </Helmet>
      <article>
        <header>
          <h1 className="py-5 text-center">{t('general.STATUTE')}</h1>
        </header>
        <div className="pb-4">
          <p>{t('status.PARAG1')}</p>
          <p>{t('status.PARAG2')}</p>
          <p>{t('status.PARAG3')}</p>
        </div>
      </article>
      <div>
        <AdSenseFrame slotId="3521281142" />
      </div>
    </div>
  );
};

export default Status;
