import React, { useContext, useState, useEffect } from 'react';
import '../locals/i18n';
import availableLangObjs from '../locals/availableLangObjs';
import { useTranslation } from 'react-i18next';

const LangObjContext = React.createContext({});

export const LangObjProvider = ({ children }) => {
  const pathname = window.location.pathname.split('/')[1];
  const defLangObj = availableLangObjs.find(
    (lg) => lg.subPath === pathname,
  ) || { subPath: '', lang: 'pl' };
  const [langObj, setLangObj] = useState(defLangObj);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(langObj.lang);
  }, [i18n, langObj.lang]);

  return (
    <LangObjContext.Provider
      value={{
        langObj,
        setLangObj,
      }}
    >
      {children}
    </LangObjContext.Provider>
  );
};

export const useLangObjContext = () => useContext(LangObjContext);
