import i18next from 'i18next';

export const getDataFromURL = () => {
  const search = window.location.search;
  const searchParamShort = new URLSearchParams(search)?.get('calc');
  if (!searchParamShort) return null;

  const searchParam = searchParamShort
    ?.replaceAll('_', '%22') // "
    .replaceAll('~', '%3A') // :
    .replaceAll('X', '%2C') // ,
    .replaceAll('*', '%7D'); // }

  const decodedParam = decodeURIComponent(searchParam);
  const objParam = JSON.parse(decodedParam);
  const initState = {
    creditValue: objParam.creditValue,
    creditDuration: objParam.creditDuration,
    rateType: objParam.rateType,
    bankMargin: objParam.bankMargin,
    wiborStart: objParam.wiborStart,
    serviceCharge: objParam.serviceCharge,
    constantFees: objParam.constantFees.map((fee) => ({
      ...fee,
      startDate: new Date(parseInt(fee.startDate)),
      endDate: new Date(parseInt(fee.endDate)),
    })),
    changingFees: objParam.changingFees.map((fee) => ({
      ...fee,
      startDate: new Date(parseInt(fee.startDate)),
      endDate: new Date(parseInt(fee.endDate)),
    })),
    firstRateDay: new Date(parseInt(objParam.firstRateDay)),
    wiborChanges: objParam.wiborChanges.map((wib) => ({
      ...wib,
      date: new Date(parseInt(wib.date)),
    })),
    surcharges: objParam.surcharges.map((sur) => ({
      ...sur,
      startDate: new Date(parseInt(sur.startDate)),
      endDate: new Date(parseInt(sur.endDate)),
    })),
    tranches: objParam.tranches.map((tra) => ({
      ...tra,
      date: new Date(parseInt(tra.date)),
    })),
    isForShorten: JSON.parse(objParam.isForShorten),
    isStatusAccepted: false,
    allData: {},

    errors: {
      errStatusAccepted: i18next.t('errors.STATUTE_REQUIRED'),
    },
  };
  return initState;
};

// it's only test-purpose function, no real logic usage
export const getDataFromLocalStorage = (key, value) => {
  return { [key]: value };
};
