import React from 'react';

const Footer = () => {
  return (
    <footer className="footer bg-dark">
      <a href="https://github.com/kirkol">
        <i className="fab fa-github-alt fs-1" />
      </a>
    </footer>
  );
};

export default Footer;
