import React, { useState } from 'react';
import { Col, Label, FormGroup, Input } from 'reactstrap';

import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import { FeeFormGroup } from './FeeFormGroup';
import {
  CONSTANT,
  DECREASING,
  CONSTANT_FEE,
  CHANGING_FEE,
} from '../../constants/constants';
import { useTranslation } from 'react-i18next';

const FormBasic = ({
  creditValue,
  onChangeCreditValue,
  creditDuration,
  onChangeCreditDuration,
  rateType,
  onChangeRateType,
  bankMargin,
  onChangeBankMargin,
  wiborStart,
  onChangeWiborStart,
  serviceCharge,
  onChangeServiceCharge,
  constantFees,
  changingFees,
  addFee,
  removeFee,
  updateFee,
  isStatusAccepted,
  onStatusAccept,
  errors,
}) => {
  const { t } = useTranslation();

  const [tooltipConstantOpen, setTooltipConstantOpen] = useState(false);
  const [tooltipChangingOpen, setTooltipChangingOpen] = useState(false);

  return (
    <div className="form-part">
      <h2 className="pb-3">{t('general.BASIC_DATA')}</h2>
      <FormGroup className="form-group" row>
        <Label for="credit-value" sm={4}>
          {t('general.CREDIT_AMOUNT')}:
        </Label>
        <Col sm={8}>
          <Input
            name="credit-value"
            type="text"
            plaintext={false}
            value={creditValue}
            data-testid="credit-value-field"
            className={errors.errCreditValue && 'error'}
            onChange={(e) => {
              onChangeCreditValue(e.target.value);
            }}
          />
          {errors.errCreditValue && (
            <p className="m-0 error" data-testid="credit-value-error">
              {errors.errCreditValue}
            </p>
          )}
        </Col>
      </FormGroup>

      <FormGroup className="form-group" row>
        <Label for="credit-duration" sm={4}>
          {t('general.CREDIT_PERIOD')} [{t('general.MONTH')}]:
        </Label>
        <Col sm={8}>
          <Input
            name="credit-duration"
            data-testid="credit-duration-field"
            className={errors.errCreditDuration && 'error'}
            type="text"
            value={creditDuration}
            onChange={(e) => onChangeCreditDuration(e.target.value)}
          />
          {errors.errCreditDuration && (
            <p className="m-0 error" data-testid="credit-duration-error">
              {errors.errCreditDuration}
            </p>
          )}
        </Col>
      </FormGroup>

      <FormGroup className="form-group mb-0" row>
        <Label for="rate-type" sm={4}>
          {t('general.RATE_TYPE')}:
        </Label>
        <Col sm={8}>
          <Input
            type="select"
            data-testid="rate-type-field"
            name="rate-type"
            value={rateType}
            onChange={(e) => onChangeRateType(e.target.value)}
          >
            <option value={CONSTANT}>
              {t('general.CONSTANT_INSTALLMENTS')}
            </option>
            <option value={DECREASING}>
              {t('general.DECREASING_INSTALLMENTS')}
            </option>
          </Input>
        </Col>
      </FormGroup>

      <FormGroup className="form-group" row>
        <Label for="bank-margin" sm={4}>
          {t('general.BANK_MARGIN')} [%]:
        </Label>
        <Col sm={8}>
          <Input
            className={errors.errBankMargin && 'error'}
            name="bank-margin"
            data-testid="bank-margin-field"
            type="text"
            value={bankMargin}
            onChange={(e) => onChangeBankMargin(e.target.value)}
          />
          {errors.errBankMargin && (
            <p className="m-0 error" data-testid="bank-margin-error">
              {errors.errBankMargin}
            </p>
          )}
        </Col>
      </FormGroup>

      <FormGroup className="form-group" row>
        <Label for="wibor-start" sm={4}>
          {t('general.WIBOR_START')} [%]:
        </Label>
        <Col sm={8}>
          <Input
            className={errors.errWiborStart && 'error'}
            name="wibor-start"
            data-testid="wibor-start-field"
            type="text"
            value={wiborStart}
            onChange={(e) => onChangeWiborStart(e.target.value)}
          />
          {errors.errWiborStart && (
            <p className="m-0 error" data-testid="wibor-start-error">
              {errors.errWiborStart}
            </p>
          )}
        </Col>
      </FormGroup>

      <FormGroup className="form-group" row>
        <Label for="service-charge" sm={4}>
          {t('general.SERVICE_CHARGE')} [%]:
        </Label>
        <Col sm={8}>
          <Input
            className={errors.errServiceCharge && 'error'}
            name="serviceCharge"
            data-testid="service-charge-field"
            type="text"
            value={serviceCharge}
            onChange={(e) => onChangeServiceCharge(e.target.value)}
          />
          {errors.errServiceCharge && (
            <p className="m-0 error" data-testid="service-charge-error">
              {errors.errServiceCharge}
            </p>
          )}
        </Col>
      </FormGroup>
      <FeeFormGroup
        testid={'constant-fee'}
        errors={errors}
        feeType={CONSTANT_FEE}
        forName={'constant-fee'}
        labelText={`${t('general.CONSTANT_FEES')}:`}
        isTooltipOpen={tooltipConstantOpen}
        setIsTooltipOpen={setTooltipConstantOpen}
        tooltipText={`${t('general.CONSTANT_FEE_TOOLTIP')}`}
        fees={constantFees}
        valueTxt={`${t('general.AMOUNT')}`}
        valueUnit={`[${t('general.CURRENCY')}]`}
        addFee={addFee}
        removeFee={removeFee}
        updateFee={updateFee}
      />
      <FeeFormGroup
        testid={'changing-fee'}
        errors={errors}
        feeType={CHANGING_FEE}
        forName={'changing-fee'}
        labelText={`${t('general.CHANGING_FEES')}:`}
        isTooltipOpen={tooltipChangingOpen}
        setIsTooltipOpen={setTooltipChangingOpen}
        tooltipText={t('general.CHANGING_FEE_TOOLTIP')}
        fees={changingFees}
        valueTxt={t('form.PERCENT_OF_REMAINING_CAPITAL')}
        valueUnit={'[%]'}
        addFee={addFee}
        removeFee={removeFee}
        updateFee={updateFee}
      />

      <FormGroup row className="status-row">
        <Col sm={4}>
          <Link to="/regulamin">{t('general.STATUTE')}:</Link>
        </Col>
        <Label
          check
          data-testid="status-error"
          sm={8}
          className={'pt-0 ' + (errors.errStatusAccepted && 'error')}
        >
          <Input
            className="me-2"
            data-testid="status-field"
            type="checkbox"
            name="status"
            selected={isStatusAccepted}
            onChange={() => onStatusAccept()}
          />{' '}
          {t('general.ACCEPT_STATUTE')}
        </Label>
      </FormGroup>
    </div>
  );
};

export default FormBasic;
