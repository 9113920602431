import validate from './validateFieldsOnFly';
import { monthBeginning, monthEnd, creditLastDay } from './dateHelpers';
import { CONSTANT_FEE } from '../constants/constants';
import moment from 'moment';
import i18next from 'i18next';

export const onStatusAccept = (setIsStatusAccepted, setErrors) => {
  setIsStatusAccepted((prev) =>
    validate({
      prev,
      value: !prev,
      errName: 'errStatusAccepted',
      fieldName: 'statusAccepted',
      setErrors,
    }),
  );
};

export const onChangeCreditValue = (value, setCreditValue, setErrors) => {
  setCreditValue((prev) =>
    validate({
      prev,
      value,
      errName: 'errCreditValue',
      fieldName: 'creditValue',
      setErrors,
    }),
  );
};

export const onChangeCreditDuration = (value, setCreditDuration, setErrors) => {
  setCreditDuration((prev) =>
    validate({
      prev,
      value,
      errName: 'errCreditDuration',
      fieldName: 'creditDuration',
      setErrors,
    }),
  );
};

export const onChangeRateType = (value, setRateType) => {
  setRateType(value);
};

export const onChangeBankMargin = (value, setBankMargin, setErrors) => {
  setBankMargin((prev) =>
    validate({
      prev,
      value,
      errName: 'errBankMargin',
      fieldName: 'bankMargin',
      setErrors,
    }),
  );
};

export const onChangeWiborStart = (value, setWiborStart, setErrors) => {
  setWiborStart((prev) =>
    validate({
      prev,
      value,
      errName: 'errWiborStart',
      fieldName: 'wiborStart',
      setErrors,
    }),
  );
};

export const onChangeServiceCharge = (value, setServiceCharge, setErrors) => {
  setServiceCharge((prev) =>
    validate({
      prev,
      value,
      errName: 'errServiceCharge',
      fieldName: 'serviceCharge',
      setErrors,
    }),
  );
};

export const addFee = ({
  feeType,
  key,
  setConstantFees,
  setChangingFees,
  firstRateDay,
  creditDuration,
}) => {
  const add = feeType === CONSTANT_FEE ? setConstantFees : setChangingFees;
  add((prevFees) => {
    return [
      ...prevFees,
      {
        key,
        startDate: firstRateDay,
        endDate: creditLastDay(firstRateDay, creditDuration),
        value: feeType === CONSTANT_FEE ? '10' : '0.03',
      },
    ];
  });
};
export const removeFee = ({
  feeType,
  key,
  setConstantFees,
  setChangingFees,
  setErrors,
}) => {
  const remove = feeType === CONSTANT_FEE ? setConstantFees : setChangingFees;
  remove((prevFees) => prevFees.filter((fee) => fee.key !== key));
  setErrors((prev) => {
    const newState = prev;
    delete newState[
      feeType === CONSTANT_FEE ? 'errConstantFee' : 'errChangingFee'
    ];
    return newState;
  });
};
export const updateFee = ({
  feeType,
  key,
  startDate,
  endDate,
  value,
  setConstantFees,
  setChangingFees,
  firstRateDay,
  creditDuration,
  tranches,
  setErrors,
  type = 'value',
}) => {
  const update = feeType === CONSTANT_FEE ? setConstantFees : setChangingFees;
  update((prevFees) =>
    prevFees.map((fee) => {
      if (fee.key === key) {
        // fee startDate updated
        if (startDate && type === 'startDate') {
          fee.startDate = monthBeginning(startDate);
          // set max start date value
          if (startDate > creditLastDay(firstRateDay, creditDuration))
            fee.startDate = monthBeginning(
              creditLastDay(firstRateDay, creditDuration),
            );
          // set min date value
          if (tranches.length) {
            const oldestTrancheDate = [...tranches].sort(
              (a, b) => a.date - b.date,
            )[0].date;
            if (startDate < monthBeginning(oldestTrancheDate)) {
              fee.startDate = monthBeginning(oldestTrancheDate);
            }
          } else {
            if (startDate < monthBeginning(firstRateDay)) {
              fee.startDate = monthBeginning(firstRateDay);
            }
          }
          if (startDate > fee.endDate) fee.endDate = monthEnd(fee.startDate);
        }
        // fee endDate updated
        if (endDate && type === 'endDate') {
          fee.endDate = monthEnd(endDate);
          if (endDate < fee.startDate) fee.endDate = monthEnd(fee.startDate);
          if (endDate > creditLastDay(firstRateDay, creditDuration)) {
            fee.endDate = creditLastDay(firstRateDay, creditDuration);
          }
        }
        // fee value updated
        if (type === 'value') {
          fee.value = validate({
            prev: fee.value,
            value,
            errName:
              feeType === CONSTANT_FEE ? 'errConstantFee' : 'errChangingFee',
            fieldName: `${feeType}Value`,
            setErrors,
          });
        }
      }
      return fee;
    }),
  );
};
export const onChangeFirstRateDay = (value, setFirstRateDay, setErrors) => {
  setFirstRateDay((prev) =>
    monthBeginning(
      validate({
        prev,
        value: value || new Date(),
        errName: 'errFirstRateDay',
        fieldName: 'firstRateDay',
        setErrors,
      }),
    ),
  );
};

export const addWiborChange = (key, setWiborChanges, firstRateDay) => {
  setWiborChanges((prevWibors) => [
    ...prevWibors,
    {
      key,
      date: firstRateDay,
      value: '7',
    },
  ]);
};
export const removeWiborChange = (key, setWiborChanges, setErrors) => {
  setWiborChanges((prevWibors) => prevWibors.filter((w) => w.key !== key));
  setErrors((prev) => {
    const newState = prev;
    delete newState['errWiborChange'];
    return newState;
  });
};
export const updateWiborChange = ({
  key,
  date,
  value,
  setWiborChanges,
  firstRateDay,
  tranches,
  creditDuration,
  setErrors,
  type = 'value',
}) => {
  setWiborChanges((prevWibors) =>
    prevWibors.map((wib) => {
      if (wib.key === key) {
        // date of wibor change updated
        if (date && type === 'date') {
          const prevWiborDate = wib.date;
          wib.date = monthBeginning(date);
          // set max date value
          if (date > creditLastDay(firstRateDay, creditDuration))
            wib.date = monthBeginning(
              creditLastDay(firstRateDay, creditDuration),
            );
          // set min date value
          if (tranches.length) {
            const oldestTrancheDate = [...tranches].sort(
              (a, b) => a.date - b.date,
            )[0].date;
            if (date < monthBeginning(oldestTrancheDate)) {
              wib.date = monthBeginning(oldestTrancheDate);
            }
          } else {
            if (date < monthBeginning(firstRateDay)) {
              wib.date = monthBeginning(firstRateDay);
            }
          }

          validate({
            prev: prevWiborDate,
            value: wib.date,
            errName: 'errWiborChange',
            fieldName: 'wiborChangeDate',
            setErrors,
          });
        }
        if (type === 'value') {
          wib.value = validate({
            prev: wib.value,
            value,
            errName: 'errWiborChange',
            fieldName: 'wiborChangeValue',
            setErrors,
          });
        }
      }
      return wib;
    }),
  );
};

export const addTranche = (key, setTranches, firstRateDay) => {
  const defValue = '1000';
  setTranches((prevTranches) => [
    ...prevTranches,
    {
      key,
      date: monthBeginning(moment(firstRateDay).add(-6, 'months')),
      value: defValue,
    },
  ]);
};
export const removeTranche = (key, setTranches, setErrors) => {
  setTranches((prevTranches) => prevTranches.filter((w) => w.key !== key));
  setErrors((prev) => {
    const newState = prev;
    delete newState['errTranche'];
    return newState;
  });
};
export const updateTranche = ({
  key,
  date,
  value,
  setTranches,
  firstRateDay,
  creditValue,
  setErrors,
  type = 'value',
}) => {
  setTranches((prevTranches) => {
    let newTraValue = value;
    return prevTranches.map((tra) => {
      if (tra.key === key) {
        // date of tranche change updated
        if (date && type === 'date') {
          tra.date = monthBeginning(date);
          if (date >= monthBeginning(firstRateDay))
            tra.date = monthBeginning(moment(firstRateDay).add(-6, 'months'));
        }
        if (value) {
          let tranchesSum = 0;
          prevTranches
            .filter((tra2) => tra2.key !== key)
            .forEach((tra3) => {
              tranchesSum += parseFloat(tra3.value);
            });
          if (tranchesSum + parseFloat(newTraValue) > parseFloat(creditValue)) {
            setErrors((prev) => ({
              ...prev,
              errTranche: i18next.t('errors.TRANCHE_WRONG_SUM'),
            }));
          }
        }
        if (type === 'value') {
          tra.value = validate({
            prev: tra.value,
            value: newTraValue,
            errName: 'errTranche',
            fieldName: 'trancheValue',
            setErrors,
          });
        }
      }
      return tra;
    });
  });
};

export const addSurcharge = (key, setSurcharges, firstRateDay) => {
  setSurcharges((prevSurcharges) => [
    ...prevSurcharges,
    {
      key,
      startDate: firstRateDay,
      endDate: monthEnd(firstRateDay),
      value: '1000',
    },
  ]);
};
export const removeSurcharge = (key, setSurcharges) => {
  setSurcharges((prev) => prev.filter((sur) => sur.key !== key));
};
export const updateSurcharge = ({
  key,
  startDate,
  value,
  endDate,
  setSurcharges,
  firstRateDay,
  creditDuration,
  setErrors,
  type = 'value',
}) => {
  setSurcharges((prevSurs) =>
    prevSurs.map((sur) => {
      if (sur.key === key) {
        // sur startDate updated
        if (startDate && type === 'startDate') {
          sur.startDate = monthBeginning(startDate);
          if (startDate > creditLastDay(firstRateDay, creditDuration))
            sur.startDate = monthBeginning(
              creditLastDay(firstRateDay, creditDuration),
            );
          if (startDate > sur.endDate) sur.endDate = monthEnd(sur.startDate);
          if (startDate < firstRateDay) sur.startDate = firstRateDay;
        }
        // sur endDate updated
        if (endDate && type === 'endDate') {
          sur.endDate = monthEnd(endDate);
          if (endDate < sur.startDate) sur.endDate = monthEnd(sur.startDate);
          if (endDate > creditLastDay(firstRateDay, creditDuration)) {
            sur.endDate = creditLastDay(firstRateDay, creditDuration);
          }
        }
        if (type === 'value') {
          sur.value = validate({
            prev: sur.value,
            value,
            errName: 'errSurcharge',
            fieldName: 'surchargeValue',
            setErrors,
          });
        }
      }
      return sur;
    }),
  );
};
