// checks if certain field contains proper data format (float, integet, <0, etc.)
// AND checks the dependencies between fields (cannot add wibor change before first tranche or credit start date in case when no tranches)
import { monthBeginning, monthEnd, creditLastDay } from './dateHelpers';
import { CONSTANT, DECREASING } from '../constants/constants';
import moment from 'moment';
import i18next from 'i18next';

export const parseAndValidate = (inp) => {
  // PARSE
  const creditValue = parseFloat(inp.creditValue);
  const creditDuration = parseInt(inp.creditDuration);
  const rateType = inp.rateType;
  const bankMargin = parseFloat(inp.bankMargin);
  const wiborStart = parseFloat(inp.wiborStart);
  const serviceCharge = parseFloat(inp.serviceCharge);
  const constantFees = inp.constantFees.map((fee) => ({
    key: fee.key,
    startDate: monthBeginning(fee.startDate),
    endDate: monthEnd(fee.endDate),
    value: parseFloat(fee.value),
  }));
  const changingFees = inp.changingFees.map((fee) => ({
    key: fee.key,
    startDate: monthBeginning(fee.startDate),
    endDate: monthEnd(fee.endDate),
    value: parseFloat(fee.value),
  }));
  const firstRateDay = monthBeginning(inp.firstRateDay);
  const wiborChanges = inp.wiborChanges.map((wib) => ({
    key: wib.key,
    date: monthBeginning(wib.date),
    value: parseFloat(wib.value),
  }));
  const surcharges = inp.surcharges.map((sur) => ({
    key: sur.key,
    startDate: monthBeginning(sur.startDate),
    endDate: monthEnd(sur.endDate),
    value: parseFloat(sur.value),
  }));
  const tranches = inp.tranches.map((tra) => ({
    key: tra.key,
    date: monthBeginning(tra.date),
    value: parseFloat(tra.value),
  }));
  const isForShorten = inp.isForShorten;
  const isStatusAccepted = inp.isStatusAccepted;

  // VALIDATE

  // isStatusAccepted
  if (
    !isStatusAccepted &&
    (isStatusAccepted !== true || isStatusAccepted !== false)
  ) {
    return {
      err: {
        name: 'errStatusAccepted',
        text: i18next.t('errors.STATUTE_REQUIRED'),
      },
    };
  }

  // credit value
  if (
    typeof creditValue !== 'number' ||
    !creditValue ||
    isNaN(creditValue) ||
    creditValue <= 0
  ) {
    return {
      err: {
        name: 'errCreditValue',
        text: i18next.t('errors.POSITIVE_NUMBERS_REQUIRED'),
      },
    };
  }

  // credit duration
  if (
    typeof creditDuration !== 'number' ||
    !creditDuration ||
    isNaN(creditDuration) ||
    creditDuration <= 0
  ) {
    return {
      err: {
        name: 'errCreditDuration',
        text: i18next.t('errors.POSITIVE_NUMBERS_REQUIRED'),
      },
    };
  }

  // rate type
  if (
    typeof rateType !== 'string' ||
    !rateType === CONSTANT ||
    !rateType === DECREASING
  ) {
    console.error(i18next.t('errors.WRONG_RATE_TYPE_VALUE'));
    return {
      err: {
        name: 'errRateType',
        text: i18next.t('errors.WRONG_RATE_TYPE_VALUE'),
      },
    };
  }

  // bank margin
  if (typeof bankMargin !== 'number' || isNaN(bankMargin)) {
    return {
      err: {
        name: 'errBankMargin',
        text: i18next.t('errors.NUMBERS_REQUIRED'),
      },
    };
  }

  // wibor start
  if (typeof wiborStart !== 'number' || isNaN(wiborStart)) {
    return {
      err: {
        name: 'errWiborStart',
        text: i18next.t('errors.NUMBERS_REQUIRED'),
      },
    };
  }

  // service charge
  if (
    typeof serviceCharge !== 'number' ||
    isNaN(serviceCharge) ||
    serviceCharge < 0
  ) {
    return {
      err: {
        name: 'errServiceCharge',
        text: i18next.t('errors.NUMBERS_REQUIRED'),
      },
    };
  }

  // fee value
  const constFeesValueErr = constantFees.filter(
    (fee) =>
      typeof fee.value !== 'number' || isNaN(fee.value) || fee.value <= 0,
  );
  if (constFeesValueErr.length > 0) {
    return {
      err: {
        name: 'errConstantFee',
        text: i18next.t('errors.POSITIVE_NUMBERS_REQUIRED'),
      },
    };
  }
  const changingFeesValueErr = changingFees.filter(
    (fee) =>
      typeof fee.value !== 'number' || isNaN(fee.value) || fee.value <= 0,
  );
  if (changingFeesValueErr.length > 0) {
    return {
      err: {
        name: 'errChangingFee',
        text: i18next.t('errors.POSITIVE_NUMBERS_REQUIRED'),
      },
    };
  }

  // surcharge value
  const surchargeValueErr = surcharges.filter(
    (sur) =>
      typeof sur.value !== 'number' || isNaN(sur.value) || sur.value <= 0,
  );
  if (surchargeValueErr.length > 0) {
    return {
      err: {
        name: 'errSurcharge',
        text: i18next.t('errors.POSITIVE_NUMBERS_REQUIRED'),
      },
    };
  }

  // tranche value
  const trancheValueErr = tranches.filter(
    (tra) =>
      typeof tra.value !== 'number' || isNaN(tra.value) || tra.value <= 0,
  );
  if (trancheValueErr.length > 0) {
    return {
      err: {
        name: 'errTranche',
        text: i18next.t('errors.POSITIVE_NUMBERS_REQUIRED'),
      },
    };
  }
  let tranchesSum = 0;
  tranches.forEach((tra) => {
    tranchesSum += tra.value;
  });
  if (tranchesSum > creditValue) {
    return {
      err: {
        name: 'errTranche',
        text: i18next.t('errors.TRANCHE_WRONG_SUM'),
      },
    };
  }

  // tranche date
  const trancheDateErr = tranches.filter((tra) => tra.date >= firstRateDay);
  if (trancheDateErr.length > 0) {
    return {
      err: {
        name: 'errTranche',
        text: i18next.t('errors.TRANCHE_FIRST_DATE'),
      },
    };
  }

  const earlierTrancheDate = [...tranches].sort((a, b) => a.date - b.date)[0];
  // wibor change value
  const wiborChangeValueErr = wiborChanges.filter(
    (wib) => typeof wib.value !== 'number' || isNaN(wib.value),
  );
  if (wiborChangeValueErr.length > 0) {
    return {
      err: {
        name: 'errWiborChange',
        text: i18next.t('errors.NUMBERS_REQUIRED'),
      },
    };
  }

  // wibor change date
  const wiborChangesMinDateErr = wiborChanges.filter((wib) =>
    earlierTrancheDate?.date
      ? wib.date < earlierTrancheDate.date
      : wib.date < firstRateDay,
  );
  if (wiborChangesMinDateErr.length > 0) {
    return {
      err: {
        name: 'errWiborChange',
        text: i18next.t('errors.WIBOR_CHANGE_MIN_DATE'),
      },
    };
  }
  const wiborChangesMaxDateErr = wiborChanges.filter((wib) => {
    return (
      wib.date >
      monthBeginning(moment(firstRateDay).add(creditDuration - 1, 'months'))
    );
  });
  if (wiborChangesMaxDateErr.length > 0) {
    return {
      err: {
        name: 'errWiborChange',
        text: i18next.t('errors.WIBOR_CHANGE_MAX_DATE'),
      },
    };
  }

  // constant fees startDate
  const constantFeesMinStartDateErr = constantFees.filter((fee) => {
    return earlierTrancheDate?.date
      ? fee.startDate < earlierTrancheDate.date
      : fee.startDate < firstRateDay;
  });
  if (constantFeesMinStartDateErr.length > 0) {
    return {
      err: {
        name: `errConstantFee`,
        text: i18next.t('errors.CONSTANT_FEE_MIN_START_DATE'),
      },
    };
  }
  const constantFeesMaxStartDateErr = constantFees.filter((fee) => {
    return fee.startDate > creditLastDay(firstRateDay, creditDuration);
  });
  if (constantFeesMaxStartDateErr.length > 0) {
    return {
      err: {
        name: `errConstantFee`,
        text: i18next.t('errors.CONSTANT_FEE_MAX_START_DATE'),
      },
    };
  }

  // changing fees startDate
  const changingFeesMinStartDateErr = changingFees.filter((fee) => {
    return earlierTrancheDate?.date
      ? fee.startDate < earlierTrancheDate.date
      : fee.startDate < firstRateDay;
  });
  if (changingFeesMinStartDateErr.length > 0) {
    return {
      err: {
        name: `errChangingFee`,
        text: i18next.t('errors.CHANGING_FEE_MIN_START_DATE'),
      },
    };
  }
  const changingFeesMaxStartDateErr = changingFees.filter((fee) => {
    return fee.startDate > creditLastDay(firstRateDay, creditDuration);
  });
  if (changingFeesMaxStartDateErr.length > 0) {
    return {
      err: {
        name: `errChangingFee`,
        text: i18next.t('errors.CHANGING_FEE_MAX_START_DATE'),
      },
    };
  }

  // surcharges startDate
  const surchargesMinStartDateErr = surcharges.filter((sur) => {
    return sur.startDate < firstRateDay;
  });
  if (surchargesMinStartDateErr.length > 0) {
    return {
      err: {
        name: `errSurcharge`,
        text: i18next.t('errors.SURCHARGE_MIN_START_DATE'),
      },
    };
  }
  const surchargesMaxStartDateErr = surcharges.filter((sur) => {
    return sur.startDate > creditLastDay(firstRateDay, creditDuration);
  });
  if (surchargesMaxStartDateErr.length > 0) {
    return {
      err: {
        name: `errSurcharge`,
        text: i18next.t('errors.SURCHARGE_MAX_START_DATE'),
      },
    };
  }

  // constant fees endDate
  const constantFeesMinEndDateErr = constantFees.filter((fee) =>
    earlierTrancheDate?.date
      ? fee.endDate < earlierTrancheDate.date
      : fee.endDate < firstRateDay,
  );
  if (constantFeesMinEndDateErr.length > 0) {
    return {
      err: {
        name: `errConstantFee`,
        text: i18next.t('errors.CONSTANT_FEE_MIN_END_DATE'),
      },
    };
  }
  const constantFeesMaxEndDateErr = constantFees.filter((fee) => {
    return fee.endDate > creditLastDay(firstRateDay, creditDuration);
  });
  if (constantFeesMaxEndDateErr.length > 0) {
    return {
      err: {
        name: `errConstantFee`,
        text: i18next.t('errors.CONSTANT_FEE_MAX_END_DATE'),
      },
    };
  }

  // changing fees endDate
  const changingFeesMinEndDateErr = changingFees.filter((fee) =>
    earlierTrancheDate?.date
      ? fee.endDate < earlierTrancheDate.date
      : fee.endDate < firstRateDay,
  );
  if (changingFeesMinEndDateErr.length > 0) {
    return {
      err: {
        name: `errChangingFee`,
        text: i18next.t('errors.CHANGING_FEE_MIN_END_DATE'),
      },
    };
  }
  const changingFeesMaxEndDateErr = changingFees.filter((fee) => {
    return fee.endDate > creditLastDay(firstRateDay, creditDuration);
  });
  if (changingFeesMaxEndDateErr.length > 0) {
    return {
      err: {
        name: `errChangingFee`,
        text: i18next.t('errors.CHANGING_FEE_MAX_END_DATE'),
      },
    };
  }

  // surcharges endDate
  const surchargesMinEndDateErr = surcharges.filter(
    (sur) => sur.endDate < firstRateDay,
  );
  if (surchargesMinEndDateErr.length > 0) {
    return {
      err: {
        name: `errSurcharge`,
        text: i18next.t('errors.SURCHARGE_MIN_END_DATE'),
      },
    };
  }
  const surchargesMaxEndDateErr = surcharges.filter((sur) => {
    return sur.endDate > creditLastDay(firstRateDay, creditDuration);
  });
  if (surchargesMaxEndDateErr.length > 0) {
    return {
      err: {
        name: `errSurcharge`,
        text: i18next.t('errors.SURCHARGE_MAX_END_DATE'),
      },
    };
  }

  // constant fees - endDate cannot be before startDate
  const constantFeesWrongOrder = constantFees.filter(
    (fee) => fee.endDate < fee.startDate,
  );
  if (constantFeesWrongOrder.length > 0) {
    return {
      err: {
        name: `errConstantFee`,
        text: i18next.t('errors.START_DATE_NOT_OLDER'),
      },
    };
  }

  // changing fees - endDate cannot be before startDate
  const changingFeesWrongOrder = changingFees.filter(
    (fee) => fee.endDate < fee.startDate,
  );
  if (changingFeesWrongOrder.length > 0) {
    return {
      err: {
        name: `errChangingFee`,
        text: i18next.t('errors.START_DATE_NOT_OLDER'),
      },
    };
  }

  // surcharges - endDate cannot be before startDate
  const surchargesWrongOrder = surcharges.filter(
    (sur) => sur.endDate < sur.startDate,
  );
  if (surchargesWrongOrder.length > 0) {
    return {
      err: {
        name: `errSurcharge`,
        text: i18next.t('errors.START_DATE_NOT_OLDER'),
      },
    };
  }

  return {
    creditValue,
    creditDuration,
    rateType,
    bankMargin,
    wiborStart,
    serviceCharge,
    constantFees,
    changingFees,
    firstRateDay,
    wiborChanges,
    surcharges,
    tranches,
    isForShorten,
    isStatusAccepted,
  };
};
