import React, { useRef, useEffect } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import Summary from './Summary';
import { DECREASING } from '../../constants/constants';
import { useTranslation } from 'react-i18next';

const Results = ({ allData, rateType, printRef, isOpen, surcharges }) => {
  const scrollRef = useRef(null);
  const isOpenRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      process.env.NODE_ENV !== 'test' &&
      (scrollRef.current || (isOpenRef.current && !isOpen))
    ) {
      // not clear, but working xd
      setTimeout(() => scrollRef.current.scrollIntoView(), 300);
    }
  }, [scrollRef, allData, isOpen]);
  return (
    <div>
      <div className="pt-3 px-2">
        <header>
          <h2 className="pb-3" ref={scrollRef}>
            {t('results.YOUR_RESULTS')}
          </h2>
          <h4>
            {rateType === DECREASING
              ? t('general.DECREASING_INSTALLMENTS')
              : t('general.CONSTANT_INSTALLMENTS')}
            :
          </h4>
        </header>
        <div className="results">
          <ResponsiveContainer className="results-chart" aspect={6.0 / 2.0}>
            <AreaChart
              data={allData.data}
              margin={{
                top: 10,
                right: 30,
                left: 30,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="dkNumber" tick={{ fill: '#efefef' }} />
              <YAxis
                tick={{ fill: '#efefef' }}
                type="number"
                dataKey={(v) => parseFloat(v.total.toFixed(2))}
              />
              <Tooltip wrapperStyle={{ color: '#343a40' }} />
              <Area
                name={t('general.CAPITAL_PART')}
                type="monotone"
                dataKey="dkCapitalPart"
                stackId="1"
                stroke="#ce2323"
                fill="#ce2323"
              />
              <Area
                name={t('general.INTEREST')}
                type="monotone"
                dataKey="dkInterestPart"
                stackId="1"
                stroke="#ffbf00"
                fill="#ffbf00"
              />
              <Area
                name={t('general.CONSTANT_FEES')}
                type="monotone"
                dataKey="dkConstantFees"
                stackId="1"
                stroke="#b2b2b2"
                fill="#b2b2b2"
              />
              <Area
                name={t('general.CHANGING_FEES')}
                type="monotone"
                dataKey="dkChangingFees"
                stackId="1"
                stroke="#343a40"
                fill="#343a40"
              />
              <Area
                name={t('general.SUM')}
                type="monotone"
                dataKey="total"
                stackId="0"
                stroke="#343a40"
                fill="#343a40"
                fillOpacity={0}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <Summary
          allData={allData}
          printRef={printRef}
          surcharges={surcharges}
        />
      </div>
    </div>
  );
};
export default Results;
