import moment from 'moment';
import { CONSTANT } from '../constants/constants';
export const calcWiborChange = ({
  type,
  wiborChanges,
  beginningDate,
  i,
  wibor,
  percentage,
  bankMargin,
  qParam,
  installment,
  remainedCapital,
  monthsLeft,
}) => {
  let newWibor = wibor;
  let newPercentage = percentage;
  let newQParam = qParam;
  let newInstallment = installment;

  [...wiborChanges]
    .sort((a, b) => a.date - b.date)
    .forEach((wib) => {
      if (
        moment(beginningDate)
          .add(i - 1, 'months')
          .unix() === moment(wib.date).unix()
      ) {
        newWibor = wib.value;
        // when wibor changed we need to re-calc the installment
        // re-calc loan percentage (% for every month)
        newPercentage = (bankMargin + newWibor) / 100;

        // ONLY FOR CONSTANT INSTALLMENT
        if (type === CONSTANT) {
          // re-calc q parameter
          newQParam = 1 + newPercentage / 12;
          newInstallment =
            (remainedCapital *
              Math.pow(newQParam, monthsLeft) *
              (newQParam - 1)) /
            (Math.pow(newQParam, monthsLeft) - 1);
        }
      }
    });
  if (type === CONSTANT) {
    return {
      wibor: newWibor,
      percentage: newPercentage,
      qParam: newQParam,
      installment: newInstallment,
    };
  }
  return {
    wibor: newWibor,
    percentage: newPercentage,
  };
};
