/* eslint-disable no-loop-func */
import { calcMonthsReduced } from './calcMonthsReducedForConstantInstallments';
import { calcWiborChange } from './calcWiborChange';
import { calcFee } from './calcFee';
import { calcTranches } from './calcTranches';
import { CONSTANT } from '../constants/constants';
import { monthBeginningMoment } from './dateHelpers';
import { calcSurchargesSumForIthMonth } from './calcSurchargesSumForIthMonth';

export const calcForConstantInstallments = (inp) => {
  const {
    creditValue,
    creditDuration,
    bankMargin,
    wiborStart,
    serviceCharge = 0,
    constantFees = [],
    changingFees = [],
    firstRateDay,
    wiborChanges = [],
    surcharges = [],
    tranches = [],
    isForShorten,
  } = inp;

  // final set of data with capital parts, interests parts, etc.
  let data = [];

  // only remainedCapital and capital sum sum declaration
  let capitalTotal = 0;
  let remainedCapital = 0;

  // only interests and their sum declaration
  let interests = 0;
  let interestsTotal = 0;

  // wibor value (might be changed during the credit duration) tranches time included
  let wibor = wiborStart;
  // surcharges sum
  let surchargesSum = 0;
  let monthsReduced = 0; // how many months have been reduced due to surcharges (when surcharges for shorten)
  let remainedSurcharge = 0;

  // helpful vars
  let percentage = 0;
  let constantFeesTotal = 0;
  let changingFeesTotal = 0;
  let qParam = 0;
  let installment = 0;
  let currentCapitalPart = 0;

  // calc service charge cost
  let serviceChargeCost = (serviceCharge * creditValue) / 100;

  // calc TRANCHES costs (before credit is started to be payed)
  const resTra = calcTranches({
    tranches,
    creditValue,
    remainedCapital,
    wiborChanges,
    wibor,
    firstRateDay,
    bankMargin,
    changingFees,
    constantFees,
    interestsTotal,
    changingFeesTotal,
    constantFeesTotal,
    data,
  });
  data = resTra.data;
  wibor = resTra.wibor;
  interestsTotal = resTra.interestsTotal;
  changingFeesTotal = resTra.changingFeesTotal;
  constantFeesTotal = resTra.constantFeesTotal;

  // CALC INSTALLMENTS, WHEN CREDIT HAS STARTED TO BE PAID
  remainedCapital = creditValue;
  let i = 1;
  for (i = 1; i <= creditDuration - monthsReduced; i++) {
    // how many months realy left
    const monthsLeft = creditDuration - monthsReduced - (i - 1);

    // --------------- INTERESTS ---------------
    //calc loan percentage (% for every month)
    percentage = (bankMargin + wibor) / 100;

    // calc the installment only for 1st month on the beginnig
    if (i === 1) {
      // calc q parameter
      qParam = 1 + percentage / 12;
      // calc installment value (capital part + interest part)
      installment =
        (remainedCapital * Math.pow(qParam, monthsLeft) * (qParam - 1)) /
        (Math.pow(qParam, monthsLeft) - 1);
    }
    // calc for proper Wibor --------------- WIBOR
    // just check if wibor has changed during the credit period
    // NOTE: change date should be a date, when wibor change had a first affect on the credit value(!)
    const w = calcWiborChange({
      type: CONSTANT,
      wiborChanges,
      beginningDate: firstRateDay,
      i,
      wibor,
      percentage,
      bankMargin,
      qParam,
      installment,
      remainedCapital,
      monthsLeft,
    });
    wibor = w.wibor;
    percentage = w.percentage;
    qParam = w.qParam;
    installment = w.installment;

    //calc interests part
    interests = (remainedCapital * percentage) / 12;
    //calc capital part
    currentCapitalPart = installment - interests;
    // calc total instersts
    interestsTotal += interests;

    // when were no surcharges for shorten it will have standard currentCapitalPart
    // but when surcharge for shorten occured then it may have reduced value
    if (
      i === creditDuration - monthsReduced &&
      isForShorten &&
      surcharges.length > 0
    ) {
      currentCapitalPart = remainedCapital;
    }
    // add capital part to total sum of payed capital parts
    capitalTotal += currentCapitalPart;

    // ------------ CHANGING & CONSTANT FEES -------------
    const fees = calcFee({
      changingFees,
      constantFees,
      firstRateDay,
      i,
      remainedCapital,
    });
    changingFeesTotal += fees.changingFeesInMonth;
    constantFeesTotal += fees.constantFeesInMonth;

    // -------------- SURCHARGES ----------------------
    // we calculate capital for NEXT(!) iteration
    const capitalPartBeforeMonthWithSurcharge = currentCapitalPart;

    let surchargeValuesSumForIthMonth = calcSurchargesSumForIthMonth({
      surcharges,
      ithMonth: i,
      firstRateDay,
    });
    if (surchargeValuesSumForIthMonth) {
      remainedCapital = creditValue - capitalTotal - surchargesSum; // remainedCapital BEFORE surcharge
      // case when surcharge is higher than remained capital
      if (surchargeValuesSumForIthMonth > remainedCapital) {
        surchargeValuesSumForIthMonth = remainedCapital;
      }
      surchargesSum += surchargeValuesSumForIthMonth;

      if (isForShorten && surcharges.length > 0) {
        // ---- SURCHARGES FOR SHORTEN ----
        const res = calcMonthsReduced(
          installment,
          {
            creditValue: remainedCapital,
            creditDuration: creditDuration - monthsReduced - i,
            bankMargin: bankMargin,
            wiborStart: wibor,
            firstRateDay: monthBeginningMoment(firstRateDay)
              .add(i, 'months')
              .toDate(),
          },
          surchargeValuesSumForIthMonth + remainedSurcharge,
        );
        remainedSurcharge = res.remainedSurcharge;
        monthsReduced = monthsReduced + res.reduction;
        remainedCapital = creditValue - capitalTotal - surchargesSum;
      } else {
        // ---- SURCHARGES FOR DECREASING ----
        remainedCapital = creditValue - capitalTotal - surchargesSum;
        // re-calc installment value (capital part + interest part)
        // Note: we use here monthsLeft - 1, becuase we calc that value for next iteration
        installment =
          (remainedCapital * Math.pow(qParam, monthsLeft - 1) * (qParam - 1)) /
          (Math.pow(qParam, monthsLeft - 1) - 1);
      }
    }
    // --------- REMAINED CAPITAL ---------
    // it should contain currently payed capitalPart and currently payed surcharge (except last)
    remainedCapital = creditValue - capitalTotal - surchargesSum;
    data.push({
      dkNumber: i,
      dkCapitalPart: parseFloat(capitalPartBeforeMonthWithSurcharge.toFixed(2)),
      dkInterestPart: parseFloat(interests.toFixed(2)),
      dkConstantFees: parseFloat(fees.constantFeesInMonth.toFixed(2)),
      dkChangingFees: parseFloat(fees.changingFeesInMonth.toFixed(2)),
      dkRemainToPay: parseFloat(remainedCapital.toFixed(2)),
      total: parseFloat(
        (
          (i === 1 ? serviceChargeCost : 0) +
          capitalPartBeforeMonthWithSurcharge +
          interests +
          fees.constantFeesInMonth +
          fees.changingFeesInMonth
        ).toFixed(2),
      ),
    });

    if (Math.round(remainedCapital) <= 0) break; // lets to pass last installment where remainedCapital = 0
  }
  const creditCost =
    creditValue +
    interestsTotal +
    constantFeesTotal +
    changingFeesTotal +
    serviceChargeCost;
  return {
    data,
    firstRateDay,
    creditValue,
    serviceChargeCost,
    interestsTotal,
    constantFeesTotal,
    changingFeesTotal,
    creditCost,

    capitalTotalSumControl: capitalTotal,
    surchargesSumControl: surchargesSum,
  };
};
