import React, { useState, useEffect } from 'react';
import { FormGroup, Col, Row, Button, Label, Tooltip, Input } from 'reactstrap';
import pl from 'date-fns/locale/pl';
import en from 'date-fns/locale/en-US';
import ua from 'date-fns/locale/uk';
import { monthEnd, monthBeginning } from '../../utils/dateHelpers';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useLangObjContext } from '../../providers/LangObjProvider';

export const FormExtended = ({
  wiborChanges,
  addWiborChange,
  removeWiborChange,
  updateWiborChange,
  surcharges,
  addSurcharge,
  removeSurcharge,
  updateSurcharge,
  tranches,
  addTranche,
  removeTranche,
  updateTranche,
  firstRateDay,
  onChangeFirstRateDay,
  isForShorten,
  setIsForShorten,
  errors,
}) => {
  const { t } = useTranslation();
  const { langObj } = useLangObjContext();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    //used to set date-pickers started from Monday (not Sunday)
    registerLocale('pl', pl);
    registerLocale('en', en);
    registerLocale('ua', ua);
    setDefaultLocale(langObj.lang);
  }, [langObj.lang]);

  return (
    <div className="form-part">
      <h2 className="pb-3">{t('general.ADDITIONAL_DATA')}</h2>
      <FormGroup className="form-group" row>
        <Col sm={4} className="date-picker">
          <Label for="firstRateDay">
            {t('form.MONTH_OF_FIRST_CAPITAL_PART_PAYMENT')}:
          </Label>
        </Col>
        <Col sm={8} className="date-picker">
          <DatePicker
            name="firstRateDay"
            placeholderText="firstRateDay"
            data-testid="first-rate-day-value"
            className="form-control"
            dateFormat="yyyy-MM"
            showMonthYearPicker
            selected={firstRateDay}
            onChange={(e) =>
              onChangeFirstRateDay(e && monthBeginning(new Date(e)))
            }
          />
        </Col>
      </FormGroup>
      <FormGroup className="form-group" row>
        <Label for="wiborChange" sm={4}>
          {t('form.WIBOR_CHANGE')}:
        </Label>
        <Col sm={8}>
          <Button
            className="btn-secondary mb-3"
            data-testid={'wibor-change-add-btn'}
            onClick={() =>
              addWiborChange(`wibor-change-${new Date().getTime()}`)
            }
          >
            {t('general.ADD_CHANGE')}
          </Button>
          {wiborChanges.map((wiborChange) => (
            <Row key={`wiborChangeRow-${wiborChange.key}`} className="pb-3">
              <Col sm={5} className="date-picker">
                <DatePicker
                  name="wiborChangeDate"
                  className="form-control"
                  placeholderText={'wibor-change-date-field'}
                  dateFormat="yyyy-MM"
                  showMonthYearPicker
                  selected={wiborChange.date}
                  onChange={(e) => {
                    updateWiborChange({
                      type: 'date',
                      key: wiborChange.key,
                      date: e && monthBeginning(new Date(e)),
                      value: `${wiborChange.value}`,
                    });
                  }}
                />
              </Col>
              <Col sm={6}>
                <Input
                  name="wiborChangeValue"
                  className={'mb-2' + (errors.errWiborChange && 'error')}
                  placeholder={`${t('general.NEW_VALUE')} [%]`}
                  data-testid="wibor-change-value-field"
                  value={wiborChange.value}
                  type="text"
                  onChange={(e) =>
                    updateWiborChange({
                      type: 'value',
                      key: wiborChange.key,
                      value: `${e.target.value}`,
                    })
                  }
                />
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center pb-2"
                sm={1}
              >
                <button
                  className="btn btn-default"
                  data-testid={'wibor-change-remove-btn'}
                  onClick={() => removeWiborChange(wiborChange.key)}
                >
                  <div className="btn-close" />
                </button>
              </Col>
            </Row>
          ))}
          {errors.errWiborChange && (
            <p data-testid={'wibor-change-error'} className="m-0 error">
              {errors.errWiborChange}
            </p>
          )}
        </Col>
      </FormGroup>
      <FormGroup className="form-group" row>
        <Label for="surcharge" sm={4}>
          {t('general.SURCHARGES')}:
        </Label>
        <Col sm={2}>
          <Button
            data-testid={'surcharges-add-btn'}
            className="btn-secondary mb-3"
            onClick={() => addSurcharge(`surcharge-${new Date().getTime()}`)}
          >
            {t('general.ADD_SURCHARGE')}
          </Button>
        </Col>
        <Col sm={6} className="pl-0 pt-2">
          <FormGroup id="surcharges" name="surchargeType">
            <Label>
              <Input
                type="checkbox"
                data-testid="is-for-shorten-field"
                className="text-secondary"
                selected={isForShorten}
                checked={isForShorten}
                onChange={() => {
                  setIsForShorten((prev) => !prev);
                }}
              />{' '}
              {t('general.FOR_SHORTEN')}
            </Label>
            <Tooltip
              placement="top"
              hideArrow={true}
              isOpen={tooltipOpen}
              target="surcharges"
              toggle={() => {
                setTooltipOpen((prev) => !prev);
              }}
              className="bg-dark"
            >
              {t('form.FOR_SHORTEN_TOOLTIP')}
            </Tooltip>
          </FormGroup>
        </Col>
      </FormGroup>
      {surcharges.length > 0 && (
        <FormGroup className="form-group" row>
          <Col sm={4} />
          <Col sm={8}>
            {surcharges.map((sur) => (
              <Row key={`surchargeRow-${sur.key}`} className="pb-3">
                <Col sm={3} className="date-picker">
                  <DatePicker
                    className="form-control pr-1 pl-1"
                    dateFormat="yyyy-MM"
                    showMonthYearPicker
                    placeholderText={'surcharges-start-date-field'}
                    selected={sur.startDate}
                    onChange={(e) =>
                      updateSurcharge({
                        type: 'startDate',
                        key: sur.key,
                        startDate: e && monthBeginning(new Date(e)),
                        value: `${sur.value}`,
                      })
                    }
                  />
                </Col>
                <Col sm={3} className="date-picker">
                  <DatePicker
                    className="form-control pr-1 pl-1"
                    dateFormat="yyyy-MM"
                    showMonthYearPicker
                    placeholderText={'surcharges-end-date-field'}
                    selected={monthEnd(new Date(sur.endDate))}
                    onChange={(e) =>
                      updateSurcharge({
                        type: 'endDate',
                        key: sur.key,
                        endDate: e && monthEnd(new Date(e)),
                        value: `${sur.value}`,
                      })
                    }
                  />
                </Col>
                <Col sm={5}>
                  <Input
                    name={`surcharge-${sur.key}`}
                    className={'mb-2' + (errors.errSurcharge && 'error')}
                    value={sur.value}
                    type="text"
                    data-testid="surcharges-value-field"
                    placeholder={t('general.CURRENCY')}
                    onChange={(e) =>
                      updateSurcharge({
                        type: 'value',
                        key: sur.key,
                        value: `${e.target.value}`,
                      })
                    }
                  />
                </Col>
                <Col
                  className={
                    'd-flex justify-content-center align-items-center pb-2'
                  }
                  sm={1}
                >
                  <button
                    className="btn btn-default"
                    data-testid={'surcharges-remove-btn'}
                    onClick={() => removeSurcharge(sur.key)}
                  >
                    <div className="btn-close" />
                  </button>
                </Col>
              </Row>
            ))}
            {errors.errSurcharge && (
              <p data-testid="surcharges-error" className="m-0 error">
                {errors.errSurcharge}
              </p>
            )}
          </Col>
        </FormGroup>
      )}
      <FormGroup className="form-group" row>
        <Label for="tranche" sm={4}>
          {t('general.TRANCHES')}:
        </Label>
        <Col sm={8}>
          <Button
            className="btn-secondary mb-3"
            data-testid={'tranches-add-btn'}
            onClick={() => addTranche(`tranche-${new Date().getTime()}`)}
          >
            {t('general.ADD_TRANCHE')}
          </Button>
          {tranches.map((tranche) => (
            <Row key={`trancheRow-${tranche.key}`} className="pb-3">
              <Col sm={5} className="date-picker">
                <DatePicker
                  name="tranche"
                  placeholderText={'tranches-date-field'}
                  className="form-control"
                  dateFormat="yyyy-MM"
                  showMonthYearPicker
                  selected={tranche.date}
                  onChange={(e) =>
                    updateTranche({
                      type: 'date',
                      key: tranche.key,
                      date: e && monthBeginning(new Date(e)),
                      value: `${tranche.value}`,
                    })
                  }
                />
              </Col>
              <Col sm={6}>
                <Input
                  name={`tranche-${tranche.key}`}
                  className={'mb-2' + (errors.errTranche && 'error')}
                  placeholder={t('general.TRANCHE_VALUE')}
                  value={tranche.value}
                  data-testid="tranches-value-field"
                  type="text"
                  onChange={(e) =>
                    updateTranche({
                      type: 'value',
                      key: tranche.key,
                      value: `${e.target.value}`,
                    })
                  }
                />
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center pb-2"
                sm={1}
              >
                <button
                  className="btn btn-default"
                  data-testid={'tranches-remove-btn'}
                  onClick={() => removeTranche(tranche.key)}
                >
                  <div className="btn-close" />
                </button>
              </Col>
            </Row>
          ))}
          {errors.errTranche && (
            <p data-testid="tranches-error" className="m-0 error">
              {errors.errTranche}
            </p>
          )}
        </Col>
      </FormGroup>
    </div>
  );
};

export default FormExtended;
