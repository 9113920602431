import React from 'react';
import { Table } from 'reactstrap';
import Dts from './Dts';
import moment from 'moment';
import AdSenseFrame from './AdSenseFrame';
import irr from '../../utils/irr';
import { useTranslation } from 'react-i18next';
import { calcSurchargesSumForIthMonth } from '../../utils/calcSurchargesSumForIthMonth';

const Summary = ({ allData, printRef, surcharges }) => {
  const {
    data,
    firstRateDay,
    creditValue,
    interestsTotal,
    serviceChargeCost,
    constantFeesTotal,
    changingFeesTotal,
    creditCost,
  } = allData;
  const { t } = useTranslation();

  const totalPaymentsWithSurchargesArr = data.map((d) => {
    let surchargeValuesSumForIthMonth = calcSurchargesSumForIthMonth({
      surcharges,
      ithMonth: d.dkNumber,
      firstRateDay,
    });

    return d.total + surchargeValuesSumForIthMonth;
  });
  const rrso =
    (Math.pow(1 + irr([-creditValue, ...totalPaymentsWithSurchargesArr]), 12) -
      1) *
    100;
  return (
    <div className="form-part">
      <AdSenseFrame slotId="6636488124" />
      <header>
        <h2 className="pb-3">{t('results.SUMMARY')}</h2>
      </header>
      <Table>
        <tbody>
          <tr>
            <th>{t('general.CREDIT_AMOUNT')}:</th>
            <td>
              {creditValue.toFixed(2)} {t('general.CURRENCY')}
            </td>
          </tr>
          <tr>
            <th>{t('general.INTEREST_TOTAL')}:</th>
            <td>
              {interestsTotal.toFixed(2)} {t('general.CURRENCY')}
            </td>
          </tr>
          <tr>
            <th>{t('general.SERVICE_CHARGE')}:</th>
            <td>
              {serviceChargeCost.toFixed(2)} {t('general.CURRENCY')}
            </td>
          </tr>
          <tr>
            <th>{t('general.CONSTANT_FEES_TOTAL')}:</th>
            <td>
              {constantFeesTotal.toFixed(2)} {t('general.CURRENCY')}
            </td>
          </tr>
          <tr>
            <th>{t('general.CHANGING_FEES_TOTAL')}:</th>
            <td>
              {changingFeesTotal.toFixed(2)} {t('general.CURRENCY')}
            </td>
          </tr>
          <tr className="bg-dark">
            <th>{t('general.CREDIT_COST_TOTAL')}:</th>
            <td>
              {(
                interestsTotal +
                serviceChargeCost +
                constantFeesTotal +
                changingFeesTotal
              ).toFixed(2)}{' '}
              {t('general.CURRENCY')}
            </td>
          </tr>
          <tr>
            <th>RRSO:</th>
            <td>{rrso.toFixed(2)} %</td>
          </tr>
          <tr className="total-result">
            <th>{t('general.TOTAL')}:</th>
            <td>
              {creditCost.toFixed(2)} {t('general.CURRENCY')}
            </td>
          </tr>
        </tbody>
      </Table>
      <Dts allData={allData} printRef={printRef} surcharges={surcharges} />
      <div className="overflow-scroll pt-4">
        <Table>
          <tbody>
            <tr className="bg-dark border-top">
              <th>{t('general.RATE_NUMBER')}</th>
              <th>{t('general.DATE')}</th>
              <th>{t('general.REMAIN_TO_PAY')}:</th>
              <th>{t('general.CAPITAL_PART')}:</th>
              <th>{t('general.INTEREST')}:</th>
              <th>{t('general.CONSTANT_FEES')}:</th>
              <th>{t('general.CHANGING_FEES')}:</th>
              {surcharges.length > 0 ? (
                <th>
                  <span>{t('general.SUM')} + </span>{' '}
                  <span className="text-secondary">
                    {t('general.SURCHARGE')}
                  </span>
                </th>
              ) : (
                <th>
                  {' '}
                  <span>{t('general.SUM')}</span>
                </th>
              )}
            </tr>
            {data.map((dat) => {
              let TotalText = () => <td>{dat.total.toFixed(2)}</td>;
              const surchargeValuesSumForIthMonth =
                calcSurchargesSumForIthMonth({
                  surcharges,
                  ithMonth: dat.dkNumber,
                  firstRateDay,
                });
              if (surchargeValuesSumForIthMonth) {
                TotalText = () => (
                  <td>
                    <span>{dat.total.toFixed(2)} +</span>
                    <span className="text-secondary">
                      {' '}
                      {surchargeValuesSumForIthMonth
                        .toString()
                        .replace('.', ',')}
                    </span>
                  </td>
                );
              }
              return (
                <tr key={dat.dkNumber}>
                  <td>{dat.dkNumber}</td>
                  <td>
                    {moment(firstRateDay)
                      .add(dat.dkNumber - 1, 'months')
                      .format('YYYY-MM')}
                  </td>
                  <td>{dat.dkRemainToPay.toFixed(2)}</td>
                  <td>{dat.dkCapitalPart.toFixed(2)}</td>
                  <td>{dat.dkInterestPart.toFixed(2)}</td>
                  <td>{dat.dkConstantFees.toFixed(2)}</td>
                  <td>{dat.dkChangingFees.toFixed(2)}</td>
                  <TotalText />
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Summary;
