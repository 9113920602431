import React from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';

const Area = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="results">
      <ResponsiveContainer className="results-chart" aspect={6.0 / 2.0}>
        <BarChart
          width={500}
          height={300}
          barCategoryGap={0}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3" />
          <XAxis dataKey="dkNumber" tick={{ fill: '#efefef' }} />
          <YAxis
            tick={{ fill: '#efefef' }}
            type="number"
            dataKey={(v) => parseFloat(v.percent.toFixed(2))}
          />
          <Tooltip wrapperStyle={{ color: '#343a40' }} />
          <Bar
            dataKey="percent"
            name={t('general.PERCENT')}
            fill="rgba(206, 35, 35, 0.6)"
            barCategoryGap={0}
          >
            {data.map((d, index) => {
              let color = 'rgba(52, 58, 64, 0.6)';
              if (d.percent <= 30) color = 'rgba(178, 178, 178, 0.6)';
              if (d.percent > 30 && d.percent <= 50)
                color = 'rgba(255, 191, 0, 0.6)';
              if (d.percent > 50 && d.percent <= 75)
                color = 'rgba(206, 35, 35, 0.6)';
              return <Cell key={`cell-${index}`} fill={color} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
export default Area;
