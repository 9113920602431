import React from 'react';
import { Helmet } from 'react-helmet';
import { Table } from 'reactstrap';
import AdSenseFrame from '../sub/AdSenseFrame';
import { useTranslation } from 'react-i18next';

const Examples = () => {
  const { t } = useTranslation();
  return (
    <div className="container examples">
      <Helmet>
        <title>{t('examples.PAGE_TITLE')}</title>
      </Helmet>
      <article>
        <header className="pb-5">
          <h1 className="py-5 text-center">{t('examples.SUB_TITLE1')}</h1>
          <h3 className="text-center pb-3">{t('examples.SUB_TITLE2')}</h3>
        </header>
        <AdSenseFrame slotId="1315572590" />
        <div>
          <h3 className="pb-3">{t('examples.PARAG1')}</h3>
          <h5 className="text-secondary">{t('general.MARGIN')}</h5>
          <p>{t('examples.EXP_MARGIN')}</p>
          <h5 className="text-secondary">{t('general.WIBOR')}</h5>
          <p>{t('examples.EXP_WIBOR')}</p>
          <h5 className="text-secondary">{t('general.SERVICE_CHARGE')}</h5>
          <p>{t('examples.EXP_SERVICE_CHARGE')}</p>
          <h5 className="text-secondary">{t('general.CONSTANT_FEES')}</h5>
          <p>{t('examples.EXP_CONSTANT_FEES')}</p>
          <h5 className="text-secondary">{t('general.CHANGING_FEES')}</h5>
          <p>{t('examples.EXP_CHANGING_FEES')}</p>
          <h5 className="text-secondary">{t('general.PERCENTAGE')}</h5>
          <p>{t('examples.PARAG2')}</p>
          <h5 className="text-secondary">{t('general.CREDIT_COST')}</h5>
          <p>{t('examples.EXP_CREDIT_COST')}</p>
          <h5 className="text-secondary">{t('general.DTS')}</h5>
          <p>{t('examples.EXP_DTS')}</p>
        </div>
        <div className="pb-4">
          <h3 className="py-3">{t('examples.PARAG3')}</h3>
          <h4 className="py-3">{t('examples.PARAG4')}</h4>
          <p>{t('examples.PARAG5')}</p>
          <p className="m-0">{t('examples.PARAG6')}: </p>
          <div>
            <p className="text-secondary d-inline">
              {t('general.CREDIT_TIME')}:
            </p>
            <p className="d-inline"> 20 {t('general.YEARS')}</p>
          </div>
          <div>
            <p className="text-secondary d-inline">{t('general.RATE_TYPE')}:</p>
            <p className="d-inline"> {t('general.CONSTANT_INSTALLMENTS')}</p>
          </div>
          <div>
            <p className="text-secondary d-inline">{t('general.MARGIN')}:</p>
            <p className="d-inline"> 1,7%</p>
          </div>
          <div>
            <p className="text-secondary d-inline">{t('general.WIBOR')}:</p>
            <p className="d-inline"> 6,90% {t('examples.PARAG7')}</p>
          </div>
          <div>
            <p className="text-secondary d-inline">
              {t('general.SERVICE_CHARGE')}:
            </p>
            <p className="d-inline"> 0,5%</p>
          </div>
          <p className="m-0">{t('examples.PARAG8')}</p>
          <div>
            <p className="text-secondary d-inline">{t('examples.PARAG9')}</p>
            <p className="d-inline"> 0,03% {t('examples.PARAG10')}</p>
          </div>
          <div>
            <p className="text-secondary d-inline">{t('examples.PARAG11')}</p>
            <p className="d-inline"> 19,20{t('general.ZL_PER_MONTH')}</p>
          </div>
          <div>
            <p className="text-secondary d-inline">{t('examples.PARAG12')}</p>
            <p className="d-inline">
              {' '}
              600{t('general.ZL')} {t('examples.PARAG13')}
            </p>
          </div>
          <div>
            <p className="text-secondary d-inline"> {t('examples.PARAG14')}:</p>
            <p className="d-inline"> 0,01% {t('examples.PARAG15')}</p>
          </div>
          <p>{t('examples.PARAG16')}:</p>
          <div className="d-flex pb-5">
            <div className="col-12">
              <img
                className="w-100 h-100"
                alt={t('examples.PARAG17')}
                src="/images/przyklad-z-zycia-dane.png"
              />
            </div>
          </div>
          <div className="d-flex pb-5">
            <div className="col-12">
              <img
                className="w-100 h-100"
                alt={t('examples.PARAG17')}
                src="/images/przyklad-z-zycia-wykres.png"
              />
            </div>
          </div>
        </div>
        <div className="pb-4">
          <h3 className="py-3">
            {t('examples.PARAG17')} - {t('examples.PARAG18')}
          </h3>
          <p>{t('examples.PARAG19')}</p>
          <div className="d-flex pb-5">
            <div className="col-12">
              <img
                className="w-100 h-100"
                alt={t('examples.PARAG17')}
                src="/images/przyklad-z-zycia-nadplata.png"
              />
            </div>
          </div>
        </div>
        <div className="pb-4">
          <h3 className="py-3">
            {t('examples.PARAG17')} - {t('examples.PARAG20')}
          </h3>
          <p>{t('examples.PARAG21')}</p>
          <div className="d-flex pb-5">
            <div className="col-12">
              <img
                className="w-100 h-100"
                alt={t('examples.PARAG17')}
                src="/images/przyklad-z-zycia-zmiana-wiboru.png"
              />
            </div>
          </div>
          <div className="d-flex pb-5">
            <div className="col-12">
              <img
                className="w-100 h-100"
                alt={t('examples.PARAG17')}
                src="/images/przyklad-z-zycia-wyniki.png"
              />
            </div>
          </div>
        </div>
        <div className="pb-4">
          <h3 className="py-3">{t('examples.PARAG22')}</h3>
          <p>{t('examples.PARAG23')}:</p>
          <p>{t('examples.PARAG24')}</p>
          <p>{t('examples.PARAG25')}</p>
        </div>
        {
          /// RATY ROWNE / RATY MALEJACE
        }
        <div>
          <h3 className="pb-3">1. {t('examples.EXAMPLE_TITLE_1')}</h3>
          <h5>{t('examples.PARAG26')}:</h5>
          <Table>
            <tbody>
              <tr>
                <td />
                <td>A</td>
                <td>B</td>
              </tr>
              <tr>
                <td>{t('general.CREDIT_AMOUNT')}:</td>
                <td>400 000 {t('general.ZL')}</td>
                <td>400 000 {t('general.ZL')}</td>
              </tr>
              <tr>
                <td>{t('general.CREDIT_PERIOD')}:</td>
                <td>360 {t('general.MONTH')}</td>
                <td>360 {t('general.MONTH')}</td>
              </tr>
              <tr className="distinguished">
                <td>{t('general.RATE_TYPE')}:</td>
                <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
                <td>{t('general.DECREASING_INSTALLMENTS')}</td>
              </tr>
              <tr>
                <td>{t('general.BANK_MARGIN')}:</td>
                <td>1,7 %</td>
                <td>1,7 %</td>
              </tr>
              <tr>
                <td>{t('general.WIBOR')}:</td>
                <td>6,9 %</td>
                <td>6,9 %</td>
              </tr>
              <tr>
                <td>{t('general.CONSTANT_FEES')}:</td>
                <td>20 {t('general.ZL_PER_MONTH')}</td>
                <td>20 {t('general.ZL_PER_MONTH')}</td>
              </tr>
              <tr>
                <td>{t('general.CHANGING_FEES')}:</td>
                <td>0,03 %</td>
                <td>0,03 %</td>
              </tr>
              <tr className="bg-dark">
                <td>{t('general.RATE_WITHOUT_FEES')}:</td>
                <td>3104,05 {t('general.ZL')}</td>
                <td>
                  4032,75 ({t('general.FIRST_RATE')}) {t('general.ZL')}
                </td>
              </tr>
              <tr className="bg-dark">
                <td>{t('general.RATE_WITH_FEES')}:</td>
                <td>3244,05 {t('general.ZL')}</td>
                <td>
                  4172,75 ({t('general.FIRST_RATE')}) {t('general.ZL')}
                </td>
              </tr>
              <tr className="bg-dark">
                <td>{t('general.CREDIT_COST_FULL_WITHOUT_FEES')}:</td>
                <td>717456,87 {t('general.ZL')}</td>
                <td>517525,25 {t('general.ZL')}</td>
              </tr>
              <tr className="bg-dark">
                <td>{t('general.CREDIT_COST_FULL_WITH_FEES')}:</td>
                <td>754689,95 {t('general.ZL')}</td>
                <td>546385,25 {t('general.ZL')}</td>
              </tr>
            </tbody>
          </Table>
          <div className="d-flex pb-5">
            <div className="col-12">
              <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
              <img
                className="w-100 h-100"
                alt={t('examples.IMG_TITLE_1')}
                src="/images/raty-rowne-30-lat-400-tysiecy-wibor-7.png"
              />
              <p className="text-center">A</p>
            </div>
          </div>
          <div className="d-flex pb-5">
            <div className="col-12">
              <h5>{t('general.DECREASING_INSTALLMENTS')}</h5>
              <img
                className="w-100 h-100"
                alt={t('examples.IMG_TITLE_2')}
                src="/images/raty-malejace-30-lat-400-tysiecy-wibor-7.png"
              />
              <p className="text-center">B</p>
            </div>
          </div>
        </div>
        <AdSenseFrame slotId="6191321291" />
        {
          // GDY 20TYS MNIEJ - RATY ROWNE
        }
        <div>
          <h3 className="pb-3">2. {t('examples.EXAMPLE_TITLE_2')}</h3>
          <h5>{t('examples.PARAG26')}:</h5>
          <Table>
            <tbody>
              <tr>
                <td />
                <td>A</td>
                <td>B</td>
              </tr>
              <tr className="distinguished">
                <td>{t('general.CREDIT_AMOUNT')}:</td>
                <td>400 000 {t('general.ZL')}</td>
                <td>380 000 {t('general.ZL')}</td>
              </tr>
              <tr>
                <td>{t('general.CREDIT_PERIOD')}:</td>
                <td>360 {t('general.MONTH')}</td>
                <td>360 {t('general.MONTH')}</td>
              </tr>
              <tr>
                <td>{t('general.RATE_TYPE')}:</td>
                <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
                <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
              </tr>
              <tr>
                <td>{t('general.BANK_MARGIN')}:</td>
                <td>1,7 %</td>
                <td>1,7 %</td>
              </tr>
              <tr>
                <td>{t('general.WIBOR')}:</td>
                <td>6,9 %</td>
                <td>6,9 %</td>
              </tr>
              <tr>
                <td>{t('general.CONSTANT_FEES')}:</td>
                <td>20 {t('general.ZL_PER_MONTH')}</td>
                <td>20 {t('general.ZL_PER_MONTH')}</td>
              </tr>
              <tr>
                <td>{t('general.CHANGING_FEES')}:</td>
                <td>0,03 %</td>
                <td>0,03 %</td>
              </tr>
              <tr className="bg-dark">
                <td>{t('general.RATE_WITHOUT_FEES')}:</td>
                <td>3104,05 {t('general.ZL')}</td>
                <td>2948,84 {t('general.ZL')}</td>
              </tr>
              <tr className="bg-dark">
                <td>{t('general.RATE_WITH_FEES')}:</td>
                <td>3244,05 {t('general.ZL')}</td>
                <td>3082,84 {t('general.ZL')}</td>
              </tr>
              <tr className="bg-dark">
                <td>{t('general.CREDIT_COST_FULL_WITHOUT_FEES')}:</td>
                <td>717456,87 {t('general.ZL')}</td>
                <td>681584,03 {t('general.ZL')}</td>
              </tr>
              <tr className="bg-dark">
                <td>{t('general.CREDIT_COST_FULL_WITH_FEES')}:</td>
                <td>754689,95 {t('general.ZL')}</td>
                <td>717315,45 {t('general.ZL')}</td>
              </tr>
            </tbody>
          </Table>
          <div className="d-flex pb-5">
            <div className="col-12">
              <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
              <img
                className="w-100 h-100"
                alt={t('examples.IMG_TITLE_3')}
                src="/images/raty-rowne-30-lat-400-tysiecy-wibor-7.png"
              />
              <p className="text-center">A</p>
            </div>
          </div>
          <div className="d-flex pb-5">
            <div className="col-12">
              <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
              <img
                className="w-100 h-100"
                alt={t('examples.IMG_TITLE_4')}
                src="/images/kredyt-380tys-zl.png"
              />
              <p className="text-center">B</p>
            </div>
          </div>
        </div>
        {
          // GDY KREDYT 5 LAT KROTSZY
        }
        <AdSenseFrame slotId="9305743258" />
        <div>
          <h3 className="pb-3">3. {t('examples.EXAMPLE_TITLE_3')}</h3>
        </div>
        <Table>
          <tbody>
            <tr>
              <td />
              <td>A</td>
              <td>B</td>
            </tr>
            <tr>
              <td>{t('general.CREDIT_AMOUNT')}:</td>
              <td>400 000 {t('general.ZL')}</td>
              <td>400 000 {t('general.ZL')}</td>
            </tr>
            <tr className="distinguished">
              <td>{t('general.CREDIT_PERIOD')}:</td>
              <td>360 {t('general.MONTH')}</td>
              <td>300 {t('general.MONTH')}</td>
            </tr>
            <tr>
              <td>{t('general.RATE_TYPE')}:</td>
              <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
              <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
            </tr>
            <tr>
              <td>{t('general.BANK_MARGIN')}:</td>
              <td>1,7 %</td>
              <td>1,7 %</td>
            </tr>
            <tr>
              <td>{t('general.WIBOR')}:</td>
              <td>6,9 %</td>
              <td>6,9 %</td>
            </tr>
            <tr>
              <td>{t('general.CONSTANT_FEES')}:</td>
              <td>20 {t('general.ZL_PER_MONTH')}</td>
              <td>20 {t('general.ZL_PER_MONTH')}</td>
            </tr>
            <tr>
              <td>{t('general.CHANGING_FEES')}:</td>
              <td>0,03 %</td>
              <td>0,03 %</td>
            </tr>
            <tr className="bg-dark">
              <td>{t('general.RATE_WITHOUT_FEES')}:</td>
              <td>3104,05 {t('general.ZL')}</td>
              <td>3247,91 {t('general.ZL')}</td>
            </tr>
            <tr className="bg-dark">
              <td>{t('general.RATE_WITH_FEES')}:</td>
              <td>3244,05 {t('general.ZL')}</td>
              <td>3387.91 {t('general.ZL')}</td>
            </tr>
            <tr className="bg-dark">
              <td>{t('general.CREDIT_COST_FULL_WITHOUT_FEES')}:</td>
              <td>717456,87 {t('general.ZL')}</td>
              <td>574372.57 {t('general.ZL')}</td>
            </tr>
            <tr className="bg-dark">
              <td>{t('general.CREDIT_COST_FULL_WITH_FEES')}:</td>
              <td>754689,95 {t('general.ZL')}</td>
              <td>604416.08 {t('general.ZL')}</td>
            </tr>
          </tbody>
        </Table>
        <div className="d-flex pb-5">
          <div className="col-12">
            <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
            <img
              className="w-100 h-100"
              alt={t('examples.IMG_TITLE_5')}
              src="/images/raty-rowne-30-lat-400-tysiecy-wibor-7.png"
            />
            <p className="text-center">A</p>
          </div>
        </div>
        <div className="d-flex pb-5">
          <div className="col-12">
            <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
            <img
              className="w-100 h-100"
              alt={t('examples.IMG_TITLE_6')}
              src="/images/kredyt-25lat.png"
            />
            <p className="text-center">B</p>
          </div>
        </div>
      </article>
      <AdSenseFrame slotId="5174926552" />
      {
        // GDY PO ROKU ZMIENI SIE WIBOR
      }
      <div>
        <h3 className="pb-3">4. {t('examples.EXAMPLE_TITLE_4')}</h3>
      </div>
      <Table>
        <tbody>
          <tr>
            <td />
            <td>A</td>
            <td>B</td>
          </tr>
          <tr>
            <td>{t('general.CREDIT_AMOUNT')}:</td>
            <td>400 000 {t('general.ZL')}</td>
            <td>400 000 {t('general.ZL')}</td>
          </tr>
          <tr>
            <td>{t('general.CREDIT_PERIOD')}:</td>
            <td>360 {t('general.MONTH')}</td>
            <td>360 {t('general.MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.RATE_TYPE')}:</td>
            <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
            <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
          </tr>
          <tr>
            <td>{t('general.BANK_MARGIN')}:</td>
            <td>1,7 %</td>
            <td>1,7 %</td>
          </tr>
          <tr className="distinguished">
            <td>{t('general.WIBOR')}:</td>
            <td>6,9 %</td>
            <td>7,9 % ({t('examples.AFTER_A_YEAR')})</td>
          </tr>
          <tr>
            <td>{t('general.CONSTANT_FEES')}:</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.CHANGING_FEES')}:</td>
            <td>0,03 %</td>
            <td>0,03 %</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITHOUT_FEES')}:</td>
            <td>3104,05 {t('general.ZL')}</td>
            <td>
              3104,05 ({t('examples.AFTER_CHANGE')}: 3388,37) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITH_FEES')}:</td>
            <td>3244,05 {t('general.ZL')}</td>
            <td>
              3244,05 ({t('examples.AFTER_CHANGE')}: 3527,56) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITHOUT_FEES')}:</td>
            <td>717456,87 {t('general.ZL')}</td>
            <td>816684,84 {t('general.ZL')}</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITH_FEES')}:</td>
            <td>754689,95 {t('general.ZL')}</td>
            <td>854647,61 {t('general.ZL')}</td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_7')}
            src="/images/raty-rowne-30-lat-400-tysiecy-wibor-7.png"
          />
          <p className="text-center">A</p>
        </div>
      </div>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_8')}
            src="/images/wibor-7-9.png"
          />
          <p className="text-center">B</p>
        </div>
      </div>
      <AdSenseFrame slotId="2357191521" />
      {
        // CZY WARTO NADPLACAC - ROWNE, NA ZMNIEJSZENIE RATY
      }
      <div>
        <h3 className="pb-3">5. {t('examples.EXAMPLE_TITLE_5')}</h3>
      </div>
      <Table>
        <tbody>
          <tr>
            <td />
            <td>A</td>
            <td>B</td>
          </tr>
          <tr className="distinguished">
            <td>{t('general.CREDIT_AMOUNT')}:</td>
            <td>400 000 {t('general.ZL')}</td>
            <td>
              400 000 {t('general.ZL')} ({t('examples.SURCHARGE_AFTER_1')})
            </td>
          </tr>
          <tr>
            <td>{t('general.CREDIT_PERIOD')}:</td>
            <td>360 {t('general.MONTH')}</td>
            <td>360 {t('general.MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.RATE_TYPE')}:</td>
            <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
            <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
          </tr>
          <tr>
            <td>{t('general.BANK_MARGIN')}:</td>
            <td>1,7 %</td>
            <td>1,7 %</td>
          </tr>
          <tr>
            <td>{t('general.WIBOR')}:</td>
            <td>6,9 %</td>
            <td>6,9 %</td>
          </tr>
          <tr>
            <td>{t('general.CONSTANT_FEES')}:</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.CHANGING_FEES')}:</td>
            <td>0,03 %</td>
            <td>0,03 %</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITHOUT_FEES')}:</td>
            <td>3104,05 {t('general.ZL')}</td>
            <td>
              3104,05 ({t('examples.AFTER_CHANGE')}: 2865,45) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITH_FEES')}:</td>
            <td>3244,05 {t('general.ZL')}</td>
            <td>
              3244,05 ({t('examples.AFTER_CHANGE')}: 2993,54) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITHOUT_FEES')}:</td>
            <td>717456,87 {t('general.ZL')}</td>
            <td>670151,97 {t('general.ZL')}</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITH_FEES')}:</td>
            <td>754689,95 {t('general.ZL')}</td>
            <td>705404,85 {t('general.ZL')}</td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_9')}
            src="/images/raty-rowne-30-lat-400-tysiecy-wibor-7.png"
          />
          <p className="text-center">A</p>
        </div>
      </div>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_10')}
            src="/images/nadplata-rowne-na-zmniejszenie.png"
          />
          <p className="text-center">B</p>
        </div>
      </div>
      <AdSenseFrame slotId="8539456491" />
      {
        // CZY WARTO NADPLACAC - ROWNE, NA SKROCENIE KREDYTU
      }
      <div>
        <h3 className="pb-3">6. {t('examples.EXAMPLE_TITLE_6')}</h3>
      </div>
      <Table>
        <tbody>
          <tr>
            <td />
            <td>A</td>
            <td>B</td>
          </tr>
          <tr className="distinguished">
            <td>{t('general.CREDIT_AMOUNT')}:</td>
            <td>400 000 {t('general.ZL')}</td>
            <td>
              400 000 {t('general.ZL')} ({t('examples.SURCHARGE_AFTER_1')})
            </td>
          </tr>
          <tr>
            <td>{t('general.CREDIT_PERIOD')}:</td>
            <td>360 {t('general.MONTH')}</td>
            <td>360 {t('general.MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.RATE_TYPE')}:</td>
            <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
            <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
          </tr>
          <tr>
            <td>{t('general.BANK_MARGIN')}:</td>
            <td>1,7 %</td>
            <td>1,7 %</td>
          </tr>
          <tr>
            <td>{t('general.WIBOR')}:</td>
            <td>6,9 %</td>
            <td>6,9 %</td>
          </tr>
          <tr>
            <td>{t('general.CONSTANT_FEES')}:</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.CHANGING_FEES')}:</td>
            <td>0,03 %</td>
            <td>0,03 %</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITHOUT_FEES')}:</td>
            <td>3104,05 {t('general.ZL')}</td>
            <td>
              3104,05 ({t('examples.AFTER_CHANGE')}: 3104,05) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITH_FEES')}:</td>
            <td>3244,05 {t('general.ZL')}</td>
            <td>
              3244,05 ({t('examples.AFTER_CHANGE')}: 3232.13) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITHOUT_FEES')}:</td>
            <td>717456,87 {t('general.ZL')}</td>
            <td>516707,54 {t('general.ZL')}</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITH_FEES')}:</td>
            <td>754689,95 {t('general.ZL')}</td>
            <td>544057,16 {t('general.ZL')}</td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_9')}
            src="/images/raty-rowne-30-lat-400-tysiecy-wibor-7.png"
          />
          <p className="text-center">A</p>
        </div>
      </div>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_11')}
            src="/images/nadplata-rowne-na-skrocenie.png"
          />
          <p className="text-center">B</p>
        </div>
      </div>
      <AdSenseFrame slotId="2213721294" />
      {
        // CZY WARTO NADPLACAC SZYBCIEJ - ROWNE, NA SKROCENIE KREDYTU 30 TYS PO 3 I PO 6 LATACH
      }
      <div>
        <h3 className="pb-3">7. {t('examples.EXAMPLE_TITLE_7')}</h3>
      </div>
      <Table>
        <tbody>
          <tr>
            <td />
            <td>A</td>
            <td>B</td>
          </tr>
          <tr className="distinguished">
            <td>{t('general.CREDIT_AMOUNT')}:</td>
            <td>
              400 000 {t('general.ZL')} ({t('examples.SURCHARGE_AFTER_1')})
            </td>
            <td>
              400 000 {t('general.ZL')} ({t('examples.SURCHARGE_AFTER_2')})
            </td>
          </tr>
          <tr>
            <td>{t('general.CREDIT_PERIOD')}:</td>
            <td>360 {t('general.MONTH')}</td>
            <td>360 {t('general.MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.RATE_TYPE')}:</td>
            <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
            <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
          </tr>
          <tr>
            <td>{t('general.BANK_MARGIN')}:</td>
            <td>1,7 %</td>
            <td>1,7 %</td>
          </tr>
          <tr>
            <td>{t('general.WIBOR')}:</td>
            <td>6,9 %</td>
            <td>6,9 %</td>
          </tr>
          <tr>
            <td>{t('general.CONSTANT_FEES')}:</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.CHANGING_FEES')}:</td>
            <td>0,03 %</td>
            <td>0,03 %</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITHOUT_FEES')}:</td>
            <td>
              3104,05 ({t('examples.AFTER_CHANGE')}{' '}
              {t('examples.AFTER_3_YEARS')}: 3104,05) {t('general.ZL')}
            </td>
            <td>
              3104,05 {t('general.ZL')} ({t('examples.AFTER_CHANGE')}{' '}
              {t('examples.AFTER_6_YEARS')}: 3104,05) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITH_FEES')}:</td>
            <td>
              3244,05 ({t('examples.AFTER_CHANGE')}{' '}
              {t('examples.AFTER_3_YEARS')}: 3232.13) {t('general.ZL')}
            </td>
            <td>
              1477,10 {t('general.ZL')} ({t('examples.AFTER_CHANGE')}{' '}
              {t('examples.AFTER_6_YEARS')}: 3228,37) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITHOUT_FEES')}:</td>
            <td>516707,54 {t('general.ZL')}</td>
            <td>559317,44 {t('general.ZL')}</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITH_FEES')}:</td>
            <td>544057,16 {t('general.ZL')}</td>
            <td>588730,73 {t('general.ZL')}</td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_11')}
            src="/images/nadplata-rowne-na-skrocenie.png"
          />
          <p className="text-center">A</p>
        </div>
      </div>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_12')}
            src="/images/nadplata-rowne-na-skrocenie-po-6-latach.png"
          />
          <p className="text-center">B</p>
        </div>
      </div>
      <AdSenseFrame slotId="4648312942" />
      {
        // CZY TRANSZE CZY BEZ - RATY ROWNE
      }
      <div>
        <h3 className="pb-3">8. {t('examples.EXAMPLE_TITLE_8')}</h3>
      </div>
      <Table>
        <tbody>
          <tr>
            <td />
            <td>A</td>
            <td>B</td>
          </tr>
          <tr className="distinguished">
            <td>{t('general.CREDIT_AMOUNT')}:</td>
            <td>
              400 000 {t('general.ZL')} ({t('examples.WITHOUT_TRANCHES')})
            </td>
            <td>
              400 000 {t('general.ZL')} ({t('examples.PARAG27')})
            </td>
          </tr>
          <tr>
            <td>{t('general.CREDIT_PERIOD')}:</td>
            <td>360 {t('general.MONTH')}</td>
            <td>360 {t('general.MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.RATE_TYPE')}:</td>
            <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
            <td>{t('general.CONSTANT_INSTALLMENTS')}</td>
          </tr>
          <tr>
            <td>{t('general.BANK_MARGIN')}:</td>
            <td>1,7 %</td>
            <td>1,7 %</td>
          </tr>
          <tr>
            <td>{t('general.WIBOR')}:</td>
            <td>6,9 %</td>
            <td>6,9 %</td>
          </tr>
          <tr>
            <td>{t('general.CONSTANT_FEES')}:</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.CHANGING_FEES')}:</td>
            <td>0,03 %</td>
            <td>0,03 %</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITHOUT_FEES')}:</td>
            <td>3104,05 {t('general.ZL')}</td>
            <td>
              716,67 ({t('examples.AFTER_I_TRANCHE')}) / 1433,33 (
              {t('examples.AFTER_II_TRANCHE')}) / 3104,05 {t('general.ZL')} (
              {t('examples.AFTER_III_TRANCHE')})
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITH_FEES')}:</td>
            <td>3244,05 {t('general.ZL')}</td>
            <td>
              766,67 ({t('examples.AFTER_I_TRANCHE')}) / 1513,33 (
              {t('examples.AFTER_II_TRANCHE')}) / 3244,05 {t('general.ZL')} (
              {t('examples.AFTER_III_TRANCHE')})
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITHOUT_FEES')}:</td>
            <td>717456,87 {t('general.ZL')}</td>
            <td>743256,87 {t('general.ZL')}</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITH_FEES')}:</td>
            <td>754689,95 {t('general.ZL')}</td>
            <td>782049,95 {t('general.ZL')}</td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_9')}
            src="/images/raty-rowne-30-lat-400-tysiecy-wibor-7.png"
          />
          <p className="text-center">A</p>
        </div>
      </div>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.CONSTANT_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_13')}
            src="/images/3-transze-raty-rowne.png"
          />
          <p className="text-center">B</p>
        </div>
      </div>
      <AdSenseFrame slotId="6891332908" />
      {
        // CZY WARTO NADPLACAC - MALEJACE, NA ZMNIEJSZENIE RATY 30 TYS PO 3 LATACH
      }
      <div>
        <h3 className="pb-3">9. {t('examples.EXAMPLE_TITLE_9')}</h3>
      </div>
      <Table>
        <tbody>
          <tr>
            <td />
            <td>A</td>
            <td>B</td>
          </tr>
          <tr className="distinguished">
            <td>{t('general.CREDIT_AMOUNT')}:</td>
            <td>400 000 {t('general.ZL')}</td>
            <td>
              400 000 {t('general.ZL')} ({t('examples.SURCHARGE_AFTER_1')})
            </td>
          </tr>
          <tr>
            <td>{t('general.CREDIT_PERIOD')}:</td>
            <td>360 {t('general.MONTH')}</td>
            <td>360 {t('general.MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.RATE_TYPE')}:</td>
            <td>{t('general.DECREASING_INSTALLMENTS')}</td>
            <td>{t('general.DECREASING_INSTALLMENTS')}</td>
          </tr>
          <tr>
            <td>{t('general.BANK_MARGIN')}:</td>
            <td>1,7 %</td>
            <td>1,7 %</td>
          </tr>
          <tr>
            <td>{t('general.WIBOR')}:</td>
            <td>6,9 %</td>
            <td>6,9 %</td>
          </tr>
          <tr>
            <td>{t('general.CONSTANT_FEES')}:</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.CHANGING_FEES')}:</td>
            <td>0,03 %</td>
            <td>0,03 %</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITHOUT_FEES')}:</td>
            <td>
              4032,75 ({t('general.FIRST_RATE')}) {t('general.ZL')}
            </td>
            <td>
              4032,75 ({t('general.FIRST_RATE')}) / 3428,88 (
              {t('examples.AFTER_CHANGE')}) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITH_FEES')}:</td>
            <td>
              4172,75 ({t('general.FIRST_RATE')}) {t('general.ZL')}
            </td>
            <td>
              4032,75 ({t('general.FIRST_RATE')}) / 3547,87 (
              {t('examples.AFTER_CHANGE')}) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITHOUT_FEES')}:</td>
            <td>517525,25 {t('general.ZL')}</td>
            <td>482580,88 {t('general.ZL')}</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITH_FEES')}:</td>
            <td>546385,25 {t('general.ZL')}</td>
            <td>509978,38 {t('general.ZL')}</td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.DECREASING_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_14')}
            src="/images/raty-malejace-30-lat-400-tysiecy-wibor-7.png"
          />
          <p className="text-center">A</p>
        </div>
      </div>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.DECREASING_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_15')}
            src="/images/nadplata-malejace-na-zmniejszenie.png"
          />
          <p className="text-center">B</p>
        </div>
      </div>
      <AdSenseFrame slotId="9517496242" />
      {
        // CZY WARTO NADPLACAC - MALEJACE, NA SKROCENIE OKRESU KREDYTOWANIA 30 TYS PO 3 LATACH
      }
      <div>
        <h3 className="pb-3">10. {t('examples.EXAMPLE_TITLE_10')}</h3>
      </div>
      <Table>
        <tbody>
          <tr>
            <td />
            <td>A</td>
            <td>B</td>
          </tr>
          <tr className="distinguished">
            <td>{t('general.CREDIT_AMOUNT')}:</td>
            <td>400 000 {t('general.ZL')}</td>
            <td>
              400 000 {t('general.ZL')} ({t('examples.SURCHARGE_AFTER_1')})
            </td>
          </tr>
          <tr>
            <td>{t('general.CREDIT_PERIOD')}:</td>
            <td>360 {t('general.MONTH')}</td>
            <td>360 {t('general.MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.RATE_TYPE')}:</td>
            <td>{t('general.DECREASING_INSTALLMENTS')}</td>
            <td>{t('general.DECREASING_INSTALLMENTS')}</td>
          </tr>
          <tr>
            <td>{t('general.BANK_MARGIN')}:</td>
            <td>1,7 %</td>
            <td>1,7 %</td>
          </tr>
          <tr>
            <td>{t('general.WIBOR')}:</td>
            <td>6,9 %</td>
            <td>6,9 %</td>
          </tr>
          <tr>
            <td>{t('general.CONSTANT_FEES')}:</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.CHANGING_FEES')}:</td>
            <td>0,03 %</td>
            <td>0,03 %</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITHOUT_FEES')}:</td>
            <td>
              4032,75 ({t('general.FIRST_RATE')}) {t('general.ZL')}
            </td>
            <td>
              4032,75 ({t('general.FIRST_RATE')}) / 3521,47 (
              {t('examples.AFTER_CHANGE')}) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITH_FEES')}:</td>
            <td>
              4172,75 ({t('general.FIRST_RATE')}) {t('general.ZL')}
            </td>
            <td>
              4172,75 ({t('general.FIRST_RATE')}) / 3640,47 (
              {t('examples.AFTER_CHANGE')}) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITHOUT_FEES')}:</td>
            <td>517525,25 {t('general.ZL')}</td>
            <td>450653,51 {t('general.ZL')}</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITH_FEES')}:</td>
            <td>546385,25 {t('general.ZL')}</td>
            <td>476174,51 {t('general.ZL')}</td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.DECREASING_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_14')}
            src="/images/raty-malejace-30-lat-400-tysiecy-wibor-7.png"
          />
          <p className="text-center">A</p>
        </div>
      </div>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.DECREASING_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_16')}
            src="/images/nadplata-malejace-na-skrocenie.png"
          />
          <p className="text-center">B</p>
        </div>
      </div>
      <AdSenseFrame slotId="6891332908" />
      {
        // CZY WARTO NADPLACAC SZYBCIEJ - MALEJACE, NA SKROCENIE OKRESU KREDYTOWANIA 30 TYS PO 3 LATACH I PO 6 LATACH
      }
      <div>
        <h3 className="pb-3">11. {t('examples.EXAMPLE_TITLE_11')}</h3>
      </div>
      <Table>
        <tbody>
          <tr>
            <td />
            <td>A</td>
            <td>B</td>
          </tr>
          <tr className="distinguished">
            <td>{t('general.CREDIT_AMOUNT')}:</td>
            <td>
              400 000 {t('general.ZL')} ({t('examples.SURCHARGE_AFTER_1')})
            </td>
            <td>
              400 000 {t('general.ZL')} ({t('examples.SURCHARGE_AFTER_2')})
            </td>
          </tr>
          <tr>
            <td>{t('general.CREDIT_PERIOD')}:</td>
            <td>360 {t('general.MONTH')}</td>
            <td>360 {t('general.MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.RATE_TYPE')}:</td>
            <td>{t('general.DECREASING_INSTALLMENTS')}</td>
            <td>{t('general.DECREASING_INSTALLMENTS')}</td>
          </tr>
          <tr>
            <td>{t('general.BANK_MARGIN')}:</td>
            <td>1,7 %</td>
            <td>1,7 %</td>
          </tr>
          <tr>
            <td>{t('general.WIBOR')}:</td>
            <td>6,9 %</td>
            <td>6,9 %</td>
          </tr>
          <tr>
            <td>{t('general.CONSTANT_FEES')}:</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.CHANGING_FEES')}:</td>
            <td>0,03 %</td>
            <td>0,03 %</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITHOUT_FEES')}:</td>
            <td>
              4032,75 ({t('general.FIRST_RATE')}) / 3229,30 (
              {t('examples.AFTER_CHANGE')}) {t('general.ZL')}
            </td>
            <td>
              4032,75 ({t('general.FIRST_RATE')}) / 3521,47 (
              {t('examples.AFTER_CHANGE')}) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITH_FEES')}:</td>
            <td>
              4172,75 ({t('general.FIRST_RATE')}) / 3640,47 (
              {t('examples.AFTER_CHANGE')}) {t('general.ZL')}
            </td>
            <td>
              4172,75 ({t('general.FIRST_RATE')}) / 3336,30 (
              {t('examples.AFTER_CHANGE')}) {t('general.ZL')}
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITHOUT_FEES')}:</td>
            <td>450653,51 {t('general.ZL')}</td>
            <td>458393,51 {t('general.ZL')}</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITH_FEES')}:</td>
            <td>476174,51 {t('general.ZL')}</td>
            <td>484238,51 {t('general.ZL')}</td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.DECREASING_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_16')}
            src="/images/nadplata-malejace-na-skrocenie.png"
          />
          <p className="text-center">A</p>
        </div>
      </div>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.DECREASING_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_17')}
            src="/images/nadplata-malejace-na-skrocenie-po-6-latach.png"
          />
          <p className="text-center">B</p>
        </div>
      </div>
      <AdSenseFrame slotId="9517496242" />
      {
        // CZY TRANSZE CZY BEZ - RATY MALEJACE
      }
      <div>
        <h3 className="pb-3">12. {t('examples.EXAMPLE_TITLE_12')}</h3>
      </div>
      <Table>
        <tbody>
          <tr>
            <td />
            <td>A</td>
            <td>B</td>
          </tr>
          <tr className="distinguished">
            <td>{t('general.CREDIT_AMOUNT')}:</td>
            <td>400 000 {t('general.ZL')}</td>
            <td>
              400 000 {t('general.ZL')} ({t('examples.SURCHARGE_AFTER_1')})
            </td>
          </tr>
          <tr>
            <td>{t('general.CREDIT_PERIOD')}:</td>
            <td>360 {t('general.MONTH')}</td>
            <td>360 {t('general.MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.RATE_TYPE')}:</td>
            <td>{t('general.DECREASING_INSTALLMENTS')}</td>
            <td>{t('general.DECREASING_INSTALLMENTS')}</td>
          </tr>
          <tr>
            <td>{t('general.BANK_MARGIN')}:</td>
            <td>1,7 %</td>
            <td>1,7 %</td>
          </tr>
          <tr>
            <td>{t('general.WIBOR')}:</td>
            <td>6,9 %</td>
            <td>6,9 %</td>
          </tr>
          <tr>
            <td>{t('general.CONSTANT_FEES')}:</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
            <td>20 {t('general.ZL_PER_MONTH')}</td>
          </tr>
          <tr>
            <td>{t('general.CHANGING_FEES')}:</td>
            <td>0,03 %</td>
            <td>0,03 %</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITHOUT_FEES')}:</td>
            <td>
              4032,75 ({t('general.FIRST_RATE')}) {t('general.ZL')}
            </td>
            <td>
              {' '}
              716,67 ({t('examples.AFTER_I_TRANCHE')}) / 1433,33 (
              {t('examples.AFTER_II_TRANCHE')}) / 4032,75 {t('general.ZL')} (
              {t('general.FIRST_RATE')} {t('examples.AFTER_III_TRANCHE')})
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.RATE_WITH_FEES')}:</td>
            <td>
              4172,75 ({t('general.FIRST_RATE')}) {t('general.ZL')}
            </td>
            <td>
              766,67 ({t('examples.AFTER_I_TRANCHE')}) / 1513,33 (
              {t('examples.AFTER_II_TRANCHE')}) / 4172,75 {t('general.ZL')} (
              {t('general.FIRST_RATE')} {t('examples.AFTER_III_TRANCHE')})
            </td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITHOUT_FEES')}:</td>
            <td>517525,25 {t('general.ZL')}</td>
            <td>543325,25 {t('general.ZL')}</td>
          </tr>
          <tr className="bg-dark">
            <td>{t('general.CREDIT_COST_FULL_WITH_FEES')}:</td>
            <td>546385,25 {t('general.ZL')}</td>
            <td>573745,25 {t('general.ZL')}</td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex pb-5">
        <div className="col-12">
          <h5>{t('general.DECREASING_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_14')}
            src="/images/raty-malejace-30-lat-400-tysiecy-wibor-7.png"
          />
          <p className="text-center">A</p>
        </div>
      </div>
      <div className="d-flex pb-5 mb-4">
        <div className="col-12">
          <h5>{t('general.DECREASING_INSTALLMENTS')}</h5>
          <img
            className="w-100 h-100"
            alt={t('examples.IMG_TITLE_15')}
            src="/images/3-transze-raty-malejace.png"
          />
          <p className="text-center">B</p>
        </div>
      </div>
    </div>
  );
};

export default Examples;
